import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { Select } from '@withdiver/components/src/inputs/Select'
import { View } from '@withdiver/components/src/View'
import { Window } from '@withdiver/components/src/windows'
import { useCallback, useEffect, useState } from 'react'
import { ArrowRightCircle, Info } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAuthorizeCliMutation, useGetCliSecretQuery, useGetMeQuery } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'

interface CliAuthenticationFormValues {
	organizationId: string
	name: string
	secret: string
}

const authorizeButtonText = 'Authorize'

function CommandLineAuthPage() {
	const navigate = useNavigate()
	const [secret, setSecret] = useState<string>()
	const graphQLClient = useGraphQLClient()
	const { data: me } = useGetMeQuery(graphQLClient)
	const { data: cliSecret } = useGetCliSecretQuery(graphQLClient, { secret: secret ?? '' }, { enabled: Boolean(secret) })
	const authorizeCliMutation = useAuthorizeCliMutation(graphQLClient)
	const { getValues, handleSubmit, register, setValue, watch } = useForm<CliAuthenticationFormValues>()

	useEffect(() => {
		if (cliSecret) {
			setValue('name', cliSecret.cliSecret.name)
		}
	}, [ cliSecret, setValue ])

	const onCheckSecret = useCallback(() => {
		setSecret(getValues('secret'))
	}, [ getValues ])

	const onSubmit = useCallback(async () => {
		await authorizeCliMutation.mutateAsync({
			organizationId: getValues('organizationId'),
			name: getValues('name'),
			secret: secret!,
		})
		navigate('/auth/cli/complete')
	}, [ authorizeCliMutation, getValues, navigate, secret ])

	return (
		<View
			display="grid"
			gridTemplateColumns="1fr min-content 1fr"
			height="100vh"
			gap={20}
		>
			<View m="auto">
				<Window
					title="Terminal"
					width="500px"
				>
					<pre>
						$ diver login
						{'\n\n\n'}
						...
						{'\n\n\n'}
						Your terminal is waiting for your authorization.
						{'\n\n'}
						The authorization code was copied to your clipboard
						{'\n'}
						automatically.
						{'\n\n'}
						<View>
							<View>
								Just paste it on the right
								{'\n'}
								and hit the "{authorizeButtonText}" button
							</View>
							<View alignSelf="center" ml={4} gap={5}>
								<ArrowRightCircle size={16}/>
								<ArrowRightCircle size={16}/>
								<ArrowRightCircle size={16}/>
								<ArrowRightCircle size={16}/>
								<ArrowRightCircle size={16}/>
							</View>
						</View>
					</pre>
				</Window>
			</View>
			<View backgroundColor="primary" opacity={0.3} width="2px"></View>
			<View flexDirection="column" m="auto">
				<>
					{Boolean(cliSecret?.cliSecret) ||
					<>
						<Button
							variant="primary"
							size="medium"
							disabled
							gap={10}
							width="max-content"
							mx="auto"
							mb={4}
						>
							<Info size={20}/>
							The authorization code has been copied to your clipboard
						</Button>
						<View my={3} display="grid" gridTemplateColumns="1fr">
							<FormControl label="Authorization code">
								<Input
									placeholder="these_are_five_example_words"
									autoComplete="off"
									{...register('secret')}
								/>
							</FormControl>
						</View>
						<Button
							onClick={onCheckSecret}
							variant="primary"
							size="medium"
							width="max-content"
						>
							{authorizeButtonText}
						</Button>
					</>
					}
					{cliSecret?.cliSecret &&
					<form onSubmit={handleSubmit(onSubmit)}>
						<View mt={3} display="grid" gridTemplateColumns="1fr">
							<FormControl label="Organization">
								<Select
									placeholder="Organization here please"
									items={me?.me.organizations.map(o => o.id) ?? []}
									itemToLabel={organizationId => me?.me.organizations.find(o => o.id === organizationId)?.name || ''}
									selected={watch('organizationId')}
									onChange={org => setValue('organizationId', org)}
								/>
							</FormControl>
							<FormControl label="Name">
								<Input
									placeholder="Name here please"
									{...register('name')}
								/>
							</FormControl>
						</View>
						<Button mt={3} variant="primary" size="medium">{authorizeButtonText}</Button>
					</form>
					}
				</>
			</View>
		</View>
	)
}

export default CommandLineAuthPage
