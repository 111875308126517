import React, {
	useState,
	InputHTMLAttributes,
	useCallback,
	SyntheticEvent,
} from 'react'
import styled, { css } from 'styled-components'
import { Check, ChevronDown } from 'react-feather'
import { Text } from '../../Text'
import { Input } from '../Input'
import { useOnClickOutside } from '../../utils/useOnClickOutside'
import { View } from '../../View'

export const SelectWrapper = styled(View)(
	{
		maxWidth: '100%',
		position: 'relative',
		width: 'max-content',
	}
)

interface ChevronProps {
	open?: boolean
}

export const Chevron = styled(ChevronDown)<ChevronProps>(
	{
		transform: 'rotate(0deg)',
	},
	({ open }) => open && css({
		transform: 'rotate(-180deg)',
	}),
)

interface SelectBodyProps {
	open?: boolean
}

export const SelectBody = styled(View)<SelectBodyProps>(
	{
		borderRadius: '6px',
		flexDirection: 'column',
		left: 0,
		marginTop: '-5px',
		minWidth: 'max-content',
		maxWidth: '100%',
		maxHeight: '200px',
		opacity: 0,
		overflow: 'auto',
		pointerEvents: 'none',
		position: 'absolute',
		right: 0,
		top: '100%',
		zIndex: 9999,
	},
	({ open }) => open && css({
		marginTop: '2px',
		opacity: 1,
		pointerEvents: 'initial',
	}),
	({ color, theme }) => ({
		backdropFilter: theme.input.backdropFilter,
		backgroundColor: theme.colors.cardInputBackground,
		color: color ?? theme.colors.text,
		border: theme.input.border,
	}),
)

interface OptionProps {
	selected?: boolean
}

export const Option = styled(View)<OptionProps>(
	{
		alignItems: 'center',
		cursor: 'default',
		flexDirection: 'row',
		fontSize: '12px',
		height: '36px',
		padding: '0 12px',
	},
	({ theme }) => ({
		'&:hover': {
			color: theme.colors.primary,
		},
	}),
)

export interface SelectProps<Value> {
	placeholder?: string
	items: Value[]
	selected?: Value
	disabled?: boolean
	onChange: (value: Value) => void
	searchable?: boolean
	searchValue?: string
	itemToLabel?: (item: Value) => string
	onSearchChange?: (e: SyntheticEvent<HTMLInputElement>) => void
	name?: InputHTMLAttributes<typeof Input>['name']
}

export const Select = <Value,>({
	itemToLabel,
	items,
	placeholder,
	disabled,
	onChange,
	searchable,
	searchValue,
	selected,
	onSearchChange,
}: SelectProps<Value>) => {
	const [ isOpen, setOpen ] = useState<boolean>(false)
	const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false))

	const onSearchChangeCallback = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
		if (onSearchChange) {
			onSearchChange(e)
		}
	}, [ onSearchChange ])

	const onOptionPick = useCallback((selected: Value) => (e: any) => {
		if (![undefined, 'Enter'].includes(e.key)) {
			return
		}
		setOpen(false)
		onChange(selected)
		e.stopPropagation()
		e.preventDefault()
	}, [ onChange ])

	const toggleSelect = useCallback((value: boolean) => (e: SyntheticEvent) => {
		if (disabled) {
			setOpen(false)
			return
		}
		e.stopPropagation()
		e.preventDefault()
		setOpen(value)
	}, [ disabled ])

	return (
		<SelectWrapper ref={ref}>
			<Input
				cursor={searchable ? 'auto' : 'default'}
				icon={<Chevron size={16} onClick={toggleSelect(!isOpen)} open={isOpen}/>}
				onChange={onSearchChangeCallback}
				onClick={searchable ? undefined : toggleSelect(!isOpen)}
				onFocus={searchable ? toggleSelect(true) : undefined}
				placeholder={placeholder}
				readOnly={!searchable}
				value={(isOpen && searchable ? searchValue : (selected ? (itemToLabel?.(selected) ?? String(selected)) : ''))}
			/>
			<SelectBody open={isOpen}>
				{items.map((item: Value, i) => (
					<Option
						key={i}
						onClick={onOptionPick(item)}
						onKeyUp={onOptionPick(item)}
						selected={item === selected}
						tabIndex={isOpen ? 0 : -1}
					>
						<>
							{itemToLabel?.(item) ?? item}
							{item === selected &&
							<Text color="primary" flexGrow={1} justifyContent="flex-end">
								<View as={Check} size={20}/>
							</Text>
							}
						</>
					</Option>
				))}
			</SelectBody>
		</SelectWrapper>
	)
}
