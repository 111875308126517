import { useQueryClient } from '@tanstack/react-query'
import Tippy from '@tippyjs/react'
import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { ListView } from '@withdiver/components/src/ListView'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import cronstrue from 'cronstrue'
import { format, parseISO } from 'date-fns'
import React, { useCallback } from 'react'
import { AlertCircle, Bell, CheckCircle, PauseCircle, Plus } from 'react-feather'
import {
	Alert,
	AlertState,
	useDeleteAlertMutation,
	useGetAlertsQuery,
	useSuspendAlertMutation,
	useTriggerAlertMutation,
	useUnsuspendAlertMutation,
} from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import Sidebar from '../Sidebar'
import { AlertEditorModal } from './AlertEditorModal'

type EditProps = Omit<Parameters<typeof AlertEditorModal>[0], 'graphQLClient'>

function AlertsPage() {
	const graphQLClient = useGraphQLClient()
	const queryClient = useQueryClient()
	const { id: organizationId } = useOrganization()
	const { show } = useModal()

	const { data } = useGetAlertsQuery(graphQLClient, { organizationId })
	const deleteAlert = useDeleteAlertMutation(graphQLClient)
	const suspendMutation = useSuspendAlertMutation(graphQLClient)
	const triggerMutation = useTriggerAlertMutation(graphQLClient)
	const unsuspendMutation = useUnsuspendAlertMutation(graphQLClient)

	const onDeleteClick = useCallback((alertId: Alert['id']) => async () => {
		await deleteAlert.mutateAsync({ alertId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ deleteAlert, queryClient ])

	const onEditClick = useCallback((editProps: EditProps) => {
		return show(modalProps => (
			<AlertEditorModal
				{...editProps}
				graphQLClient={graphQLClient}
				{...modalProps}
			/>
		))
	}, [ graphQLClient, show ])

	const onSuspendClick = useCallback((alertId: string) => async () => {
		await suspendMutation.mutateAsync({ alertId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ queryClient, suspendMutation ])

	const onTriggerClick = useCallback((alertId: string) => async () => {
		await triggerMutation.mutateAsync({ alertId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ queryClient, triggerMutation ])

	const onUnsuspendClick = useCallback((alertId: string) => async () => {
		await unsuspendMutation.mutateAsync({ alertId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ queryClient, unsuspendMutation ])

	const onAddAlertClick = useCallback(() => {
		show(modalProps => (
			<AlertEditorModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				query=""
				{...modalProps}
			/>
		))()
	}, [ graphQLClient, organizationId, show ])
	return (
		<View>
			<Sidebar/>
			<View px={[ 0, 40 ]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Alerts
						<Button
							variant="primary"
							size="icon"
							alignSelf="center"
							width="fit-content"
							onClick={onAddAlertClick}
							ml={10}
						>
							<View as={Plus} size={20}/>
						</Button>
					</Text>
				</View>
				<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
					<Text
						color="text"
						display="flex"
						maxWidth={1100}
						px={3}
						fontWeight="bold"
					>
						<View minWidth="24px" mr={2}/>
						<View width="38%">Alert Name</View>
						<View width="30%">Interval</View>
						<View justifyContent="center" width="20%">Next check</View>
						<View justifyContent="center" width="20%">Suspended?</View>
						<View justifyContent="center" width="2%"/>
					</Text>
					<ListView gap={20}>
						{data?.alerts?.map(alert => (
							<Card p={3} maxWidth={1100} alignItems="baseline">
								<Tippy content={alert.state === 'suspended' ? 'Alert suspended' : alert.state === 'raised' ? 'Alert raised' : 'Alert healthy'}>
									<Text
										color="text"
										alignSelf="center"
										mr={2}
										position="relative"
									>
										<View as={Bell} size={3}/>
										<Text
											color={alert.state === 'suspended' ? 'info' : alert.state === 'raised' ? 'danger' : 'success'}
										>
											<View
												as={alert.state === 'suspended' ? PauseCircle : alert.state === 'raised' ? AlertCircle : CheckCircle}
												backdropFilter="blur(10px)"
												size={13}
												position="absolute"
												bottom={-3}
												right={-2}
												borderRadius="50%"
											/>
										</Text>
									</Text>
								</Tippy>
								<Text
									display="block"
									color="text"
									fontSize={3}
									width="38%"
									textOverflow="ellipsis"
									overflow="hidden"
									title={alert.name}
									whiteSpace="nowrap"
								>
									{alert.name}
								</Text>
								<Text
									display="block"
									color="text"
									fontSize={1}
									width="30%"
									textOverflow="ellipsis"
									overflow="hidden"
									title={cronstrue.toString(alert.cron)}
									whiteSpace="nowrap"
								>
									{cronstrue.toString(alert.cron)}
								</Text>
								<Text
									display="block"
									color="text"
									fontSize={1}
									width="20%"
									textOverflow="ellipsis"
									overflow="hidden"
									textAlign="center"
									title={(alert.next_run_at && format(parseISO(alert.next_run_at), 'PPP p')) || undefined}
									whiteSpace="nowrap"
								>
									{(alert.next_run_at && format(parseISO(alert.next_run_at), 'PPP p')) || '-'}
								</Text>
								<Text
									display="block"
									color="text"
									fontSize={1}
									width="20%"
									textOverflow="ellipsis"
									overflow="hidden"
									textAlign="center"
									title={alert.state === 'suspended' ? 'Yes' : 'No'}
									whiteSpace="nowrap"
								>
									{alert.state === 'suspended' ? 'Yes' : 'No'}
								</Text>
								<Text
									color="text"
									fontSize={1}
									width="2%"
									textOverflow="ellipsis"
									justifyContent="flex-end"
									whiteSpace="nowrap"
								>
									<OverflowActionMenu>
										<TextLink
											to=""
											onClick={onEditClick({
												alertId: alert.id,
												cron: alert.cron,
												name: alert.name,
												organizationId,
												query: alert.metric.diver_query ?? '',
												queryId: alert.metric.id,
												sourceId: alert.metric.source.id,
											})}
											color="text"
										>
											Edit
										</TextLink>
										{alert.state === AlertState.Suspended &&
										<TextLink to="" onClick={onUnsuspendClick(alert.id)} color="success">Unsuspend</TextLink>
										}
										{alert.state !== AlertState.Suspended &&
											<TextLink to="" onClick={onSuspendClick(alert.id)} color="warning">Suspend</TextLink>
										}
										<TextLink to="" color="text">View history</TextLink>
										<TextLink
											to=""
											onClick={onTriggerClick(alert.id)}
											color="text"
										>
											Trigger now
										</TextLink>
										<TextLink
											to=""
											onClick={onDeleteClick(alert.id)}
											color="danger"
										>
											Delete
										</TextLink>
									</OverflowActionMenu>
								</Text>
							</Card>
						))}
					</ListView>
				</View>
			</View>
		</View>
	)
}

export default AlertsPage
