import { Button } from '@withdiver/components/src/Button'
import { Input } from '@withdiver/components/src/inputs/Input'
import { Select } from '@withdiver/components/src/inputs/Select'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import { TextLink } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import React, { useCallback, useState } from 'react'
import { Calendar } from 'react-feather'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components'

type RangeTypes = 'after' | 'before' | 'fixed' | 'relative'

const RangeTab = styled(TextLink).attrs({ to: '' })<{ isActive: boolean }>(
	({ theme }) => css({
		color: theme.colors.text,
	}),
	({ isActive, theme }) => isActive && css({
		fontWeight: 700,
		pointerEvents: 'none',
	}),
)

interface AfterRangeFormValues {
	after: string
}

interface BeforeRangeFormValues {
	before: string
}

interface FixedRangeFormValues {
	since: string
	until: string
}

interface RelativeRangeFormValues {
	relativity: 'this' | 'last'
	period: string
}

type FormValues = AfterRangeFormValues | BeforeRangeFormValues | FixedRangeFormValues | RelativeRangeFormValues

const Form = styled.form``

function TimeRangePicker() {
	const theme = useTheme() as Theme
	const [
		selectedRangeType,
		setSelectedRangeType,
	] = useState<RangeTypes>('fixed')
	const [ searchParams, setSearchParams ] = useSearchParams()
	const {
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm<FormValues>({
		defaultValues: {
			since: searchParams.get('since') ?? undefined,
			until: searchParams.get('until') ?? undefined,
		},
	})

	const onRangeSave = useCallback((values: FormValues) => {
		setSearchParams(prev => {
			prev.delete('since')
			prev.delete('until')
			if ('since' in values && values.since) {
				prev.set('since', values.since)
			}
			if ('until' in values && values.until) {
				prev.set('until', values.until)
			}
			return prev
		})
	}, [ setSearchParams ])

	const onRangeTabClick = useCallback((rangeType: RangeTypes) => () => {
		reset()
		setSelectedRangeType(rangeType)
	}, [ reset ])

	return (
		<Input
			disabled={true}
			icon={
				<View size={16}>
					<View position="absolute" size={16}>
						<OverflowActionMenu
							icon={Calendar}
							size={16}
						>
							<View
								as={Form}
								flexDirection="column"
								onSubmit={handleSubmit(onRangeSave)}
								p={2}
							>
								<View
									gap={15}
									height={300}
									width={720}
								>
									<View
										flexDirection="column"
										flexGrow={1}
										gap={15}
									>
										<RangeTab
											isActive={selectedRangeType === 'relative'}
											onClick={onRangeTabClick('relative')}
										>
											Relative
										</RangeTab>
										<RangeTab
											isActive={selectedRangeType === 'before'}
											onClick={onRangeTabClick('before')}
										>
											Before
										</RangeTab>
										<RangeTab
											isActive={selectedRangeType === 'after'}
											onClick={onRangeTabClick('after')}
										>
											After
										</RangeTab>
										<RangeTab
											isActive={selectedRangeType === 'fixed'}
											onClick={onRangeTabClick('fixed')}
										>
											From-to
										</RangeTab>
									</View>
									<View
										borderLeft={theme.input.border}
										height="100%"
									/>
									<View flexGrow={3} alignItems="flex-start">
										<Select
											items={['last', 'this']}
											onChange={(value: RelativeRangeFormValues['relativity']) => setValue('relativity', value)}
											selected={watch('relativity')}
										/>
										<Select
											items={['week', 'month', 'year']}
											onChange={value => setValue('period', value)}
											selected={watch('period')}
										/>
									</View>
								</View>
								<View ml="auto">
									<Button
										size="medium"
										type="button"
										variant="outline"
									>
										Clear filter
									</Button>
									<Button
										ml={2}
										size="medium"
										variant="primary"
									>
										Save
									</Button>
								</View>
							</View>
						</OverflowActionMenu>
					</View>
				</View>
			}
			placeholder="Last week"
		/>
	)
}

export {
	TimeRangePicker,
}
