import { LoginPage as LoginPageComponent } from '@withdiver/components/src/pages/Login'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Auth } from '../../AuthProvider'
import { useLogInMutation } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'

interface LoginPageProps {
	setAuth: Dispatch<SetStateAction<Auth>>
}

interface LoginFormValues {
	email: string
	password: string
}

function LoginPage({ setAuth }: LoginPageProps) {
	const { handleSubmit, register } = useForm<LoginFormValues>()
	const graphQLClient = useGraphQLClient()
	const logIn = useLogInMutation(graphQLClient)

	const onFormSubmit = useCallback(async ({ email, password }: LoginFormValues) => {
		const data = await logIn.mutateAsync({ email, password })
		setAuth(data.authenticate)
	}, [ logIn, setAuth ])

	return (
		<LoginPageComponent
			onLogin={handleSubmit(onFormSubmit)}
			emailProps={register('email')}
			passwordProps={register('password')}
		/>
	)
}

export default LoginPage
