import { Chart as ChartType, ChartData } from 'chart.js'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'
import Theme from '../theme/Theme'
import { Chart, ChartProps } from './Chart'

const rotation = {
	140: 290,
	240: 240,
}

type Circumference = keyof typeof rotation

function gaugeNeedle(
	circumference: Circumference,
	theme: Theme['chart']['gauge'],
) {
	return {
		id: 'gaugeNeedle',
		afterDatasetsDraw(chart: ChartType) {
			const { ctx, data } = chart
			ctx.save()

			const xCenter = chart.getDatasetMeta(0).data[0].x
			const yCenter = chart.getDatasetMeta(0).data[0].y
			const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius
			const innerRadius = chart.getDatasetMeta(2).data[0].innerRadius

			const needleValue = data.datasets[0].data[0]

			const cir = chart.getDatasetMeta(0).data[0].circumference

			ctx.translate(xCenter, yCenter)
			ctx.rotate(Math.PI / 180 * rotation[circumference] + cir)

			ctx.beginPath()
			ctx.strokeStyle = theme.needleColor
			ctx.fillStyle = theme.needleColor
			ctx.moveTo(-3, -innerRadius)
			ctx.lineTo(0, -outerRadius)
			ctx.lineTo(3, -innerRadius)
			ctx.fill()
			ctx.stroke()
			ctx.restore()

			if (needleValue !== null) {
				const fontSize = Number(Math.min(xCenter, yCenter) * 0.2).toFixed(0)
				ctx.font = `bold ${fontSize}px sans-serif`
				ctx.fillStyle = theme.needleValueColor
				ctx.textAlign = 'center'
				ctx.fillText(String(needleValue), xCenter, yCenter)
				ctx.restore()
			}
		},
	}
}

function defaultColors (size: number) {
	const c = [
		'#7edfb4',
		'#9de4a8',
		'#bce89c',
		'#dbe190',
		'#f79c47',
		'#f5863b',
		'#f46f30',
		'#f45825',
		'#f93e2e',
	]

	const colors = []

	if (size >= 1) {
		colors.push(c[0])
	}
	if (size >= 2) {
		colors.push(c[8])
	}
	if (size >= 3) {
		colors.splice(1, 0, c[4])
	}
	if (size >= 4) {
		colors.splice(1, 0, c[2])
	}
	if (size >= 5) {
		colors.splice(3, 0, c[7])
	}
	if (size >= 6) {
		colors.splice(3, 0, c[6])
	}
	if (size >= 7) {
		colors.splice(3, 0, c[5])
	}
	if (size >= 8) {
		colors.splice(2, 0, c[3])
	}
	if (size === 9) {
		colors.splice(1, 0, c[1])
	}

	return colors
}

interface ColorRange {
	lower: number,
	upper: number,
	color?: string
}

const defaultColorRanges: ColorRange[] = [
	{
		lower: 0,
		upper: 10,
	},
	{
		lower: 10,
		upper: 20,
	},
	{
		lower: 20,
		upper: 30,
	},
]

function generateData(
	circumference: Circumference,
	colorRanges: ColorRange[],
	data: number,
	theme: Theme['chart']['gauge'],
): ChartData<'doughnut'> {
	const computedColorRangeData = []
	let dataColor = null
	for (let i = 0; i < colorRanges.length; i++) {
		computedColorRangeData.push(colorRanges[i].upper - colorRanges[i].lower)
		if (colorRanges[i].lower <= data && colorRanges[i].upper > data) {
			dataColor = colorRanges[i].color ?? defaultColors(colorRanges.length)[i]
		}
	}

	if (data <= colorRanges[0].lower) {
		dataColor = colorRanges[0].color ?? defaultColors(colorRanges.length)[0]
	}
	if (data >= colorRanges[colorRanges.length - 1].upper) {
		dataColor = colorRanges[colorRanges.length - 1].color ?? defaultColors(colorRanges.length)[colorRanges.length - 1]
	}

	const rangeSum = computedColorRangeData.reduce((a, b) => a + b, 0)

	return {
		datasets: [
			{
				data: [ Math.max(0, data), Math.max(0, rangeSum - data) ],
				circumference,
				rotation: rotation[circumference],
				cutout: '75%',
				borderWidth: 0,
				backgroundColor: [ dataColor, theme.dataBackgroundColor ],
				weight: 75,
			},
			{
				data: [ 1 ],
				circumference: 240, // Hardcoding this will align the center of the different circumferences
				rotation: rotation[240],
				cutout: '75%',
				animation: false,
				borderWidth: 0,
				backgroundColor: 'transparent',
				weight: 5,
			},
			{
				data: computedColorRangeData,
				circumference,
				rotation: rotation[circumference],
				cutout: '75%',
				animation: false,
				borderWidth: 0,
				backgroundColor: colorRanges.map((range, i) => range.color ?? defaultColors(colorRanges.length)[i]),
				weight: 20,
			},
		],
	}
}

interface GaugeChartProps {
	colorRanges?: ColorRange[]
	data: number
	renderSettings?: ChartProps['renderSettings']
	title?: ChartProps['title']
}

function GaugeChart(props: GaugeChartProps) {
	const circumference = props.renderSettings?.circumference ?? 240
	const colorRanges = props.colorRanges?.length ? props.colorRanges : defaultColorRanges
	const theme = (useTheme() as Theme).chart.gauge

	const gaugeNeedlePluginValue = useMemo(() => {
		return gaugeNeedle(circumference, theme)
	}, [ circumference, theme ])

	return (
		<Chart
			type="doughnut"
			data={generateData(circumference, colorRanges, props.data, theme)}
			title={props.title}
			options={{
				elements: {
					arc: {
						backgroundColor: 'blue',
					},
				},
				hover: false,
				maintainAspectRatio: false,
				plugins: {
					tooltip: {
						enabled: false,
					},
					legend: {
						display: false,
					},
				},
				responsive: true,
				scales: {
				},
			}}
			plugins={[ gaugeNeedlePluginValue ]}
		/>
	)
}

export {
	GaugeChart,
}
