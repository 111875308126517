import { Card } from '@withdiver/components/src/Card'
import { PreText, Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React from 'react'
import { useGetUpcomingInvoiceQuery } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'

function InvoiceList() {
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const { data: upcomingInvoice } = useGetUpcomingInvoiceQuery(graphQLClient, { organizationId })

	const invoice = upcomingInvoice?.me.organizations?.[0].upcoming_invoice

	return (
		<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
			{invoice &&
			<Card p={2}>
				<Text alignItems="baseline" color="text">
					Upcoming invoice of <PreText>{invoice.total}</PreText> due
					at <PreText>{new Intl.DateTimeFormat().format(new Date(Date.parse(invoice.date)))}</PreText>
				</Text>
			</Card>
			}
		</View>
	)
}

export default InvoiceList
