import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import useModal from '@withdiver/components/src/modals/useModal'
import { PreText, Text } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import {
	useDeleteDomainMutation,
	useDeleteOrganizationMutation,
	useGetDomainsQuery,
	useGetMeQuery,
	useVerifyDomainMutation,
} from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import Sidebar from '../Sidebar'
import { DomainAdditionModal } from './DomainAdditionModal'

interface DeleteOrganizationFormValues {
	name: string
}

function SettingsPage() {
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const queryClient = useQueryClient()
	const { data: me } = useGetMeQuery(graphQLClient)
	const { data: domainData } = useGetDomainsQuery(graphQLClient, { organizationId })
	const deleteDomain = useDeleteDomainMutation(graphQLClient)
	const deleteOrganization = useDeleteOrganizationMutation(graphQLClient)
	const verifyDomain = useVerifyDomainMutation(graphQLClient)
	const { handleSubmit, register, watch } = useForm<DeleteOrganizationFormValues>()
	const navigate = useNavigate()
	const { show } = useModal()
	const theme = useTheme() as Theme

	const onDeleteDomainClick = useCallback((domainId: string) => async () => {
		await deleteDomain.mutateAsync({ domainId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ deleteDomain, queryClient ])

	const onDeleteOrganizationSubmit = useCallback(async () => {
		await deleteOrganization.mutateAsync({ organizationId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
		navigate('/')
	}, [ deleteOrganization, navigate, organizationId, queryClient ])

	const onVerifyDomainClick = useCallback((domainId: string) => async () => {
		await verifyDomain.mutateAsync({ domainId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ verifyDomain, queryClient ])

	const onAddDomainClick = useCallback(() => {
		show(modalProps => (
			<DomainAdditionModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				{...modalProps}
			/>
		))()
	}, [ graphQLClient, organizationId, show ])

	const organization = me?.me.organizations.find(o => o.id === organizationId)

	return (
		<View>
			<Sidebar/>
			<View px={[0, 40]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Settings</Text>
				</View>
				<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
					<Card p={2} flexDirection="column">
						<View justifyContent="space-between">
							<Text color="text" fontSize={20}>Domains</Text>
							<Button
								onClick={onAddDomainClick}
								size="medium"
								variant="primary"
							>
								Add domain
							</Button>
						</View>
						<View flexDirection="column">
							{(domainData?.domains || []).map(domain => (
								<>
									<PreText>
										<View>
											<Text color="text">DOMAIN: {domain.name}</Text>
										</View>
										<View>
											<Text color="text">VERIFIED AT: {domain.verified_at}</Text>
										</View>
										<View>
											<Text color="text">RECORD TYPE: {domain.record_type}</Text>
										</View>
										<View>
											<Text color="text">RECORD DOMAIN: {domain.record_domain}</Text>
										</View>
										<View>
											<Text color="text">RECORD VALUE: {domain.record_value}</Text>
										</View>
									</PreText>
									<View>
										{!domain.verified_at &&
										<Button
											onClick={onVerifyDomainClick(domain.id)}
											size="medium"
											variant="success"
										>
											Verify {domain.name}
										</Button>
										}
										<Button
											onClick={onDeleteDomainClick(domain.id)}
											size="medium"
											variant="danger"
										>
											Delete {domain.name}
										</Button>
									</View>
								</>
							))}
						</View>
					</Card>
					{organization &&
					<Card p={2} flexDirection="column" border={`1px ${theme.colors.danger} solid`} gap={20}>
						<Text color="danger" fontSize={20}>
							Danger Zone
						</Text>
						<Text color="text" display="block">
							Once you delete a workplace, there is no going back.
							This will immediately remove all team members and dashboards, and cancel your subscription.
							Please type <b>{organization.name}</b> to confirm.
						</Text>
						<form onSubmit={handleSubmit(onDeleteOrganizationSubmit)}>
							<FormControl width="300px">
								<Input
									autoComplete="off"
									placeholder={`Please type in '${organization.name}'`}
									{...register('name', {
										pattern: new RegExp(`^${organization.name}$`),
										required: true,
									})}
								/>
							</FormControl>
							<View mt={3}>
								<Button
									disabled={watch('name') !== organization.name}
									size="medium"
									variant="danger"
								>
									Delete Workplace
								</Button>
							</View>
						</form>
					</Card>
					}
				</View>
			</View>
		</View>
	)
}

export default SettingsPage
