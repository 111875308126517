import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { TableChart } from '@withdiver/components/src/charts/TableChart'
import { CodeArea } from '@withdiver/components/src/inputs/CodeArea'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import MultiSelect from '@withdiver/components/src/inputs/MultiSelect'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { PreText, Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback, useState } from 'react'
import { HelpCircle } from 'react-feather'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
	useGetSourcesQuery,
	useRunQueryMutation,
	useSaveQueryFilterMutation,
	useSaveQueryMutation,
	useValidateQueryMutation,
} from '../../generated/graphql'

interface QueryFilterEditorModalProps extends ModalProps {
	queryFilterId?: string
	graphQLClient: GraphQLClient
	identifier?: QueryFilterEditorFormValues['identifier']
	name?: QueryFilterEditorFormValues['name']
	organizationId: string
	query: QueryFilterEditorFormValues['query']
	queryId?: string
	sourceId?: QueryFilterEditorFormValues['sourceId']
}

interface QueryFilterEditorFormValues {
	identifier: string
	name: string
	query: string
	sourceId: string
}

const Form = styled.form``

export function QueryFilterEditorModal(props: QueryFilterEditorModalProps) {
	const { data: sources } = useGetSourcesQuery(props.graphQLClient, { organizationId: props.organizationId })
	const validateQuery = useValidateQueryMutation(props.graphQLClient)
	const runQuery = useRunQueryMutation(props.graphQLClient)
	const [ queryResult, setQueryResult ] = useState<{ columns: string[], rows: string[] }|null>()
	const [ fontSize, setFontSize ] = useState(13)
	const [ tabSize, setTabSize ] = useState(4)
	const [ previewState, setPreviewState ] = useState<string[]>([])
	const queryClient = useQueryClient()
	const saveQuery = useSaveQueryMutation(props.graphQLClient)
	const saveQueryFilter = useSaveQueryFilterMutation(props.graphQLClient)
	const { register, handleSubmit, getValues, setValue, watch } = useForm<QueryFilterEditorFormValues>({
		defaultValues: {
			identifier: props.name ?? '',
			name: props.name ?? '',
			query: props.query,
			sourceId: props.sourceId,
		},
	})
	const [ sawError, setSawError ] = useState(false)

	const onValidateQuery = useCallback(async ({ query, sourceId }: QueryFilterEditorFormValues) => {
		if (!query || !sourceId) {
			setSawError(false)
			return
		}
		try {
			await validateQuery.mutateAsync({ sourceId, query })
			setSawError(false)
		} catch (e) {
			setSawError(true)
		}
	}, [ validateQuery, setSawError ])

	const onRunQuery = useCallback(async () => {
		try {
			const response = await runQuery.mutateAsync({
				filters: [],
				sourceId: getValues('sourceId'),
				query: getValues('query'),
			})
			setQueryResult(response?.compileQuery.result)
			setSawError(false)
		} catch (e) {
			setSawError(true)
		}
	}, [ getValues, runQuery, setSawError ])

	const onHide = props.onHide
	const onSaveQueryFilter = useCallback(async () => {
		try {
			const savedQueryResponse = await saveQuery.mutateAsync({
				queryId: props.queryId,
				sourceId: getValues('sourceId'),
				query: getValues('query'),
			})
			if (!savedQueryResponse.saveQuery) {
				return
			}
			await saveQueryFilter.mutateAsync({
				queryFilterId: props.queryFilterId,
				queryId: savedQueryResponse.saveQuery.id,
				sourceId: getValues('sourceId'),
				identifier: getValues('identifier'),
				name: getValues('name'),
			})
		} finally {
			queryClient.invalidateQueries([], { refetchType: 'all' })
		}
		onHide?.()
	}, [
		getValues,
		onHide,
		props.queryFilterId,
		props.queryId,
		queryClient,
		saveQuery,
		saveQueryFilter,
	])

	const onFormChange = useCallback(() => {
		onValidateQuery(getValues())
	}, [onValidateQuery, getValues])

	register('sourceId')

	const items = Object.assign({}, ...(queryResult?.rows ?? []).map(r => ({
		[JSON.parse(r)[0] as string]: JSON.parse(r).pop() as string,
	})))

	return (
		<Modal {...props}>
			<View
				gap="20px"
				height="80vh"
				width="80vw"
				flexGrow={1}
			>
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onValidateQuery)}
					width="inherit"
					gap="10px"
				>
					<View gap="40px">
						<FormControl label="Filter name">
							<Input
								{...register('name')}
							/>
						</FormControl>
						<FormControl label="Filter identifier">
							<Input
								{...register('identifier')}
							/>
						</FormControl>
					</View>
					<View
						width="100%"
						flexBasis={0}
						flexGrow={1}
						gap="40px"
					>
						<View
							flexDirection="column"
							height="100%"
							width="100%"
							gap="10px"
						>
							<CodeArea
								fontSize={fontSize}
								backgroundColor={sawError ? '#f00' : undefined}
								onChange={(value) => {
									setValue('query', value)
									onFormChange()
								}}
								tabSize={tabSize}
								showPrintMargin={false}
								value={watch('query')}
								mode="dql"
							/>
							<View flexDirection="row-reverse" gap="20px">
								<Button variant="primary" size="medium" disabled={sawError} onClick={onRunQuery}>
									Test filter
								</Button>
								<Select
									items={sources?.sources.map(source => source.id) ?? []}
									itemToLabel={item => sources?.sources.find(s => s.id === item)?.name ?? ''}
									selected={watch('sourceId')}
									onChange={sourceId => setValue('sourceId', sourceId) }
								/>
								<Select
									items={[1, 2, 4, 8]}
									itemToLabel={item => `${item} space${item > 1 ? 's' : ''}`}
									selected={tabSize}
									onChange={setTabSize}
								/>
								<View width="100px">
									<Select
										items={[11, 13, 14, 16, 18]}
										itemToLabel={item => `${item} px`}
										selected={fontSize}
										onChange={setFontSize}
									/>
								</View>
							</View>
						</View>
					</View>
					<View flexGrow={1} flexBasis={0} overflow="auto">
						<Text color="text" width="100%">
							<TableChart
								data={{
									columns: queryResult?.columns ?? [],
									rows: queryResult?.rows.map(row => JSON.parse(row)) ?? [],
								}}
							/>
						</Text>
					</View>
					<View>
						<Button
							disabled={!queryResult || queryResult.columns.length > 2}
							variant="success"
							size="medium"
							type="button"
							onClick={onSaveQueryFilter}
						>
							Save
						</Button>
					</View>
				</View>
				<View
					backgroundColor="cardInputBackground"
					borderRadius="5px"
					flexDirection="column"
					padding="10px"
					width="30%"
				>
					<Text color="text" fontSize={3}>
						How to build filters
						<View ml="auto"><HelpCircle size={20}/></View>
					</Text>
					<p>
						<Text color="text" fontSize={2}>Columns</Text>
						<Text color="text">
							A filter is based on a query returning at least 1 column and at most 2 columns.
							The first column will be the values used to further filter chart queries
							while the second column will be the label of the dropdown options. If only one column
							is returned from this filter query, that column will be used for both the values
							and labels in the dropdown.
						</Text>
					</p>
					<p>
						<Text color="text" fontSize={2}>Use</Text>
						<Text color="text">
							When building charts and writing queries for charts and dashboards these filters can be used to interact with the data
							based on input fields in the top of every dashboard.
						</Text>
						<Text color="primary" py={1}>
							<PreText>
								{'{<expr>='}
								{watch('identifier') ? getValues('identifier') : '<filter_identifier>'}
								{'}'}
							</PreText>
						</Text>
					</p>
					<p>
						<Text color="text" fontSize={2}>Preview</Text>
						<MultiSelect
							itemToLabel={key => items[key]}
							items={Object.keys(items)}
							onChange={(values) => { setPreviewState(values) }}
							placeholder={watch('identifier')}
							selected={previewState}
						/>
					</p>
				</View>
			</View>
		</Modal>
	)
}
