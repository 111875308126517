import React, { FC, forwardRef, InputHTMLAttributes, ReactElement } from 'react'
import styled, { CSSObject, useTheme } from 'styled-components'
import { system } from 'styled-system'
import Theme from '../../theme/Theme'
import { View } from '../../View'

interface InputProps extends Partial<InputHTMLAttributes<HTMLInputElement>> {
	cursor?: CSSObject['cursor']
	icon?: ReactElement
}

const cursor = system({ cursor: true })

const StyledInput = styled.input(
	{
		background: 'none',
		border: 'none',
		color: 'inherit',
		outline: 'none',
		padding: '10px',
		width: '100%',
		zIndex: 1,
	},
	cursor,
)

const Input = forwardRef<HTMLInputElement>(({ icon, ...props }: InputProps, ref) => {
	const theme = useTheme() as Theme
	return (
		<View
			background={theme.colors.cardInputBackground}
			borderRadius={6}
			color={props.color ?? theme.colors.text}
			border={theme.input.border}
			overflow="hidden"
		>
			<StyledInput {...props} ref={ref}/>
			{icon && <View alignItems="center" pr="10px">{icon}</View>}
		</View>
	)
}) as FC<InputProps>

export {
	Input,
}
