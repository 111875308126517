function downloadCanvasToImage(canvas: HTMLCanvasElement) {
	canvas.toBlob((blob) => {
		if (!blob) {
			return
		}
		// Create a download link for the blob
		const link = document.createElement('a')
		link.download = 'canvas.png'
		link.href = URL.createObjectURL(blob)

		// Click the download link to trigger the download
		link.click()

		// Release the URL object
		URL.revokeObjectURL(link.href)
	}, 'image/png')
}

export {
	downloadCanvasToImage,
}
