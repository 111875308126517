import { useQueryClient } from '@tanstack/react-query'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { LogInMutation, useGetMeQuery } from './generated/graphql'
import Login from './pages/Login'
import { useGraphQLClient } from './useGraphQLClient'

export type Auth = Partial<LogInMutation['authenticate']>

const AuthContext = createContext<Auth>({})

export const useAuth = (): Auth => {
	return useContext(AuthContext)
}

interface ProviderProps {
	children?: ReactNode | ReactNode[]
}

export function AuthProvider({ children }: ProviderProps) {
	const token = localStorage.getItem('refresh_token')
	const [ auth, setAuth ] = useState<Auth>(token ? { token } : {})
	const queryClient = useQueryClient()
	const graphQLClient = useGraphQLClient({ token: auth.token })
	const { data, isLoading } = useGetMeQuery(graphQLClient, undefined, {
		enabled: Boolean(auth.token && !auth.user),
	})

	useEffect(() => {
		if (isLoading) {
			return
		}
		if (data?.me) {
			setAuth((prevState) => ({ ...prevState, user: data.me }))
		}
		if (!data?.me) {
			console.info('Removing refresh_token from local storage')
			localStorage.removeItem('refresh_token')
			setAuth({})
		}
	}, [ data, isLoading ])

	useEffect(() => {
		if (auth?.token) {
			localStorage.setItem('refresh_token', auth.token)
		}
	}, [ auth ])

	if (!auth.token) {
		return (
			<Login setAuth={setAuth}/>
		)
	}

	return (
		<AuthContext.Provider value={auth}>
			{children}
		</AuthContext.Provider>
	)
}
