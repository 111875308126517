import { GraphQLClient } from 'graphql-request'
import { useMemo } from 'react'
import { useAuth } from './AuthProvider'
import { GRAPHQL_API_URL } from './config'

interface UseGraphQLClientOptions {
	token?: string
}

export const useGraphQLClient = (options?: UseGraphQLClientOptions) => {
	const token = useAuth().token ?? options?.token

	return useMemo(() => {
		const options = {
			headers: new Headers(),
		}

		if (token) {
			options.headers.append('Authorization', `Bearer ${token}`)
		}

		return new GraphQLClient(GRAPHQL_API_URL, options)
	}, [ token ])
}
