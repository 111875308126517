import React, { Children } from 'react'
import { View, ViewProps } from '../View'

interface ListViewProps extends ViewProps {
}

/**
 * Reverse everything to fix a rendering issue
 * where the OverflowAction menu would render
 * below the next item in the list because the
 * `backdrop-filter` CSS property causes the
 * creation of a new stacking context.
 * @see https://philipwalton.com/articles/what-no-one-told-you-about-z-index/
 */
export function ListView({ children, ...props }: ListViewProps) {
	return (
		<View flexDirection="column-reverse" {...props}>
			{Children.toArray(children).toReversed()}
		</View>
	)
}
