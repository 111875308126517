window.ace.define("ace/mode/doc_comment_highlight_rules", ["require", "exports", "module", "ace/lib/oop", "ace/mode/text_highlight_rules"], function (require, exports, module) {
	"use strict";
	var oop = require("../lib/oop");
	var TextHighlightRules = require("./text_highlight_rules").TextHighlightRules;
	var DocCommentHighlightRules = function () {
		this.$rules = {
			"start": [{
				token: "comment.doc.tag", regex: "@[\\w\\d_]+" // TODO: fix email addresses
			}, DocCommentHighlightRules.getTagRule(), {
				defaultToken: "comment.doc", caseInsensitive: true
			}]
		};
	};
	oop.inherits(DocCommentHighlightRules, TextHighlightRules);
	DocCommentHighlightRules.getTagRule = function (start) {
		return {
			token: "comment.doc.tag.storage.type", regex: "\\b(?:TODO|FIXME|XXX|HACK)\\b"
		};
	};
	DocCommentHighlightRules.getStartRule = function (start) {
		return {
			token: "comment.doc", regex: "\\/\\*(?=\\*)", next: start
		};
	};
	DocCommentHighlightRules.getEndRule = function (start) {
		return {
			token: "comment.doc", regex: "\\*\\/", next: start
		};
	};
	exports.DocCommentHighlightRules = DocCommentHighlightRules;

});

window.ace.define("ace/mode/dql_highlight_rules", ["require", "exports", "module", "ace/lib/oop", "ace/lib/lang", "ace/mode/doc_comment_highlight_rules", "ace/mode/text_highlight_rules"], function (require, exports, module) {
	var oop = require("../lib/oop");
	var lang = require("../lib/lang");
	var DocCommentHighlightRules = require("./doc_comment_highlight_rules").DocCommentHighlightRules;
	var TextHighlightRules = require("./text_highlight_rules").TextHighlightRules;
	var DqlHighlightRules = function () {
		var mySqlKeywords = (
			"alter|and|as|asc|between|count|create|delete|desc|distinct|drop|from|having|in|insert|into|is|join|like|not|on|or|order|select|set|table|union|update|values|where|" +
			"accessible|action|add|after|algorithm|all|analyze|asensitive|at|authors|auto_increment|autocommit|avg|avg_row_length|before|binary|binlog|both|btree|cache|call|cascade|" +
			"cascaded|case|catalog_name|chain|change|changed|character|check|checkpoint|checksum|class_origin|client_statistics|close|coalesce|code|collate|collation|collations|" +
			"column|columns|comment|commit|committed|completion|concurrent|condition|connection|consistent|constraint|contains|continue|contributors|convert|cross|current_date|" +
			"current_time|current_timestamp|current_user|cursor|data|database|databases|day_hour|day_microsecond|day_minute|day_second|deallocate|dec|declare|default|" +
			"delay_key_write|delayed|delimiter|des_key_file|describe|deterministic|dev_pop|dev_samp|deviance|directory|disable|discard|distinctrow|div|dual|dumpfile|each|elseif|" +
			"enable|enclosed|end|ends|engine|engines|enum|errors|escape|escaped|even|event|events|every|execute|exists|exit|explain|extended|fast|fetch|field|fields|first|flush|for|" +
			"force|foreign|found_rows|full|fulltext|function|general|global|grant|grants|group|groupby_concat|handler|hash|help|high_priority|hosts|hour_microsecond|hour_minute|" +
			"hour_second|if|ignore|ignore_server_ids|import|index|index_statistics|infile|inner|innodb|inout|insensitive|insert_method|install|interval|invoker|isolation|iterate|" +
			"key|keys|kill|language|last|leading|leave|left|level|limit|linear|lines|list|load|local|localtime|localtimestamp|lock|logs|low_priority|master|master_heartbeat_period|" +
			"master_ssl_verify_server_cert|masters|match|max|max_rows|maxvalue|message_text|middleint|migrate|min|min_rows|minute_microsecond|minute_second|mod|mode|modifies|modify|" +
			"mutex|mysql_errno|natural|next|no|no_write_to_binlog|offline|offset|one|online|open|optimize|option|optionally|out|outer|outfile|pack_keys|parser|partition|partitions|" +
			"password|phase|plugin|plugins|prepare|preserve|prev|primary|privileges|procedure|processlist|profile|profiles|purge|query|quick|range|read|read_write|reads|real|" +
			"rebuild|recover|references|regexp|relaylog|release|remove|rename|reorganize|repair|repeatable|replace|require|resignal|restrict|resume|return|returns|revoke|right|" +
			"rlike|rollback|rollup|row|row_format|rtree|savepoint|schedule|schema|schema_name|schemas|second_microsecond|security|sensitive|separator|serializable|server|session|" +
			"share|show|signal|slave|slow|smallint|snapshot|soname|spatial|specific|sql|sql_big_result|sql_buffer_result|sql_cache|sql_calc_found_rows|sql_no_cache|sql_small_result|" +
			"sqlexception|sqlstate|sqlwarning|ssl|start|starting|starts|status|std|stddev|stddev_pop|stddev_samp|storage|straight_join|subclass_origin|sum|suspend|table_name|" +
			"table_statistics|tables|tablespace|temporary|terminated|to|trailing|transaction|trigger|triggers|truncate|uncommitted|undo|uninstall|unique|unlock|upgrade|usage|use|" +
			"use_frm|user|user_resources|user_statistics|using|utc_date|utc_time|utc_timestamp|value|variables|varying|view|views|warnings|when|while|with|work|write|xa|xor|" +
			"year_month|zerofill|begin|do|then|else|loop|repeat|" +
			"by|over"
		);
		var builtins = "bool|boolean|bit|blob|decimal|double|enum|float|long|longblob|longtext|medium|mediumblob|mediumint|mediumtext|time|timestamp|tinyblob|tinyint|tinytext|text|bigint|int|int1|int2|int3|int4|int8|integer|float|float4|float8|double|char|varbinary|varchar|varcharacter|precision|date|datetime|year|unsigned|signed|numeric|ucase|lcase|mid|len|round|rank|now|format|coalesce|ifnull|isnull|nvl";
		var variable = "charset|clear|connect|edit|ego|exit|go|help|nopager|notee|nowarning|pager|print|prompt|quit|rehash|source|status|system|tee";
		var keywordMapper = this.createKeywordMapper({
			"support.function": builtins,
			"keyword": mySqlKeywords,
			"constant": "false|true|null|unknown|date|time|timestamp|ODBCdotTable|zerolessFloat",
			"variable.language": variable
		}, "identifier", true);

		function string(rule) {
			var start = rule.start;
			var escapeSeq = rule.escape;
			return {
				token: "string.start",
				regex: start,
				next: [{token: "constant.language.escape", regex: escapeSeq}, {
					token: "string.end",
					next: "start",
					regex: start
				}, {defaultToken: "string"}]
			};
		}

		this.$rules = {
			"start": [{
				token: "comment", regex: "(?:-- |#).*$"
			}, string({start: '"', escape: /\\[0'"bnrtZ\\%_]?/}), string({
				start: "'",
				escape: /\\[0'"bnrtZ\\%_]?/
			}), DocCommentHighlightRules.getStartRule("doc-start"), {
				token: "comment", regex: /\/\*/, next: "comment"
			}, {
				token: "constant.numeric", regex: /0[xX][0-9a-fA-F]+|[xX]'[0-9a-fA-F]+'|0[bB][01]+|[bB]'[01]+'/
			}, {
				token: "dql", regex: /\{.*?}/
			}, {
				token: "constant.numeric", regex: "[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b"
			}, {
				token: keywordMapper, regex: "[a-zA-Z_$][a-zA-Z0-9_$]*\\b"
			}, {
				token: "constant.class", regex: "@@?[a-zA-Z_$][a-zA-Z0-9_$]*\\b"
			}, {
				token: "constant.buildin", regex: "`[^`]*`"
			}, {
				token: "keyword.operator", regex: "\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|="
			}, {
				token: "paren.lparen", regex: "[\\(]"
			}, {
				token: "paren.rparen", regex: "[\\)]"
			}, {
				token: "text", regex: "\\s+"
			}], "comment": [{token: "comment", regex: "\\*\\/", next: "start"}, {defaultToken: "comment"}]
		};
		this.embedRules(DocCommentHighlightRules, "doc-", [DocCommentHighlightRules.getEndRule("start")]);
		this.normalizeRules();
	};
	oop.inherits(DqlHighlightRules, TextHighlightRules);
	exports.DqlHighlightRules = DqlHighlightRules;

});

window.ace.define("ace/mode/dql", ["require", "exports", "module", "ace/lib/oop", "ace/mode/text", "ace/mode/dql_highlight_rules"], function (require, exports, module) {
	var oop = require("../lib/oop");
	var TextMode = require("../mode/text").Mode;
	var DqlHighlightRules = require("./dql_highlight_rules").DqlHighlightRules;
	var Mode = function () {
		this.HighlightRules = DqlHighlightRules;
		this.$behaviour = this.$defaultBehaviour;
	};
	oop.inherits(Mode, TextMode);
	(function () {
		this.lineCommentStart = ["--", "#"]; // todo space
		this.blockComment = {start: "/*", end: "*/"};
		this.$id = "ace/mode/dql";
	}).call(Mode.prototype);
	exports.Mode = Mode;

});
(function () {
	window.ace.require(["ace/mode/dql"], function (m) {
		if (typeof module == "object" && typeof exports == "object" && module) {
			module.exports = m;
		}
	});
})();
