import React from 'react'
import { View } from '../../View'

interface TopMenuProps {
	children?: React.ReactNode|React.ReactNode[]
}

function TopMenu(props: TopMenuProps) {
	return (
		<View
			height={60}
			alignItems="center"
			gap={20}
			justifyContent="space-between"
			pl={20}
			pr={20}
		>
			{props.children}
		</View>

	)
}

export {
	TopMenu,
}
