import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Role, useInviteUserMutation } from '../../generated/graphql'

interface UserInvitationModalProps extends ModalProps {
	graphQLClient: GraphQLClient
	organizationId: string
}

interface UserInvitationFormValues {
	email: string
	name: string
	role: Role
}

const Form = styled.form``

export function UserInvitationModal(props: UserInvitationModalProps) {
	const { organizationId } = props
	const queryClient = useQueryClient()
	const mutation = useInviteUserMutation(props.graphQLClient)
	const { register, handleSubmit, setValue, watch } = useForm<UserInvitationFormValues>()

	const onHide = props.onHide
	const onInviteUser = useCallback(async ({ email, name, role }: UserInvitationFormValues) => {
		try {
			await mutation.mutateAsync({
				organizationId,
				email,
				name,
				role,
			})
			await queryClient.invalidateQueries({ refetchType: 'all' })
			onHide?.()
		} catch (e) {
			console.error(e)
		}
	}, [ mutation, onHide, organizationId, queryClient ])

	return (
		<Modal {...props}>
			<View minWidth="300px" width="30vw" flexGrow={1} flexDirection="column">
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onInviteUser)}
					width="100%"
					gap="20px"
				>
					<FormControl label="Email">
						<Input
							{...register('email')}
							type="email"
						/>
					</FormControl>
					<FormControl label="Full name">
						<Input
							{...register('name')}
						/>
					</FormControl>
					<FormControl label="Role">
						<Select
							{...register('role')}
							onChange={role => setValue('role', role)}
							items={Object.values(Role)}
							selected={watch('role')}
						/>
					</FormControl>
					<View mt={2}>
						<Button variant="primary" size="medium">Invite user</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
