import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthorizeGithubMutation } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { switchOrganization } from '../../useOrganization'

function GithubAuthPage() {
	const graphQLClient = useGraphQLClient()
	const mutation = useAuthorizeGithubMutation(graphQLClient)
	const [ params ] = useSearchParams()
	const navigate = useNavigate()

	const installationId = params.get('installation_id')!
	const organizationId = params.get('state')!
	const setupAction = params.get('setup_action')!

	useEffect(() => {
		mutation.mutate({
			installationId,
			organizationId,
			setupAction,
		}, {
			onSuccess: () => {
				switchOrganization(navigate, organizationId, 'connections/github')
			},
		})
	}, [ installationId, mutation, navigate, organizationId, params, setupAction ])

	return (
		<View flexDirection="column">
			<Text color="text">Authorize Github</Text>
		</View>
	)
}

export default GithubAuthPage
