import React, {
	useState,
	useCallback,
	SyntheticEvent,
} from 'react'
import styled from 'styled-components'
import { useOnClickOutside } from '../../utils/useOnClickOutside'
import { View } from '../../View'
import {
	Chevron,
	SelectWrapper,
	SelectBody,
	Option,
	SelectProps,
} from '../Select'
import { Input } from '../Input'

const Checkbox = styled.input.attrs({ type: 'checkbox' })({})

export interface MultiSelectProps<Value> extends Omit<SelectProps<Value>,  'selected' | 'onChange'> {
	selected: Value[]
	onChange: (selected: Value[]) => void
}

const MultiSelect = <Value,>({
	itemToLabel,
	items,
	placeholder,
	selected,
	disabled,
	onChange
}: MultiSelectProps<Value>) => {
	const [ isOpen, setOpen ] = useState<boolean>(false)
	const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false))

	const counter: number = selected.length

	const toggleSelect = useCallback((e: SyntheticEvent) => {
		!disabled && setOpen(!isOpen)
		e.stopPropagation()
		e.preventDefault()
	}, [ disabled, isOpen ])

	const onOptionClick = useCallback((value: Value) => () => {
		if (selected.includes(value)) {
			onChange(selected.filter(v => v !== value))
			return
		}
		onChange([ ...selected, value ])
	}, [ onChange, selected ])

	return (
		<SelectWrapper ref={ref}>
			<Input
				icon={<Chevron size={16} onClick={toggleSelect} open={isOpen}/>}
				onClick={toggleSelect}
				readOnly
				value={counter + ' ' + placeholder}
			/>
			<SelectBody open={isOpen}>
				{items.map(item => (
					<Option
						key={itemToLabel?.(item) ?? String(item)}
						onClick={onOptionClick(item)}
						selected={selected.includes(item)}
					>
						<>
							<View
								as={Checkbox}
								checked={selected.includes(item)}
								onChange={onOptionClick(item)}
								cursor="inherit"
								mr={1}
							/>
							{itemToLabel?.(item) ?? item}
						</>
					</Option>
				))}
			</SelectBody>
		</SelectWrapper>
	)
}

export default MultiSelect
