import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { Text, TextLink } from '../Text'

interface TextChartProps {
	children: string
}

const A = styled.a``

function TextChart({ children }: TextChartProps) {
	return (
		<Text
			color="text"
			display="inline-block"
			whiteSpace="normal"
			width="100%"
		>
			<ReactMarkdown
				components={{
					a: props => (
						<TextLink
							as={A}
							target="_blank"
							{...props}
						/>
					),
				}}
			>
				{children}
			</ReactMarkdown>
		</Text>
	)
}

export {
	TextChart,
}
