import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeElementsOptions } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { Text } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import React, { FormEventHandler, useCallback, useEffect, useState } from 'react'
import { CreditCard } from 'react-feather'
import { useSearchParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { STRIPE_PUBLISHABLE_KEY } from '../../config'
import {
	useCreateStripeSetupIntentMutation,
	useGetStripeCustomerQuery,
	useSyncStripeCustomerMutation,
} from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

function CreditCardForm() {
	const stripe = useStripe()
	const elements = useElements()
	const onSaveClick = useCallback<FormEventHandler>(async e => {
		e.preventDefault()
		await stripe?.confirmSetup({
			elements: elements!,
			confirmParams: {
				return_url: window.location.href,
			},
		})
	}, [ elements, stripe ])
	return (
		<Card p={2} flexDirection="column">
			<form onSubmit={onSaveClick}>
				<PaymentElement/>
				<View mt={2}/>
				<Button
					size="medium"
					variant="primary"
				>
					Save credit card
				</Button>
			</form>
		</Card>
	)
}

function CreditCardPage() {
	const theme = useTheme() as Theme
	const [ clientSecret, setClientSecret ] = useState<string>()
	const [ showCreditCardForm, setShowCreditCardForm ] = useState(false)
	const options: StripeElementsOptions = {
		clientSecret: clientSecret,
		appearance: {
			theme: 'none',
			labels: 'above',
			disableAnimations: true,
			variables: {
				colorText: theme.colors.text,
				colorBackground: theme.colors.cardInputBackground,
			},
			rules: {
				'.Input': {
					border: theme.input.border,
					outline: 'none',
				},
				'.DropDown': {
					cursor: 'auto',
				},
			},
		},
	}
	const [ searchParams, setSearchParams ] = useSearchParams()
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const { data: me, remove } = useGetStripeCustomerQuery(graphQLClient, { organizationId })
	const createStripeSetupIntent = useCreateStripeSetupIntentMutation(graphQLClient)
	const syncStripeCustomerMutation = useSyncStripeCustomerMutation(graphQLClient)

	useEffect(() => {
		const setupIntentId = searchParams.get('setup_intent')
		if (setupIntentId) {
			syncStripeCustomerMutation.mutate({
				organizationId,
				setupIntentId
			}, {
				onSuccess: () => {
					remove()
					setSearchParams({})
				}
			})
		}
	}, [ organizationId, remove, searchParams, setSearchParams, syncStripeCustomerMutation ])

	const onShowCreditCardFormClick = useCallback(async () => {
		await setShowCreditCardForm(true)
		const setupIntent = await createStripeSetupIntent.mutateAsync({ organizationId })
		setClientSecret(setupIntent.createStripeSetupIntent.client_secret)
	}, [ createStripeSetupIntent, organizationId ])

	return (
		<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
			<Card p={2} flexDirection="column">
				<Text color="text">Your card will not be charged until you select a paid plan.</Text>
			</Card>
			{me?.me.organizations?.[0].pm_type &&
			<Card p={2} flexDirection="column">
				<View mb={2}>
					<Text color="text">The following payment method will be used to pay due invoices</Text>
				</View>
				<Text color="text" alignItems="center">
					<View as={CreditCard} mr={2}/>
					{`${me?.me.organizations?.[0].pm_type}: XXXX XXXX XXXX ${me?.me.organizations?.[0].pm_last_four}`}
				</Text>
			</Card>
			}
			{!showCreditCardForm &&
			<Card p={2}>
				<Button
					onClick={onShowCreditCardFormClick}
					size="medium"
					variant="primary"
				>
					Add credit card
				</Button>
			</Card>
			}
			{showCreditCardForm && !clientSecret &&
			<Card p={2}>
				<Text color="text">
					LOADING!
				</Text>
			</Card>
			}
			{showCreditCardForm && clientSecret &&
			<Elements stripe={stripePromise} options={options}>
				<CreditCardForm/>
			</Elements>
			}
		</View>
	)
}

export default CreditCardPage
