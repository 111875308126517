import Tippy from '@tippyjs/react'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Archive, Send } from 'react-feather'
import ReactMarkdown from 'react-markdown'
import styled, { useTheme } from 'styled-components'
import { Button } from '../Button'
import { Card } from '../Card'
import { TextArea } from '../inputs/Textarea'
import { PreText, Text, TextLink } from '../Text'
import Theme from '../theme/Theme'
import { useOnClickOutside } from '../utils/useOnClickOutside'
import { View } from '../View'

const StyledMessage = styled(View)`
	& li, & li > p {
		padding: 0;
		margin: 0;
	}

	& ol, & p {
		margin: 0;
	}
`

const A = styled.a``

interface Message {
	id: string
	author: 'assistant' | 'user'
	content: string
	sent_at: string
}

function MessageCard(message: Message) {
	const theme = useTheme() as Theme
	const isUser = message.author === 'user'
	return (
		<StyledMessage flexDirection="column">
			<Text
				color="text"
				mx={1}
				fontSize="12px"
				alignSelf={isUser ? 'flex-end' : undefined}
				flexDirection={isUser ? 'row-reverse' : undefined}
			>
				<span>{isUser ? 'You' : 'Instructor'}</span> · <span>{message.sent_at}</span>
			</Text>
			<Card
				padding={2}
				display="block"
				width="fit-content"
				alignSelf={isUser ? 'flex-end' : undefined}
				maxWidth="90%"
				{...(isUser ? {
					backgroundColor: theme.colors.primary,
					boxShadow: '0px 0px 40px -10px ' + theme.colors.primary,
				} : {})}
			>
				<Text color="text" display="initial">
					<ReactMarkdown
						components={{
							a: props => (
								<TextLink
									as={A}
									target="_blank"
									color={isUser ? theme.colors.text : undefined}
									{...props}
								/>
							),
							pre: PreText as any,
						}}
					>
						{message.content}
					</ReactMarkdown>
				</Text>
			</Card>
		</StyledMessage>
	)
}

interface SidebarProps {
	messages: Message[]
	onSendMessage: (message: string) => Promise<void>
	onArchiveConversation?: () => Promise<void>
}

function Sidebar({ messages, onSendMessage, onArchiveConversation }: SidebarProps) {
	const theme = useTheme() as Theme
	const [ isAssistantVisible, setIsAssistantVisible ] = useState(false)
	const [ prompt, setPrompt ] = useState('')

	const re = useOnClickOutside(() => setIsAssistantVisible(false))

	const onTextAreaChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
		setPrompt(e.currentTarget.value)
	}, [])

	const onSendClick = useCallback(async () => {
		await onSendMessage(prompt)
		setPrompt('')
	}, [ onSendMessage, prompt ])

	const onArchiveConversationClick = useCallback(async () => {
		await onArchiveConversation?.()
	}, [ onArchiveConversation ])

	useEffect(() => {
		if (re?.current) {
			const scrollView = re.current.querySelector('span')!
			scrollView.scrollTop = scrollView.scrollHeight
		}
	}, [ messages ])

	return (
		<View
			position="fixed"
			paddingY={10}
			right={isAssistantVisible ? 0 : -596}
			top={0}
			bottom={0}
			height="100%"
			width={600}
			backgroundColor={theme.colors.rootBackgroundColor}
			borderBottomLeftRadius={10}
			borderTopLeftRadius={10}
			borderLeft="4px solid"
			borderLeftColor={theme.colors.primary}
			zIndex={999}
			onClick={() => setIsAssistantVisible(true)}
			elevation={isAssistantVisible ? 3 : 0}
			ref={re}
			flexDirection="column"
			gap={20}
		>
			<View
				overflowY="auto"
				paddingX={10}
				flexDirection="column"
				gap={20}
				flexGrow={1}
			>
				{messages.map((message) => (
					<MessageCard
						key={message.id}
						{...message}
					/>
				))}
			</View>
			<View
				gap={10}
				paddingX={10}
			>
				<TextArea
					onChange={onTextAreaChange}
					placeholder="Please help me with ..."
					rows={5}
					value={prompt}
				/>
				<View flexDirection="column" gap={10}>
					<Tippy
						content="Ctrl + enter to send"
					>
						<Button
							disabled={!prompt}
							onClick={onSendClick}
							variant="primary"
							size="icon"
							flexGrow={1}
						>
							<Send size={20}/>
						</Button>
					</Tippy>
					<Tippy
						content="Archive conversation"
					>
						<Button
							disabled={!onArchiveConversation}
							onClick={onArchiveConversationClick}
							variant="outline"
							size="icon"
						>
							<View p="2px">
								<Archive size={16}/>
							</View>
						</Button>
					</Tippy>
				</View>
			</View>
		</View>
	)
}

export {
	Sidebar,
}
