import { Sidebar } from '@withdiver/components/src/Assistant/Sidebar'
import { formatRelative, parseISO } from 'date-fns'
import { enCA } from 'date-fns/locale'
import Pusher from 'pusher-js'
import { useCallback, useEffect } from 'react'
import { useAuth } from '../AuthProvider'
import { GRAPHQL_API_URL } from '../config'
import {
	ConversationMessageAuthor,
	OnConversationMessageUpdatedDocument,
	OnConversationMessageUpdatedSubscriptionVariables,
	useCreateConversationMutation,
	useGetAssistantConversationQuery,
	useSendAssistantConversationMessageMutation,
} from '../generated/graphql'
import { useGraphQLClient } from '../useGraphQLClient'
import { useOrganization } from '../useOrganization'

const locale = {
	...enCA,
	formatRelative: (token: string) => ({
		lastWeek: "eeee 'at' p",
		yesterday: "'Yesterday at' p",
		today: 'p',
		other: 'PPPP p',
	}[token]),
}

function Assistant() {
	const graphQLClient = useGraphQLClient()
	const auth = useAuth()
	const { id: organizationId } = useOrganization()
	const createConversation = useCreateConversationMutation(graphQLClient)
	const {
		data,
		refetch: refetchConversation,
	} = useGetAssistantConversationQuery(
		graphQLClient,
		{ organizationId },
		{ enabled: Boolean(organizationId) },
	)
	const mutation = useSendAssistantConversationMessageMutation(graphQLClient)
	const conversation = data?.me.conversations?.[0]

	const onSendMessage = useCallback(async (message: string) => {
		if (!conversation) {
			return
		}
		await mutation.mutateAsync({
			conversationId: conversation.id,
			content: message,
		})
		await refetchConversation()
	}, [ conversation, mutation, refetchConversation ])

	const onArchiveConversation = useCallback(async () => {
		if (!auth.user?.id) {
			return
		}
		await createConversation.mutateAsync({
			organizationId,
			userId: auth.user.id,
		})
		await refetchConversation()
	}, [ auth, createConversation, organizationId, refetchConversation ])

	const conversationId = conversation?.id

	useEffect(() => {
		if (!conversationId) {
			return
		}
		graphQLClient.rawRequest<any, OnConversationMessageUpdatedSubscriptionVariables>(
			OnConversationMessageUpdatedDocument,
			{
				conversationId,
			}
		).then(resp => {
			const channelName = resp?.extensions?.lighthouse_subscriptions?.channel

			if (!channelName) {
				return
			}

			const p = new Pusher(
				'0a448ea4a817fbdf7dc9',
				{
					cluster: 'eu',
					authEndpoint: `${GRAPHQL_API_URL}/subscriptions/auth`,
					auth: {
						headers: {
							authorization: auth.token,
						},
					},
				},
			)
			const channel = p.subscribe(channelName)
			channel.bind(
				'lighthouse-subscription',
				async () => {
					await refetchConversation({
						cancelRefetch: false,
					})
				},
			)
		})
	}, [ conversationId, refetchConversation ]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Sidebar
			messages={conversation?.messages.map(message => ({
				id: message.id,
				author: message.author,
				content: message.content,
				sent_at: formatRelative(parseISO(message.sent_at), new Date(), {
					locale,
					weekStartsOn: 1,
				}),
			})) ?? []}
			onSendMessage={onSendMessage}
			onArchiveConversation={
				conversation?.messages.some(m => m.author === ConversationMessageAuthor.User)
					? onArchiveConversation
					: undefined
			}
		/>
	)
}

export {
	Assistant,
}
