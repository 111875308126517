import { useQueryClient } from '@tanstack/react-query'
import Tippy from '@tippyjs/react'
import { Card } from '@withdiver/components/src/Card'
import { ListView } from '@withdiver/components/src/ListView'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import { PreText, Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { formatRelative, parseISO } from 'date-fns'
import { enCA } from 'date-fns/locale'
import React, { useCallback } from 'react'
import { Key } from 'react-feather'
import { CliToken, useDeleteCliTokenMutation, useGetCliTokensQuery } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'

const locale = {
	...enCA,
	formatRelative: (token: string) => ({
		lastWeek: 'eeee \'at\' p',
		yesterday: '\'Yesterday at\' p',
		today: 'p',
		other: 'PPPP p',
	}[token]),
}

function CommandLineTokenListPage() {
	const graphQLClient = useGraphQLClient()
	const queryClient = useQueryClient()
	const { id } = useOrganization()
	const { data } = useGetCliTokensQuery(graphQLClient, { organizationId: id! })
	const deleteCliToken = useDeleteCliTokenMutation(graphQLClient)

	const onDeleteClick = useCallback((cliTokenId: CliToken['id']) => async () => {
		await deleteCliToken.mutateAsync({ cliTokenId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ deleteCliToken, queryClient ])

	return (
		<ListView width="100%" maxWidth={1100} gap={20}>
			<Card p={2}>
				<Text color="text" alignItems="baseline">
					To create a new Diver CLI Token, run
					{' '}
					<PreText>diver login</PreText>
					{' '}
					from your terminal.
					{' '}
					<TextLink to="/docs/category/cli" target="_blank">Learn more</TextLink>
				</Text>
			</Card>
			<Text
				color="text"
				display="flex"
				maxWidth={1100}
				px={3}
				fontWeight="bold"
			>
				<View minWidth="24px" mr={2}/>
				<View width="40%">Token Name</View>
				<View width="20%"/>
				<View width="20%">Last Used</View>
				<View width="20%">Preview</View>
				<View width="4%"/>
			</Text>
			{data?.me.cliTokens.map((token) => (
				<Card
					alignItems="baseline"
					key={token.api_key_preview}
					maxWidth={1100}
					p={3}
				>
					<Tippy content={token.operating_system}>
						<Text
							color="text"
							alignSelf="center"
							mr={2}
							position="relative"
						>
							<View as={Key} size={3}/>
						</Text>
					</Tippy>
					<Text
						display="block"
						color="text"
						fontSize={1}
						width="40%"
						textOverflow="ellipsis"
						overflow="hidden"
						title={token.name}
						whiteSpace="nowrap"
					>
						{token.name}
					</Text>
					<Text
						display="block"
						color="text"
						fontSize={1}
						width="20%"
						textOverflow="ellipsis"
						overflow="hidden"
						title={token.operating_system}
						whiteSpace="nowrap"
					>
						{token.operating_system}
					</Text>
					<Text
						display="block"
						color="text"
						fontSize={1}
						width="20%"
						textOverflow="ellipsis"
						overflow="hidden"
						title={token.last_used_at ?? 'Never'}
						whiteSpace="nowrap"
					>
						{token.last_used_at
							? formatRelative(parseISO(token.last_used_at), new Date(), {
								locale,
								weekStartsOn: 1,
							})
							: <i>Never</i>
						}
					</Text>
					<Text
						display="block"
						color="text"
						fontSize={1}
						width="20%"
						textOverflow="ellipsis"
						overflow="hidden"
						title={token.api_key_preview}
						whiteSpace="nowrap"
					>
						{token.api_key_preview}
					</Text>
					<Text
						color="text"
						fontSize={1}
						width="4%"
						textOverflow="ellipsis"
						justifyContent="flex-end"
						whiteSpace="nowrap"
					>
						<OverflowActionMenu>
							<TextLink
								to=""
								color="danger"
								onClick={onDeleteClick(token.id)}
							>
								Delete
							</TextLink>
						</OverflowActionMenu>
					</Text>
				</Card>
			))}
			{!data?.me.cliTokens.length &&
			<Card p={2}>
				<Text color="text">No tokens yet</Text>
			</Card>
			}
		</ListView>
	)
}

export default CommandLineTokenListPage
