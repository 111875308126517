import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useCreateOrganizationMutation } from '../generated/graphql'
import { switchOrganization } from '../useOrganization'

interface OrganizationCreationModalProps extends ModalProps {
	graphQLClient: GraphQLClient
}

interface OrganizationCreationFormValues {
	name: string
}

const Form = styled.form``

export function OrganizationCreationModal(props: OrganizationCreationModalProps) {
	const queryClient = useQueryClient()
	const mutation = useCreateOrganizationMutation(props.graphQLClient)
	const { register, handleSubmit } = useForm<OrganizationCreationFormValues>()
	const navigate = useNavigate()

	const onHide = props.onHide
	const onCreateDashboard = useCallback(async ({ name }: OrganizationCreationFormValues) => {
		try {
			const response = await mutation.mutateAsync({ organizationName: name })
			await queryClient.invalidateQueries({ refetchType: 'all' })
			switchOrganization(navigate, response.createOrganization.id)
			onHide?.()
		} catch (e) {
			console.error(e)
		}
	}, [ mutation, navigate, onHide, queryClient ])

	return (
		<Modal {...props}>
			<View minWidth="300px" width="30vw" flexGrow={1} flexDirection="column">
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onCreateDashboard)}
					width="100%"
				>
					<FormControl label="Workplace name">
						<Input
							{...register('name')}
						/>
					</FormControl>
					<View mt={2}>
						<Button variant="primary" size="medium">Create workplace</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
