import React, { FC, forwardRef, TextareaHTMLAttributes } from 'react'
import styled, { useTheme } from 'styled-components'
import Theme from '../../theme/Theme'
import { View } from '../../View'

interface TextAreaProps extends Partial<TextareaHTMLAttributes<HTMLTextAreaElement>> {
	as?: string | React.ComponentType
}

const StyledTextArea = styled.textarea(
	{
		background: 'inherit',
		border: 'none',
		borderRadius: 'inherit',
		color: 'inherit',
		outline: 'none',
		padding: '10px',
		width: '100%',
		zIndex: 1,
	},
	{
		resize: 'none',
	},
)

const TextArea = forwardRef<HTMLTextAreaElement>(({ as, ...props }: TextAreaProps, ref) => {
	const theme = useTheme() as Theme
	return (
		<View
			background={theme.colors.cardInputBackground}
			borderRadius={6}
			color={props.color ?? theme.colors.text}
			border={theme.input.border}
			width="100%"
		>
			<StyledTextArea {...props} ref={ref}/>
		</View>
	)
}) as FC<TextAreaProps>

export {
	TextArea,
}
