import { Card } from '@withdiver/components/src/Card'
import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React from 'react'
import Sidebar from '../Sidebar'

function ActivityPage() {
	return (
		<View>
			<Sidebar/>
			<View px={[0, 40]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Activity</Text>
				</View>
				<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
					<Card p={2}>
						<Text color="text">
							The activity log keeps track of changes made by users through the web interface and the CLI.
							{'\n\n'}
							All changes in the past 14 days will be available here.
						</Text>
					</Card>
					<Card p={2}>
						<Text color="text">
							No changes in the past 14 days have been made.
						</Text>
					</Card>
				</View>
			</View>
		</View>
	)
}

export default ActivityPage
