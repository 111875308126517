import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { Window } from '@withdiver/components/src/windows'
import { CheckCircle } from 'react-feather'

function CommandLineAuthCompletedPage() {
	return (
		<View
			alignItems="center"
			height="100vh"
			justifyContent="center"
			width="100vw"
		>
			<Window title="Terminal">
				<View
					alignItems="center"
					height="200px"
					justifyContent="center"
					width="500px"
					flexDirection="column"
				>
					<Text color="success">
						<CheckCircle size={50}/>
					</Text>
					<Text color="success" fontSize={4}>
						Authorized
					</Text>
					<TextLink
						color="text"
						mt={2}
						to={`/`}
					>
						Continue →
					</TextLink>
				</View>
			</Window>
		</View>
	)
}

export default CommandLineAuthCompletedPage
