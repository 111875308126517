import { useQueryClient } from '@tanstack/react-query'
import Tippy from '@tippyjs/react'
import { Card } from '@withdiver/components/src/Card'
import { Spinner } from '@withdiver/components/src/Dashboard'
import { ListView } from '@withdiver/components/src/ListView'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import { AlertCircle, CheckCircle, Database, Settings } from 'react-feather'
import { Source, useGetSourcesQuery, useRefreshSourceMutation } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'

function SourceListPage() {
	const graphQLClient = useGraphQLClient()
	const queryClient = useQueryClient()
	const { id } = useOrganization()
	const { data } = useGetSourcesQuery(graphQLClient, { organizationId: id! })
	const refreshSource = useRefreshSourceMutation(graphQLClient)

	const onSyncClick = useCallback((sourceId: Source['id']) => async () => {
		await refreshSource.mutateAsync({ sourceId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ refreshSource, queryClient ])

	return (
		<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
			<Text
				color="text"
				display="flex"
				maxWidth={1100}
				px={3}
				fontWeight="bold"
			>
				<View minWidth="24px" mr={2}/>
				<View width="40%">Source Name</View>
				<View width="30%">Host</View>
				<View width="20%">User</View>
				<View width="20%">Database</View>
				<View width="4%"/>
			</Text>
			<ListView gap={20}>
				{data?.sources.map(source => (
					<Card p={3} maxWidth={1100} alignItems="baseline">
						<Tippy content={source.is_syncing ? 'Synchronizing almanac' : source.failed_at ? 'Connection failed' : 'Connected successfully'}>
							<Text
								color="text"
								alignSelf="center"
								mr={2}
								position="relative"
							>
								<View as={Database} size={3}/>
								<Text
									color={source.is_syncing ? 'info' : source.failed_at ? 'danger' : 'success'}
								>
									<View
										backdropFilter="blur(10px)"
										position="absolute"
										bottom={-3}
										right={-2}
										borderRadius="50%"
									>
										{source.is_syncing ?
										<Spinner>
											<View as={Settings} size={13}/>
										</Spinner>
										:
										<View as={source.failed_at ? AlertCircle : CheckCircle} size={13}/>
										}
									</View>
								</Text>
							</Text>
						</Tippy>
						<Text
							display="block"
							color="text"
							fontSize={3}
							width="40%"
							textOverflow="ellipsis"
							overflow="hidden"
							title={source.name}
							whiteSpace="nowrap"
						>
							{source.name}
						</Text>
						<Text
							display="block"
							color="text"
							fontSize={1}
							width="30%"
							textOverflow="ellipsis"
							overflow="hidden"
							title={source.host}
							whiteSpace="nowrap"
						>
							{source.host}
						</Text>
						<Text
							display="block"
							color="text"
							fontSize={1}
							width="20%"
							textOverflow="ellipsis"
							overflow="hidden"
							title={source.user}
							whiteSpace="nowrap"
						>
							{source.user}
						</Text>
						<Text
							display="block"
							color="text"
							fontSize={1}
							width="20%"
							textOverflow="ellipsis"
							overflow="hidden"
							title={source.database}
							whiteSpace="nowrap"
						>
							{source.database}
						</Text>
						<Text
							color="text"
							fontSize={1}
							width="4%"
							textOverflow="ellipsis"
							justifyContent="flex-end"
							whiteSpace="nowrap"
						>
							<OverflowActionMenu>
								<TextLink to="" color="text">Edit</TextLink>
								{source.is_syncing &&
								<Tippy content="Sync already queued" placement="left">
									<View>
										<TextLink to="" color="text" opacity="0.5" pointerEvents="none">Syncing ...</TextLink>
									</View>
								</Tippy>
								}
								{!source.is_syncing &&
								<TextLink
									to=""
									onClick={onSyncClick(source.id)}
									color="text"
								>
									Sync
								</TextLink>
								}
								<Tippy content="Cannot be deleted - Source is in use" placement="left">
									<View>
										<TextLink to="" color="danger" opacity="0.5" pointerEvents="none">Delete</TextLink>
									</View>
								</Tippy>
							</OverflowActionMenu>
						</Text>
					</Card>
				))}
			</ListView>
		</View>
	)
}

export default SourceListPage
