import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useState } from 'react'
import { GetDomainsQuery, useGetDomainsQuery } from '../../generated/graphql'

interface SamlSsoCreationModalProps extends ModalProps {
	graphQLClient: GraphQLClient
	organizationId: string
}

export function SamlSsoCreationModal(props: SamlSsoCreationModalProps) {
	const { data: domainData } = useGetDomainsQuery(props.graphQLClient, { organizationId: props.organizationId })
	const [ domain, setDomain ] = useState<GetDomainsQuery['domains'][0]>()

	return (
		<Modal {...props}>
			<View
				flexDirection="column"
				flexGrow={1}
				gap="20px"
				minWidth="300px"
				width="30vw"
			>
				<Text color="text" fontSize={20}>Create SAML SSO</Text>
				<Text color="text">
					See instructions for <TextLink to="#">Okta</TextLink>, <TextLink
					to="#">OneLogin</TextLink>, <TextLink
					to="#">Active Directory</TextLink>, <TextLink to="#">and any other SAML IDP</TextLink>.
				</Text>
				<FormControl label="Domain">
					<Select
						items={domainData?.domains ?? []}
						itemToLabel={item => item.name}
						onChange={item => setDomain(item)}
						selected={domain}
					/>
				</FormControl>
				<View>
					<Button
						size="medium"
						variant="primary"
					>
						Begin setup
					</Button>
				</View>
			</View>
		</Modal>
	)
}
