import React from 'react'
import { MoreHorizontal, User } from 'react-feather'
import styled from 'styled-components'
import { Button } from '../../Button'
import { Card } from '../../Card'
import { FormControl } from '../../inputs/FormControl'
import { Input } from '../../inputs/Input'
import { View } from '../../View'

const Form = styled.form``

interface LoginPageProps {
	emailProps: Parameters<typeof Input>[0]
	passwordProps: Parameters<typeof Input>[0]
	onLogin: Partial<HTMLFormElement['onsubmit']>
}

const Link = styled.a({ // TODO: Build Link component
	color: 'darkgrey',
})

export function LoginPage({ emailProps, passwordProps, onLogin }: LoginPageProps) {
	return (
		<View alignItems="center" justifyContent="center" height="100vh" flexDirection="column">
			<View backgroundColor="#3a36db" width="100%" height="100%" bottom="-50%" position="fixed" transform="skew(0deg, -10deg)"/>
			<img src="/logo.svg" width={150} height={150} style={{ margin: '25px' }}/>
			<Card zIndex={10} elevation={1} mb={200}>
				<View as={Form} onSubmit={onLogin} flexDirection="column" width="320px">
					<Card.Content p={20} flexDirection="column">
						<FormControl label="Email">
							<Input {...emailProps} icon={<User size={16}/>}/>
						</FormControl>
						<View mt={1}/>
						<FormControl label="Password">
							<Input type="password" {...passwordProps} icon={<MoreHorizontal size={16}/>}/>
						</FormControl>
						<View mt={2} alignItems="center" justifyContent="space-between">
							<Button variant="primary" size="medium">Log me in!</Button>
							<View as={Link} href="#">Forgot password?</View>
						</View>
					</Card.Content>
				</View>
			</Card>
		</View>
	)
}
