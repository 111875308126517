import { useQueryClient } from '@tanstack/react-query'
import { AggregationPicker } from '@withdiver/components/src/AggregationPicker'
import { Button } from '@withdiver/components/src/Button'
import { Chart, ChartProps as ChartComponentProps, color } from '@withdiver/components/src/charts/Chart'
import { GaugeChart } from '@withdiver/components/src/charts/GaugeChart'
import { TableChart } from '@withdiver/components/src/charts/TableChart'
import { TextChart } from '@withdiver/components/src/charts/TextChart'
import { CodeArea } from '@withdiver/components/src/inputs/CodeArea'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { PreText, Text } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useForm } from 'react-hook-form'
import styled, { useTheme } from 'styled-components'
import {
	ChartType,
	GetDatabaseAlmanacQuery,
	RunQueryMutation,
	useAddQueryToChartMutation,
	useGetDatabaseAlmanacQuery,
	useGetSourcesQuery,
	useRunQueryMutation,
	useSaveQueryMutation,
	useValidateQueryMutation,
} from '../../generated/graphql'

interface QueryEditorModalProps extends ModalProps {
	label?: QueryEditorFormValues['label']
	query: QueryEditorFormValues['query']
	chartId: string
	graphQLClient: GraphQLClient
	organizationId: string
	queryId?: string
	renderSettings?: any
	sourceId?: QueryEditorFormValues['sourceId']
	type?: QueryEditorFormValues['type']
}

const options = {
	[ChartType.Bar]: 'Bar',
	[ChartType.Line]: 'Line',
	[ChartType.Scatter]: 'Scatter',
	[ChartType.Doughnut]: 'Doughnut',
	[ChartType.Gauge]: 'Gauge',
	[ChartType.Table]: 'Table',
	[ChartType.Text]: 'Text',
}

interface QueryEditorFormValues {
	label: string
	query: string
	sourceId: string
	// type: NonNullable<ChartComponentProps['data']['datasets'][number]['type'] | 'table'>
	type: keyof typeof options
	filters?: {
		name: string
		value?: string
		values?: string[]
	}[]
	renderSettings: {
		stacked?: boolean
		columns: [
			{
				visibility: '' | 'hidden'
			}
		]
	}
}

const Form = styled.form``

export function QueryEditorModal(props: QueryEditorModalProps) {
	const { data: sources } = useGetSourcesQuery(props.graphQLClient, { organizationId: props.organizationId })
	const { data: almanac } = useGetDatabaseAlmanacQuery(props.graphQLClient, { organizationId: props.organizationId })
	const validateQuery = useValidateQueryMutation(props.graphQLClient)
	const runQuery = useRunQueryMutation(props.graphQLClient)
	const [ compiledQuery, setCompiledQuery ] = useState<string>()
	const [ queryResult, setQueryResult ] = useState<RunQueryMutation['compileQuery']['result']>()
	const [ filters, setFilters ] = useState<string[]>()
	const [ fontSize, setFontSize ] = useState(13)
	const [ tabSize, setTabSize ] = useState(4)
	const [ activeSettingsTab, setActiveSettingsTab ] = useState('')
	const queryClient = useQueryClient()
	const saveQuery = useSaveQueryMutation(props.graphQLClient)
	const addQueryToChart = useAddQueryToChartMutation(props.graphQLClient)
	const theme = useTheme() as Theme
	const { register, handleSubmit, getValues, setValue, watch } = useForm<QueryEditorFormValues>({
		defaultValues: {
			label: props.label ?? 'Test',
			query: props.query,
			type: props.type ?? ChartType.Bar,
			sourceId: props.sourceId,
			renderSettings: props.renderSettings ?? {},
		},
	})
	const [ sawError, setSawError ] = useState(false)

	const onValidateQuery = useCallback(async ({ query, sourceId }: QueryEditorFormValues) => {
		if (!query || !sourceId) {
			setSawError(false)
			return
		}
		try {
			const response = await validateQuery.mutateAsync({ sourceId, query })
			setFilters(response?.compileQuery.filters)
			setCompiledQuery(response?.compileQuery.query)
			setSawError(false)
		} catch (e) {
			setSawError(true)
		}
	}, [ validateQuery, setSawError ])

	const onRunQuery = useCallback(async () => {
		try {
			const response = await runQuery.mutateAsync({
				sourceId: getValues('sourceId'),
				filters: getValues('filters') ?? [],
				query: getValues('query'),
			})
			setFilters(response?.compileQuery.filters)
			setCompiledQuery(response?.compileQuery.query)
			setQueryResult(response?.compileQuery.result)
			setSawError(false)
		} catch (e) {
			setSawError(true)
		}
	}, [ getValues, runQuery, setSawError ])

	const onHide = props.onHide
	const onSaveQuery = useCallback(async () => {
		try {
			const savedQueryResponse = await saveQuery.mutateAsync({
				queryId: props.queryId,
				sourceId: getValues('sourceId'),
				query: getValues('query'),
			})
			if (!savedQueryResponse.saveQuery) {
				return
			}
			await addQueryToChart.mutateAsync({
				chartId: props.chartId,
				queryId: savedQueryResponse.saveQuery.id,
				label: getValues('label'),
				type: getValues('type'),
				renderSettings: JSON.stringify(getValues('renderSettings')),
			})
		} finally {
			queryClient.invalidateQueries([], { refetchType: 'all' })
		}
		onHide?.()
	}, [
		addQueryToChart,
		getValues,
		onHide,
		props.chartId,
		props.queryId,
		queryClient,
		saveQuery,
	])

	const onFormChange = useCallback(() => {
		onValidateQuery(getValues())
	}, [onValidateQuery, getValues])

	register('sourceId')

	return (
		<Modal {...props}>
			<View height="80vh" width="80vw" flexGrow={1}>
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onValidateQuery)}
					width="inherit"
					gap="10px"
				>
					<View
						width="100%"
						flexBasis={0}
						flexGrow={1}
						gap="40px"
					>
						<View
							flexDirection="column"
							height="100%"
							width="100%"
							gap="10px"
						>
							<CodeArea
								autocompletion={
									almanac
										?.sources
										?.find((source: GetDatabaseAlmanacQuery['sources'][0]) => source.id === watch('sourceId'))
										?.availableSchemas
										.flatMap(schema => [
												{ value: schema.name, score: 1000, meta: 'schema' },
												...schema.tables.flatMap(table => [
														{ value: schema.name + '.' + table.name, score: 3000, meta: 'table' },
														...table.columns.map(column => ({
															value: table.name + '.' + column.name,
															score: 2000,
															meta: 'column (' + column.data_type + ')',
														})),
													],
												),
											],
										)
								}
								fontSize={fontSize}
								backgroundColor={sawError ? '#f00' : undefined}
								onChange={(value) => {
									setValue('query', value)
									onFormChange()
								}}
								tabSize={tabSize}
								showPrintMargin={false}
								value={watch('query')}
								mode="dql"
							/>
							<View flexDirection="row-reverse" gap="20px">
								<Button variant="primary" size="medium" disabled={sawError} onClick={onRunQuery}>
									Run query
								</Button>
								<Select
									items={sources?.sources.map(source => source.id) ?? []}
									itemToLabel={item => sources?.sources.find(s => s.id === item)?.name ?? ''}
									selected={watch('sourceId')}
									onChange={sourceId => setValue('sourceId', sourceId) }
								/>
								<Select
									items={[1, 2, 4, 8]}
									itemToLabel={item => `${item} space${item > 1 ? 's' : ''}`}
									selected={tabSize}
									onChange={setTabSize}
								/>
								<View width="100px">
									<Select
										items={[11, 13, 14, 16, 18]}
										itemToLabel={item => `${item} px`}
										selected={fontSize}
										onChange={setFontSize}
									/>
								</View>
							</View>
						</View>
						<Text color="text" flexDirection="column" width="50%" height="100%" overflow="auto" gap="20px">
							<PreText>{compiledQuery}</PreText>
							{filters?.map(filter => <PreText key={filter}>{filter}</PreText>)}
						</Text>
					</View>
					<View borderTop="2px solid" borderTopColor={theme.colors.rootBackgroundColor}/>
					<View alignSelf="center" alignItems="center">
						<Text color="text" mr={3}>Visualization:</Text>
						<AggregationPicker
							options={options}
							value={getValues('type')}
							onChange={value => setValue('type', value)}
						/>
					</View>
					<View flexGrow={1} flexBasis={0} overflow="auto" gap="40px">
						<Text
							color="text"
							flexDirection="column"
							flexGrow={1}
							overflow="auto"
						>
							{queryResult?.columns.map((column, index) => (
								<View
									flexDirection="column"
									backgroundColor={theme.colors.rootBackgroundColor}
									paddingX={2}
									key={column}
								>
									<View
										paddingY={2}
										width="100%"
										cursor="pointer"
										onClick={() => setActiveSettingsTab(activeSettingsTab === column ? '' : column)}
									>
										<View as={activeSettingsTab === column ? ChevronUp : ChevronDown} mr={2}/>
										{column} ({queryResult?.meta[index].type})
									</View>
									<View
										flexDirection="column"
										display={activeSettingsTab === column ? undefined : 'none'}
									>
										<View alignItems="center">
											<input
												type="checkbox"
												{...register(`renderSettings.columns.${index}.visibility` as any)}
												value="hidden"
											/>
											<Text color="text" ml={2}>Hide this column</Text>
										</View>
									</View>
								</View>
							))}
						</Text>
						<View flexGrow={10} flexBasis={0} overflow="auto">
							{[ChartType.Gauge, ChartType.Table, ChartType.Text].includes(getValues('type')) ||
							<Chart
								type="bar"
								title="hello"
								data={{
									labels: queryResult?.rows.map(row => JSON.parse(row)[0]),
									datasets: queryResult?.columns.slice(1).map((column, index) => {
										if (watch('renderSettings')?.columns?.[index + 1]?.visibility === 'hidden') {
											return undefined as any
										}

										return {
											type: getValues('type') as NonNullable<ChartComponentProps['data']['datasets'][number]['type']>,
											label: column,
											backgroundColor: color(index) + 'bb',
											borderColor: color(index),
											data: queryResult?.rows.flatMap(row => JSON.parse(row)[index + 1]) ?? [],
										}
									})
										.filter(set => set) ?? [],
								}}
								renderSettings={watch('renderSettings')}
							/>
							}
							{getValues('type') === ChartType.Gauge &&
							<GaugeChart
								data={JSON.parse(queryResult?.rows?.[0] ?? '[]')?.[0]}
								colorRanges={
									[2, 3, 4, 5, 6, 7, 8, 9, 10].includes(JSON.parse(queryResult?.rows?.[0] ?? '[]').length - 1)
										? JSON.parse(queryResult?.rows?.[0] ?? '[]').slice(2).map((_, i) => {
											const gaugeData = JSON.parse(queryResult?.rows?.[0] ?? '[]')
											return {
												lower: Number(gaugeData[i + 1]),
												upper: Number(gaugeData[i + 2]),
											}
										})
										: undefined
								}
								renderSettings={watch('renderSettings')}
							/>
							}
							{getValues('type') === ChartType.Table &&
							<Text color="text" width="100%">
								<TableChart
									data={{
										columns: queryResult?.columns ?? [],
										rows: queryResult?.rows.map(row => JSON.parse(row)) ?? [],
									}}
									renderSettings={watch('renderSettings')}
								/>
							</Text>
							}
							{getValues('type') === ChartType.Text &&
							<TextChart>
								{JSON.parse(queryResult?.rows?.[0] ?? '[]')?.[0]}
							</TextChart>
							}
						</View>
					</View>
					<View>
						<Button variant="success" size="medium" type="button" onClick={onSaveQuery}>Save</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
