import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type Alert = {
  __typename?: 'Alert';
  cron: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  metric: Metric;
  name: Scalars['String']['output'];
  next_run_at?: Maybe<Scalars['DateTime']['output']>;
  runs: Array<AlertRun>;
  state?: Maybe<AlertState>;
};

export type AlertRun = {
  __typename?: 'AlertRun';
  id: Scalars['ID']['output'];
  status: AlertStatus;
  triggered_at: Scalars['DateTime']['output'];
};

export enum AlertState {
  Lowered = 'lowered',
  Raised = 'raised',
  Suspended = 'suspended'
}

export enum AlertStatus {
  Failed = 'failed',
  Pending = 'pending',
  Running = 'running',
  Succeeded = 'succeeded'
}

export type AlmanacColumn = {
  __typename?: 'AlmanacColumn';
  data_type: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AlmanacSchema = {
  __typename?: 'AlmanacSchema';
  name: Scalars['String']['output'];
  source: Source;
  tables: Array<AlmanacTable>;
};

export type AlmanacTable = {
  __typename?: 'AlmanacTable';
  columns: Array<AlmanacColumn>;
  name: Scalars['String']['output'];
};

export type Authentication = {
  __typename?: 'Authentication';
  secret?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
  user: User;
};

export type Authorization = {
  __typename?: 'Authorization';
  organization_id: Scalars['ID']['output'];
  secret: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user: User;
};

export type Chart = {
  __typename?: 'Chart';
  datasets: Array<DataSet>;
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: ChartType;
  width: Scalars['Int']['output'];
  x: Scalars['Int']['output'];
  y: Scalars['Int']['output'];
};

export enum ChartType {
  Bar = 'bar',
  Doughnut = 'doughnut',
  Gauge = 'gauge',
  Line = 'line',
  Scatter = 'scatter',
  Table = 'table',
  Text = 'text'
}

export type CliSecret = {
  __typename?: 'CliSecret';
  name: Scalars['String']['output'];
  operating_system: OperatingSystem;
};

export type CliToken = {
  __typename?: 'CliToken';
  api_key_preview: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last_used_at?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  operating_system: OperatingSystem;
  organization: Organization;
  user: User;
};

export type CompiledQuery = {
  __typename?: 'CompiledQuery';
  bindings: Array<Scalars['String']['output']>;
  filters: Array<Scalars['String']['output']>;
  query: Scalars['String']['output'];
  result?: Maybe<QueryResult>;
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['ID']['output'];
  messages: Array<ConversationMessage>;
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  author: ConversationMessageAuthor;
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sent_at: Scalars['DateTime']['output'];
};

export enum ConversationMessageAuthor {
  Assistant = 'assistant',
  User = 'user'
}

export type Dashboard = {
  __typename?: 'Dashboard';
  chartCount: Scalars['Int']['output'];
  charts: Array<Chart>;
  columns: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization_id: Scalars['ID']['output'];
  referencedFilters: Array<ReferencedQueryFilter>;
  rows: Scalars['Int']['output'];
};

export type DataSet = {
  __typename?: 'DataSet';
  compiledQuery?: Maybe<CompiledQuery>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  metric: Metric;
  render_settings?: Maybe<Scalars['String']['output']>;
  type: ChartType;
};


export type DataSetCompiledQueryArgs = {
  filters: Array<QueryFilterInput>;
};

export type Domain = {
  __typename?: 'Domain';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization_id: Scalars['ID']['output'];
  record_domain: Scalars['String']['output'];
  record_type: DomainRecordType;
  record_value: Scalars['String']['output'];
  verified_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum DomainRecordType {
  Txt = 'TXT'
}

export type Invoice = {
  __typename?: 'Invoice';
  date: Scalars['DateTime']['output'];
  raw_total: Scalars['Int']['output'];
  total: Scalars['String']['output'];
};

export type Member = {
  __typename?: 'Member';
  organization: Organization;
  role: Role;
  user: User;
};

export type Metric = {
  __typename?: 'Metric';
  diver_query: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: Source;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptUserInvitation: UserInvitation;
  addDomain: Domain;
  addQueryToChart: DataSet;
  addSource: Source;
  authenticate: Authentication;
  authorizeCli: CliToken;
  authorizeGithub?: Maybe<Organization>;
  changeOrganizationUserRole?: Maybe<Scalars['ID']['output']>;
  compileQuery: CompiledQuery;
  convertJsonToDashboards: Array<Dashboard>;
  createChart: Chart;
  createConversation: Conversation;
  createDashboard: Dashboard;
  createOrganization: Organization;
  createStripeSetupIntent: StripeSetupIntent;
  deleteAlert?: Maybe<Alert>;
  deleteChart: Chart;
  deleteCliToken?: Maybe<CliToken>;
  deleteDomain?: Maybe<Domain>;
  deleteOrganization: Scalars['ID']['output'];
  deleteOrganizationUser?: Maybe<Scalars['ID']['output']>;
  deleteQueryFilter?: Maybe<QueryFilter>;
  generateCliSecret: Scalars['String']['output'];
  inviteUser: UserInvitation;
  moveChart: Chart;
  ping: Scalars['String']['output'];
  refreshSource: Source;
  saveAlert: Alert;
  saveQuery?: Maybe<Metric>;
  saveQueryFilter: QueryFilter;
  sendConversationMessage?: Maybe<ConversationMessage>;
  signUp: Scalars['Boolean']['output'];
  suspendAlert?: Maybe<Alert>;
  syncStripeCustomer?: Maybe<StripeBillable>;
  testSource: Source;
  triggerAlert: Alert;
  uninviteUser?: Maybe<UserInvitation>;
  unsuspendAlert?: Maybe<Alert>;
  verifyDomain?: Maybe<Domain>;
};


export type MutationAcceptUserInvitationArgs = {
  token: Scalars['String']['input'];
};


export type MutationAddDomainArgs = {
  domain_name: Scalars['String']['input'];
  organization_id: Scalars['ID']['input'];
};


export type MutationAddQueryToChartArgs = {
  chart_id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  query_id: Scalars['ID']['input'];
  render_settings?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type MutationAddSourceArgs = {
  database: Scalars['String']['input'];
  driver: SourceDriver;
  host: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organization_id: Scalars['ID']['input'];
  password: Scalars['String']['input'];
  port: Scalars['Int']['input'];
  user: Scalars['String']['input'];
};


export type MutationAuthenticateArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationAuthorizeCliArgs = {
  name: Scalars['String']['input'];
  organization_id: Scalars['ID']['input'];
  secret: Scalars['String']['input'];
};


export type MutationAuthorizeGithubArgs = {
  installation_id: Scalars['String']['input'];
  organization_id: Scalars['ID']['input'];
  setup_action: Scalars['String']['input'];
};


export type MutationChangeOrganizationUserRoleArgs = {
  organization_id: Scalars['ID']['input'];
  role: Role;
  user_id: Scalars['ID']['input'];
};


export type MutationCompileQueryArgs = {
  dashboard_id?: InputMaybe<Scalars['ID']['input']>;
  filters: Array<QueryFilterInput>;
  fresh?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  query_id?: InputMaybe<Scalars['ID']['input']>;
  source_id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationConvertJsonToDashboardsArgs = {
  data: Scalars['String']['input'];
  organization_id: Scalars['ID']['input'];
  simulation: Scalars['Boolean']['input'];
};


export type MutationCreateChartArgs = {
  dashboard_id: Scalars['ID']['input'];
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};


export type MutationCreateConversationArgs = {
  organization_id: Scalars['ID']['input'];
  user_id: Scalars['ID']['input'];
};


export type MutationCreateDashboardArgs = {
  cache_ttl: Scalars['Int']['input'];
  columns: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  organization_id: Scalars['ID']['input'];
  rows: Scalars['Int']['input'];
};


export type MutationCreateOrganizationArgs = {
  organization_name: Scalars['String']['input'];
};


export type MutationCreateStripeSetupIntentArgs = {
  organization_id: Scalars['ID']['input'];
};


export type MutationDeleteAlertArgs = {
  alert_id: Scalars['ID']['input'];
};


export type MutationDeleteChartArgs = {
  chart_id: Scalars['ID']['input'];
};


export type MutationDeleteCliTokenArgs = {
  cli_token_id: Scalars['ID']['input'];
};


export type MutationDeleteDomainArgs = {
  domain_id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationArgs = {
  organization_id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationUserArgs = {
  organization_id: Scalars['ID']['input'];
  user_id: Scalars['ID']['input'];
};


export type MutationDeleteQueryFilterArgs = {
  query_filter_id: Scalars['ID']['input'];
};


export type MutationGenerateCliSecretArgs = {
  name: Scalars['String']['input'];
  operating_system: OperatingSystem;
};


export type MutationInviteUserArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organization_id: Scalars['ID']['input'];
  role: Role;
};


export type MutationMoveChartArgs = {
  chart_id: Scalars['ID']['input'];
  dashboard_id?: InputMaybe<Scalars['ID']['input']>;
  x?: InputMaybe<Scalars['Int']['input']>;
  y?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRefreshSourceArgs = {
  source_id: Scalars['ID']['input'];
};


export type MutationSaveAlertArgs = {
  alert_id?: InputMaybe<Scalars['ID']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['ID']['input']>;
  query_id?: InputMaybe<Scalars['ID']['input']>;
  source_id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSaveQueryArgs = {
  diver_query: Scalars['String']['input'];
  query_id?: InputMaybe<Scalars['ID']['input']>;
  source_id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSaveQueryFilterArgs = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  query_filter_id?: InputMaybe<Scalars['ID']['input']>;
  query_id?: InputMaybe<Scalars['ID']['input']>;
  source_id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSendConversationMessageArgs = {
  content: Scalars['String']['input'];
  conversation_id: Scalars['ID']['input'];
};


export type MutationSignUpArgs = {
  email: Scalars['String']['input'];
  organization_name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  user_name: Scalars['String']['input'];
};


export type MutationSuspendAlertArgs = {
  alert_id: Scalars['ID']['input'];
};


export type MutationSyncStripeCustomerArgs = {
  organization_id: Scalars['ID']['input'];
  setup_intent_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTestSourceArgs = {
  source_id: Scalars['ID']['input'];
};


export type MutationTriggerAlertArgs = {
  alert_id: Scalars['ID']['input'];
};


export type MutationUninviteUserArgs = {
  invitation_id: Scalars['ID']['input'];
};


export type MutationUnsuspendAlertArgs = {
  alert_id: Scalars['ID']['input'];
};


export type MutationVerifyDomainArgs = {
  domain_id: Scalars['ID']['input'];
};

export enum OperatingSystem {
  Linux = 'linux',
  Macos = 'macos',
  Unknown = 'unknown',
  Windows = 'windows'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Account of a person who utilizes this application. */
export type Organization = StripeBillable & {
  __typename?: 'Organization';
  alerts: Array<Maybe<Alert>>;
  /** When the entity was created. */
  created_at: Scalars['DateTime']['output'];
  dashboards: Array<Dashboard>;
  /** Unique primary key. */
  id: Scalars['ID']['output'];
  invitations: Array<UserInvitation>;
  members: Array<Member>;
  /** Non-unique name. */
  name: Scalars['String']['output'];
  pm_last_four?: Maybe<Scalars['String']['output']>;
  pm_type?: Maybe<Scalars['String']['output']>;
  sources: Array<Source>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  trial_ends_at?: Maybe<Scalars['DateTime']['output']>;
  upcoming_invoice?: Maybe<Invoice>;
  /** When the entity was last updated. */
  updated_at: Scalars['DateTime']['output'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

/** Indicates what fields are available at the top level of a query operation. */
export type Query = {
  __typename?: 'Query';
  alerts: Array<Alert>;
  chart?: Maybe<Chart>;
  cliSecret: CliSecret;
  dashboard?: Maybe<Dashboard>;
  dashboards: Array<Dashboard>;
  domains: Array<Domain>;
  me: User;
  queryFilters: Array<QueryFilter>;
  sources: Array<Source>;
  /** Find a single user by an identifying attribute. */
  user?: Maybe<User>;
  /** List multiple users. */
  users: UserPaginator;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryAlertsArgs = {
  organization_id: Scalars['ID']['input'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryChartArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryCliSecretArgs = {
  secret: Scalars['String']['input'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryDashboardArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryDashboardsArgs = {
  organization_id: Scalars['ID']['input'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryDomainsArgs = {
  organization_id: Scalars['ID']['input'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryQueryFiltersArgs = {
  organization_id: Scalars['ID']['input'];
  source_id?: InputMaybe<Scalars['ID']['input']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QuerySourcesArgs = {
  organization_id: Scalars['ID']['input'];
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


/** Indicates what fields are available at the top level of a query operation. */
export type QueryUsersArgs = {
  first?: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFilter = {
  __typename?: 'QueryFilter';
  compiledQuery?: Maybe<CompiledQuery>;
  filterReferenceCount: Scalars['Int']['output'];
  filterReferences: Array<ReferencedQueryFilter>;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  metric: Metric;
  source: Source;
};


export type QueryFilterCompiledQueryArgs = {
  filters: Array<QueryFilterInput>;
};

export type QueryFilterInput = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryResult = {
  __typename?: 'QueryResult';
  cache: Scalars['Boolean']['output'];
  columns: Array<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  meta: Array<QueryResultMeta>;
  rows: Array<Scalars['String']['output']>;
  runAt: Scalars['DateTime']['output'];
  time: Scalars['Float']['output'];
};

export type QueryResultMeta = {
  __typename?: 'QueryResultMeta';
  type: Scalars['String']['output'];
};

export type ReferencedQueryFilter = {
  __typename?: 'ReferencedQueryFilter';
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  metric: Metric;
  queryFilter?: Maybe<QueryFilter>;
};

export enum Role {
  Admin = 'admin',
  Analyst = 'analyst',
  Viewer = 'viewer'
}

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Source = {
  __typename?: 'Source';
  availableSchemas: Array<AlmanacSchema>;
  database: Scalars['String']['output'];
  failed_at?: Maybe<Scalars['DateTime']['output']>;
  host: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_syncing: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export enum SourceDriver {
  Mysql = 'mysql',
  Postgresql = 'postgresql',
  Redshift = 'redshift',
  Snowflake = 'snowflake'
}

export type StripeBillable = {
  pm_last_four?: Maybe<Scalars['String']['output']>;
  pm_type?: Maybe<Scalars['String']['output']>;
  stripe_id?: Maybe<Scalars['String']['output']>;
  trial_ends_at?: Maybe<Scalars['DateTime']['output']>;
};

export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  client_secret: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  cliAuthorized?: Maybe<Authorization>;
  conversationMessageUpdated?: Maybe<Conversation>;
};


export type SubscriptionCliAuthorizedArgs = {
  secret: Scalars['String']['input'];
};


export type SubscriptionConversationMessageUpdatedArgs = {
  conversationId: Scalars['ID']['input'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

/** Account of a person who utilizes this application. */
export type User = {
  __typename?: 'User';
  cliTokens: Array<CliToken>;
  conversations: Array<Conversation>;
  /** When the account was created. */
  created_at: Scalars['DateTime']['output'];
  dashboards?: Maybe<Array<Maybe<Dashboard>>>;
  /** Unique email address. */
  email: Scalars['String']['output'];
  /** When the email was verified. */
  email_verified_at?: Maybe<Scalars['DateTime']['output']>;
  gravatar: Scalars['String']['output'];
  /** Unique primary key. */
  id: Scalars['ID']['output'];
  /** Non-unique name. */
  name: Scalars['String']['output'];
  organizations: Array<Organization>;
  /** When the account was last updated. */
  updated_at: Scalars['DateTime']['output'];
};


/** Account of a person who utilizes this application. */
export type UserCliTokensArgs = {
  organizationId: Scalars['ID']['input'];
};


/** Account of a person who utilizes this application. */
export type UserConversationsArgs = {
  organizationId: Scalars['ID']['input'];
};


/** Account of a person who utilizes this application. */
export type UserOrganizationsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  accepted_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  expires_at: Scalars['DateTime']['output'];
  gravatar: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization_id: Scalars['ID']['output'];
  role: Role;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type GetAssistantConversationQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetAssistantConversationQuery = { __typename?: 'Query', me: { __typename?: 'User', conversations: Array<{ __typename?: 'Conversation', id: string, messages: Array<{ __typename?: 'ConversationMessage', id: string, author: ConversationMessageAuthor, content: string, sent_at: any }> }> } };

export type CreateConversationMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type CreateConversationMutation = { __typename?: 'Mutation', createConversation: { __typename?: 'Conversation', id: string } };

export type SendAssistantConversationMessageMutationVariables = Exact<{
  conversationId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
}>;


export type SendAssistantConversationMessageMutation = { __typename?: 'Mutation', sendConversationMessage?: { __typename?: 'ConversationMessage', id: string, author: ConversationMessageAuthor, content: string } | null };

export type OnConversationMessageUpdatedSubscriptionVariables = Exact<{
  conversationId: Scalars['ID']['input'];
}>;


export type OnConversationMessageUpdatedSubscription = { __typename?: 'Subscription', conversationMessageUpdated?: { __typename?: 'Conversation', id: string } | null };

export type GetChartQueryVariables = Exact<{
  chartId: Scalars['ID']['input'];
  filters: Array<QueryFilterInput> | QueryFilterInput;
}>;


export type GetChartQuery = { __typename?: 'Query', chart?: { __typename?: 'Chart', title: string, type: ChartType, datasets: Array<{ __typename?: 'DataSet', type: ChartType, label: string, render_settings?: string | null, compiledQuery?: { __typename?: 'CompiledQuery', filters: Array<string>, result?: { __typename?: 'QueryResult', columns: Array<string>, rows: Array<string> } | null } | null }> } | null };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email: string, name: string, gravatar: string, organizations: Array<{ __typename?: 'Organization', id: string, name: string }> } };

export type PingMutationVariables = Exact<{ [key: string]: never; }>;


export type PingMutation = { __typename?: 'Mutation', ping: string };

export type GetAlertsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetAlertsQuery = { __typename?: 'Query', alerts: Array<{ __typename?: 'Alert', id: string, name: string, cron: string, next_run_at?: any | null, state?: AlertState | null, metric: { __typename?: 'Metric', id: string, diver_query: string, source: { __typename?: 'Source', id: string } } }> };

export type DeleteAlertMutationVariables = Exact<{
  alertId: Scalars['ID']['input'];
}>;


export type DeleteAlertMutation = { __typename?: 'Mutation', deleteAlert?: { __typename?: 'Alert', id: string } | null };

export type SaveAlertMutationVariables = Exact<{
  alertId?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  queryId?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  cron?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveAlertMutation = { __typename?: 'Mutation', saveAlert: { __typename?: 'Alert', id: string } };

export type SuspendAlertMutationVariables = Exact<{
  alertId: Scalars['ID']['input'];
}>;


export type SuspendAlertMutation = { __typename?: 'Mutation', suspendAlert?: { __typename?: 'Alert', id: string } | null };

export type TriggerAlertMutationVariables = Exact<{
  alertId: Scalars['ID']['input'];
}>;


export type TriggerAlertMutation = { __typename?: 'Mutation', triggerAlert: { __typename?: 'Alert', id: string } };

export type UnsuspendAlertMutationVariables = Exact<{
  alertId: Scalars['ID']['input'];
}>;


export type UnsuspendAlertMutation = { __typename?: 'Mutation', unsuspendAlert?: { __typename?: 'Alert', id: string } | null };

export type GetCliSecretQueryVariables = Exact<{
  secret: Scalars['String']['input'];
}>;


export type GetCliSecretQuery = { __typename?: 'Query', cliSecret: { __typename?: 'CliSecret', name: string, operating_system: OperatingSystem } };

export type AuthorizeCliMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  secret: Scalars['String']['input'];
}>;


export type AuthorizeCliMutation = { __typename?: 'Mutation', authorizeCli: { __typename?: 'CliToken', api_key_preview: string } };

export type AuthorizeGithubMutationVariables = Exact<{
  installationId: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  setupAction: Scalars['String']['input'];
}>;


export type AuthorizeGithubMutation = { __typename?: 'Mutation', authorizeGithub?: { __typename: 'Organization' } | null };

export type GetStripeCustomerQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetStripeCustomerQuery = { __typename?: 'Query', me: { __typename?: 'User', organizations: Array<{ __typename?: 'Organization', stripe_id?: string | null, pm_type?: string | null, pm_last_four?: string | null, trial_ends_at?: any | null }> } };

export type CreateStripeSetupIntentMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type CreateStripeSetupIntentMutation = { __typename?: 'Mutation', createStripeSetupIntent: { __typename?: 'StripeSetupIntent', id: string, client_secret: string } };

export type SyncStripeCustomerMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  setupIntentId?: InputMaybe<Scalars['String']['input']>;
}>;


export type SyncStripeCustomerMutation = { __typename?: 'Mutation', syncStripeCustomer?: { __typename?: 'Organization', stripe_id?: string | null, pm_type?: string | null, pm_last_four?: string | null, trial_ends_at?: any | null } | null };

export type GetUpcomingInvoiceQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetUpcomingInvoiceQuery = { __typename?: 'Query', me: { __typename?: 'User', organizations: Array<{ __typename?: 'Organization', upcoming_invoice?: { __typename?: 'Invoice', date: any, raw_total: number, total: string } | null }> } };

export type GetCliTokensQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetCliTokensQuery = { __typename?: 'Query', me: { __typename?: 'User', cliTokens: Array<{ __typename?: 'CliToken', id: string, api_key_preview: string, last_used_at?: any | null, name: string, operating_system: OperatingSystem }> } };

export type DeleteCliTokenMutationVariables = Exact<{
  cliTokenId: Scalars['ID']['input'];
}>;


export type DeleteCliTokenMutation = { __typename?: 'Mutation', deleteCliToken?: { __typename?: 'CliToken', id: string } | null };

export type AddSourceMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  driver: SourceDriver;
  host: Scalars['String']['input'];
  port: Scalars['Int']['input'];
  database: Scalars['String']['input'];
  user: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type AddSourceMutation = { __typename?: 'Mutation', addSource: { __typename?: 'Source', id: string } };

export type GetSourcesQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetSourcesQuery = { __typename?: 'Query', sources: Array<{ __typename?: 'Source', id: string, name: string, host: string, user: string, database: string, failed_at?: any | null, is_syncing: boolean }> };

export type RefreshSourceMutationVariables = Exact<{
  sourceId: Scalars['ID']['input'];
}>;


export type RefreshSourceMutation = { __typename?: 'Mutation', refreshSource: { __typename?: 'Source', id: string } };

export type GetDashboardQueryVariables = Exact<{
  dashboardId: Scalars['ID']['input'];
  filters: Array<QueryFilterInput> | QueryFilterInput;
}>;


export type GetDashboardQuery = { __typename?: 'Query', dashboard?: { __typename?: 'Dashboard', columns: number, rows: number, charts: Array<{ __typename?: 'Chart', id: string, x: number, y: number, width: number, height: number, datasets: Array<{ __typename?: 'DataSet', label: string, render_settings?: string | null, type: ChartType, metric: { __typename?: 'Metric', id: string, diver_query: string, source: { __typename?: 'Source', id: string } } }> }>, referencedFilters: Array<{ __typename?: 'ReferencedQueryFilter', id: string, identifier: string, queryFilter?: { __typename?: 'QueryFilter', compiledQuery?: { __typename?: 'CompiledQuery', result?: { __typename?: 'QueryResult', rows: Array<string> } | null } | null } | null }> } | null };

export type GetDatabaseAlmanacQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetDatabaseAlmanacQuery = { __typename?: 'Query', sources: Array<{ __typename?: 'Source', id: string, availableSchemas: Array<{ __typename?: 'AlmanacSchema', name: string, tables: Array<{ __typename?: 'AlmanacTable', name: string, columns: Array<{ __typename?: 'AlmanacColumn', data_type: string, name: string }> }> }> }> };

export type RunQueryMutationVariables = Exact<{
  sourceId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
  filters: Array<QueryFilterInput> | QueryFilterInput;
}>;


export type RunQueryMutation = { __typename?: 'Mutation', compileQuery: { __typename?: 'CompiledQuery', query: string, filters: Array<string>, result?: { __typename?: 'QueryResult', cache: boolean, columns: Array<string>, rows: Array<string>, count: number, runAt: any, time: number, meta: Array<{ __typename?: 'QueryResultMeta', type: string }> } | null } };

export type ValidateQueryMutationVariables = Exact<{
  sourceId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
}>;


export type ValidateQueryMutation = { __typename?: 'Mutation', compileQuery: { __typename?: 'CompiledQuery', query: string, filters: Array<string> } };

export type SaveQueryMutationVariables = Exact<{
  queryId?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  query: Scalars['String']['input'];
}>;


export type SaveQueryMutation = { __typename?: 'Mutation', saveQuery?: { __typename?: 'Metric', id: string } | null };

export type AddQueryToChartMutationVariables = Exact<{
  chartId: Scalars['ID']['input'];
  queryId: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  type: Scalars['String']['input'];
  renderSettings?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddQueryToChartMutation = { __typename?: 'Mutation', addQueryToChart: { __typename?: 'DataSet', id: string } };

export type CreateChartMutationVariables = Exact<{
  dashboardId: Scalars['ID']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
  height: Scalars['Int']['input'];
}>;


export type CreateChartMutation = { __typename?: 'Mutation', createChart: { __typename?: 'Chart', id: string } };

export type DeleteChartMutationVariables = Exact<{
  chartId: Scalars['ID']['input'];
}>;


export type DeleteChartMutation = { __typename?: 'Mutation', deleteChart: { __typename?: 'Chart', id: string } };

export type MoveChartMutationVariables = Exact<{
  chartId: Scalars['ID']['input'];
  dashboardId: Scalars['ID']['input'];
  x?: InputMaybe<Scalars['Int']['input']>;
  y?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MoveChartMutation = { __typename?: 'Mutation', moveChart: { __typename?: 'Chart', id: string } };

export type GetDashboardsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetDashboardsQuery = { __typename?: 'Query', dashboards: Array<{ __typename?: 'Dashboard', id: string, name: string, chartCount: number, columns: number, rows: number, charts: Array<{ __typename?: 'Chart', id: string, height: number, width: number, x: number, y: number }> }> };

export type CreateDashboardMutationVariables = Exact<{
  cacheTtl: Scalars['Int']['input'];
  organizationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  columns: Scalars['Int']['input'];
  rows: Scalars['Int']['input'];
}>;


export type CreateDashboardMutation = { __typename?: 'Mutation', createDashboard: { __typename?: 'Dashboard', id: string } };

export type GetQueryFiltersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  sourceId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetQueryFiltersQuery = { __typename?: 'Query', queryFilters: Array<{ __typename?: 'QueryFilter', id: string, identifier: string, filterReferenceCount: number, filterReferences: Array<{ __typename?: 'ReferencedQueryFilter', metric: { __typename?: 'Metric', diver_query: string } }>, metric: { __typename?: 'Metric', id: string, diver_query: string }, source: { __typename?: 'Source', id: string } }> };

export type DeleteQueryFilterMutationVariables = Exact<{
  queryFilterId: Scalars['ID']['input'];
}>;


export type DeleteQueryFilterMutation = { __typename?: 'Mutation', deleteQueryFilter?: { __typename?: 'QueryFilter', id: string } | null };

export type SaveQueryFilterMutationVariables = Exact<{
  queryFilterId?: InputMaybe<Scalars['ID']['input']>;
  queryId?: InputMaybe<Scalars['ID']['input']>;
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type SaveQueryFilterMutation = { __typename?: 'Mutation', saveQueryFilter: { __typename?: 'QueryFilter', id: string } };

export type LogInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LogInMutation = { __typename?: 'Mutation', authenticate: { __typename?: 'Authentication', token: string, user: { __typename?: 'User', id: string, email: string, name: string, gravatar: string } } };

export type CreateOrganizationMutationVariables = Exact<{
  organizationName: Scalars['String']['input'];
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'Organization', id: string } };

export type AddDomainMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  domainName: Scalars['String']['input'];
}>;


export type AddDomainMutation = { __typename?: 'Mutation', addDomain: { __typename?: 'Domain', id: string } };

export type GetDomainsQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetDomainsQuery = { __typename?: 'Query', domains: Array<{ __typename?: 'Domain', id: string, name: string, record_type: DomainRecordType, record_domain: string, record_value: string, verified_at?: any | null }> };

export type DeleteDomainMutationVariables = Exact<{
  domainId: Scalars['ID']['input'];
}>;


export type DeleteDomainMutation = { __typename?: 'Mutation', deleteDomain?: { __typename?: 'Domain', id: string } | null };

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteOrganization: string };

export type VerifyDomainMutationVariables = Exact<{
  domainId: Scalars['ID']['input'];
}>;


export type VerifyDomainMutation = { __typename?: 'Mutation', verifyDomain?: { __typename?: 'Domain', id: string } | null };

export type InviteUserMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  role: Role;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'UserInvitation', email: string, name: string } };

export type GetUsersQueryVariables = Exact<{
  organizationId: Scalars['ID']['input'];
}>;


export type GetUsersQuery = { __typename?: 'Query', me: { __typename?: 'User', organizations: Array<{ __typename?: 'Organization', invitations: Array<{ __typename?: 'UserInvitation', id: string, email: string, expires_at: any, gravatar: string, name: string, role: Role }>, members: Array<{ __typename?: 'Member', role: Role, user: { __typename?: 'User', id: string, email: string, gravatar: string, name: string } }> }> } };

export type ChangeOrganizationUserRoleMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  role: Role;
}>;


export type ChangeOrganizationUserRoleMutation = { __typename?: 'Mutation', changeOrganizationUserRole?: string | null };

export type DeleteOrganizationUserMutationVariables = Exact<{
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;


export type DeleteOrganizationUserMutation = { __typename?: 'Mutation', deleteOrganizationUser?: string | null };

export type UninviteUserMutationVariables = Exact<{
  invitationId: Scalars['ID']['input'];
}>;


export type UninviteUserMutation = { __typename?: 'Mutation', uninviteUser?: { __typename?: 'UserInvitation', id: string } | null };



export const GetAssistantConversationDocument = `
    query GetAssistantConversation($organizationId: ID!) {
  me {
    conversations(organizationId: $organizationId) {
      id
      messages {
        id
        author
        content
        sent_at
      }
    }
  }
}
    `;

export const useGetAssistantConversationQuery = <
      TData = GetAssistantConversationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAssistantConversationQueryVariables,
      options?: Omit<UseQueryOptions<GetAssistantConversationQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetAssistantConversationQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAssistantConversationQuery, TError, TData>(
      {
    queryKey: ['GetAssistantConversation', variables],
    queryFn: fetcher<GetAssistantConversationQuery, GetAssistantConversationQueryVariables>(client, GetAssistantConversationDocument, variables, headers),
    ...options
  }
    )};

export const CreateConversationDocument = `
    mutation CreateConversation($organizationId: ID!, $userId: ID!) {
  createConversation(organization_id: $organizationId, user_id: $userId) {
    id
  }
}
    `;

export const useCreateConversationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateConversationMutation, TError, CreateConversationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateConversationMutation, TError, CreateConversationMutationVariables, TContext>(
      {
    mutationKey: ['CreateConversation'],
    mutationFn: (variables?: CreateConversationMutationVariables) => fetcher<CreateConversationMutation, CreateConversationMutationVariables>(client, CreateConversationDocument, variables, headers)(),
    ...options
  }
    )};

export const SendAssistantConversationMessageDocument = `
    mutation sendAssistantConversationMessage($conversationId: ID!, $content: String!) {
  sendConversationMessage(conversation_id: $conversationId, content: $content) {
    id
    author
    content
  }
}
    `;

export const useSendAssistantConversationMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SendAssistantConversationMessageMutation, TError, SendAssistantConversationMessageMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SendAssistantConversationMessageMutation, TError, SendAssistantConversationMessageMutationVariables, TContext>(
      {
    mutationKey: ['sendAssistantConversationMessage'],
    mutationFn: (variables?: SendAssistantConversationMessageMutationVariables) => fetcher<SendAssistantConversationMessageMutation, SendAssistantConversationMessageMutationVariables>(client, SendAssistantConversationMessageDocument, variables, headers)(),
    ...options
  }
    )};

export const OnConversationMessageUpdatedDocument = `
    subscription onConversationMessageUpdated($conversationId: ID!) {
  conversationMessageUpdated(conversationId: $conversationId) {
    id
  }
}
    `;
export const GetChartDocument = `
    query GetChart($chartId: ID!, $filters: [QueryFilterInput!]!) {
  chart(id: $chartId) {
    title
    type
    datasets {
      compiledQuery(filters: $filters) {
        filters
        result {
          columns
          rows
        }
      }
      type
      label
      render_settings
    }
  }
}
    `;

export const useGetChartQuery = <
      TData = GetChartQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetChartQueryVariables,
      options?: Omit<UseQueryOptions<GetChartQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetChartQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetChartQuery, TError, TData>(
      {
    queryKey: ['GetChart', variables],
    queryFn: fetcher<GetChartQuery, GetChartQueryVariables>(client, GetChartDocument, variables, headers),
    ...options
  }
    )};

export const GetMeDocument = `
    query GetMe {
  me {
    id
    email
    name
    gravatar
    organizations {
      id
      name
    }
  }
}
    `;

export const useGetMeQuery = <
      TData = GetMeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetMeQueryVariables,
      options?: Omit<UseQueryOptions<GetMeQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetMeQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetMeQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetMe'] : ['GetMe', variables],
    queryFn: fetcher<GetMeQuery, GetMeQueryVariables>(client, GetMeDocument, variables, headers),
    ...options
  }
    )};

export const PingDocument = `
    mutation Ping {
  ping
}
    `;

export const usePingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PingMutation, TError, PingMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<PingMutation, TError, PingMutationVariables, TContext>(
      {
    mutationKey: ['Ping'],
    mutationFn: (variables?: PingMutationVariables) => fetcher<PingMutation, PingMutationVariables>(client, PingDocument, variables, headers)(),
    ...options
  }
    )};

export const GetAlertsDocument = `
    query GetAlerts($organizationId: ID!) {
  alerts(organization_id: $organizationId) {
    id
    name
    cron
    next_run_at
    state
    metric {
      id
      diver_query
      source {
        id
      }
    }
  }
}
    `;

export const useGetAlertsQuery = <
      TData = GetAlertsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetAlertsQueryVariables,
      options?: Omit<UseQueryOptions<GetAlertsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetAlertsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAlertsQuery, TError, TData>(
      {
    queryKey: ['GetAlerts', variables],
    queryFn: fetcher<GetAlertsQuery, GetAlertsQueryVariables>(client, GetAlertsDocument, variables, headers),
    ...options
  }
    )};

export const DeleteAlertDocument = `
    mutation DeleteAlert($alertId: ID!) {
  deleteAlert(alert_id: $alertId) {
    id
  }
}
    `;

export const useDeleteAlertMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteAlertMutation, TError, DeleteAlertMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteAlertMutation, TError, DeleteAlertMutationVariables, TContext>(
      {
    mutationKey: ['DeleteAlert'],
    mutationFn: (variables?: DeleteAlertMutationVariables) => fetcher<DeleteAlertMutation, DeleteAlertMutationVariables>(client, DeleteAlertDocument, variables, headers)(),
    ...options
  }
    )};

export const SaveAlertDocument = `
    mutation SaveAlert($alertId: ID, $organizationId: ID, $queryId: ID, $sourceId: ID, $cron: String, $name: String) {
  saveAlert(
    alert_id: $alertId
    organization_id: $organizationId
    query_id: $queryId
    source_id: $sourceId
    cron: $cron
    name: $name
  ) {
    id
  }
}
    `;

export const useSaveAlertMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SaveAlertMutation, TError, SaveAlertMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SaveAlertMutation, TError, SaveAlertMutationVariables, TContext>(
      {
    mutationKey: ['SaveAlert'],
    mutationFn: (variables?: SaveAlertMutationVariables) => fetcher<SaveAlertMutation, SaveAlertMutationVariables>(client, SaveAlertDocument, variables, headers)(),
    ...options
  }
    )};

export const SuspendAlertDocument = `
    mutation SuspendAlert($alertId: ID!) {
  suspendAlert(alert_id: $alertId) {
    id
  }
}
    `;

export const useSuspendAlertMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SuspendAlertMutation, TError, SuspendAlertMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SuspendAlertMutation, TError, SuspendAlertMutationVariables, TContext>(
      {
    mutationKey: ['SuspendAlert'],
    mutationFn: (variables?: SuspendAlertMutationVariables) => fetcher<SuspendAlertMutation, SuspendAlertMutationVariables>(client, SuspendAlertDocument, variables, headers)(),
    ...options
  }
    )};

export const TriggerAlertDocument = `
    mutation TriggerAlert($alertId: ID!) {
  triggerAlert(alert_id: $alertId) {
    id
  }
}
    `;

export const useTriggerAlertMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TriggerAlertMutation, TError, TriggerAlertMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<TriggerAlertMutation, TError, TriggerAlertMutationVariables, TContext>(
      {
    mutationKey: ['TriggerAlert'],
    mutationFn: (variables?: TriggerAlertMutationVariables) => fetcher<TriggerAlertMutation, TriggerAlertMutationVariables>(client, TriggerAlertDocument, variables, headers)(),
    ...options
  }
    )};

export const UnsuspendAlertDocument = `
    mutation UnsuspendAlert($alertId: ID!) {
  unsuspendAlert(alert_id: $alertId) {
    id
  }
}
    `;

export const useUnsuspendAlertMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnsuspendAlertMutation, TError, UnsuspendAlertMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UnsuspendAlertMutation, TError, UnsuspendAlertMutationVariables, TContext>(
      {
    mutationKey: ['UnsuspendAlert'],
    mutationFn: (variables?: UnsuspendAlertMutationVariables) => fetcher<UnsuspendAlertMutation, UnsuspendAlertMutationVariables>(client, UnsuspendAlertDocument, variables, headers)(),
    ...options
  }
    )};

export const GetCliSecretDocument = `
    query GetCliSecret($secret: String!) {
  cliSecret(secret: $secret) {
    name
    operating_system
  }
}
    `;

export const useGetCliSecretQuery = <
      TData = GetCliSecretQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCliSecretQueryVariables,
      options?: Omit<UseQueryOptions<GetCliSecretQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCliSecretQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCliSecretQuery, TError, TData>(
      {
    queryKey: ['GetCliSecret', variables],
    queryFn: fetcher<GetCliSecretQuery, GetCliSecretQueryVariables>(client, GetCliSecretDocument, variables, headers),
    ...options
  }
    )};

export const AuthorizeCliDocument = `
    mutation AuthorizeCli($organizationId: ID!, $name: String!, $secret: String!) {
  authorizeCli(organization_id: $organizationId, name: $name, secret: $secret) {
    api_key_preview
  }
}
    `;

export const useAuthorizeCliMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AuthorizeCliMutation, TError, AuthorizeCliMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AuthorizeCliMutation, TError, AuthorizeCliMutationVariables, TContext>(
      {
    mutationKey: ['AuthorizeCli'],
    mutationFn: (variables?: AuthorizeCliMutationVariables) => fetcher<AuthorizeCliMutation, AuthorizeCliMutationVariables>(client, AuthorizeCliDocument, variables, headers)(),
    ...options
  }
    )};

export const AuthorizeGithubDocument = `
    mutation AuthorizeGithub($installationId: String!, $organizationId: ID!, $setupAction: String!) {
  authorizeGithub(
    installation_id: $installationId
    organization_id: $organizationId
    setup_action: $setupAction
  ) {
    __typename
  }
}
    `;

export const useAuthorizeGithubMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AuthorizeGithubMutation, TError, AuthorizeGithubMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AuthorizeGithubMutation, TError, AuthorizeGithubMutationVariables, TContext>(
      {
    mutationKey: ['AuthorizeGithub'],
    mutationFn: (variables?: AuthorizeGithubMutationVariables) => fetcher<AuthorizeGithubMutation, AuthorizeGithubMutationVariables>(client, AuthorizeGithubDocument, variables, headers)(),
    ...options
  }
    )};

export const GetStripeCustomerDocument = `
    query GetStripeCustomer($organizationId: ID!) {
  me {
    organizations(id: $organizationId) {
      stripe_id
      pm_type
      pm_last_four
      trial_ends_at
    }
  }
}
    `;

export const useGetStripeCustomerQuery = <
      TData = GetStripeCustomerQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetStripeCustomerQueryVariables,
      options?: Omit<UseQueryOptions<GetStripeCustomerQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetStripeCustomerQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetStripeCustomerQuery, TError, TData>(
      {
    queryKey: ['GetStripeCustomer', variables],
    queryFn: fetcher<GetStripeCustomerQuery, GetStripeCustomerQueryVariables>(client, GetStripeCustomerDocument, variables, headers),
    ...options
  }
    )};

export const CreateStripeSetupIntentDocument = `
    mutation CreateStripeSetupIntent($organizationId: ID!) {
  createStripeSetupIntent(organization_id: $organizationId) {
    id
    client_secret
  }
}
    `;

export const useCreateStripeSetupIntentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateStripeSetupIntentMutation, TError, CreateStripeSetupIntentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateStripeSetupIntentMutation, TError, CreateStripeSetupIntentMutationVariables, TContext>(
      {
    mutationKey: ['CreateStripeSetupIntent'],
    mutationFn: (variables?: CreateStripeSetupIntentMutationVariables) => fetcher<CreateStripeSetupIntentMutation, CreateStripeSetupIntentMutationVariables>(client, CreateStripeSetupIntentDocument, variables, headers)(),
    ...options
  }
    )};

export const SyncStripeCustomerDocument = `
    mutation SyncStripeCustomer($organizationId: ID!, $setupIntentId: String) {
  syncStripeCustomer(
    organization_id: $organizationId
    setup_intent_id: $setupIntentId
  ) {
    stripe_id
    pm_type
    pm_last_four
    trial_ends_at
  }
}
    `;

export const useSyncStripeCustomerMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SyncStripeCustomerMutation, TError, SyncStripeCustomerMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SyncStripeCustomerMutation, TError, SyncStripeCustomerMutationVariables, TContext>(
      {
    mutationKey: ['SyncStripeCustomer'],
    mutationFn: (variables?: SyncStripeCustomerMutationVariables) => fetcher<SyncStripeCustomerMutation, SyncStripeCustomerMutationVariables>(client, SyncStripeCustomerDocument, variables, headers)(),
    ...options
  }
    )};

export const GetUpcomingInvoiceDocument = `
    query GetUpcomingInvoice($organizationId: ID!) {
  me {
    organizations(id: $organizationId) {
      upcoming_invoice {
        date
        raw_total
        total
      }
    }
  }
}
    `;

export const useGetUpcomingInvoiceQuery = <
      TData = GetUpcomingInvoiceQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUpcomingInvoiceQueryVariables,
      options?: Omit<UseQueryOptions<GetUpcomingInvoiceQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUpcomingInvoiceQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUpcomingInvoiceQuery, TError, TData>(
      {
    queryKey: ['GetUpcomingInvoice', variables],
    queryFn: fetcher<GetUpcomingInvoiceQuery, GetUpcomingInvoiceQueryVariables>(client, GetUpcomingInvoiceDocument, variables, headers),
    ...options
  }
    )};

export const GetCliTokensDocument = `
    query GetCliTokens($organizationId: ID!) {
  me {
    cliTokens(organizationId: $organizationId) {
      id
      api_key_preview
      last_used_at
      name
      operating_system
    }
  }
}
    `;

export const useGetCliTokensQuery = <
      TData = GetCliTokensQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCliTokensQueryVariables,
      options?: Omit<UseQueryOptions<GetCliTokensQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCliTokensQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCliTokensQuery, TError, TData>(
      {
    queryKey: ['GetCliTokens', variables],
    queryFn: fetcher<GetCliTokensQuery, GetCliTokensQueryVariables>(client, GetCliTokensDocument, variables, headers),
    ...options
  }
    )};

export const DeleteCliTokenDocument = `
    mutation DeleteCliToken($cliTokenId: ID!) {
  deleteCliToken(cli_token_id: $cliTokenId) {
    id
  }
}
    `;

export const useDeleteCliTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteCliTokenMutation, TError, DeleteCliTokenMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteCliTokenMutation, TError, DeleteCliTokenMutationVariables, TContext>(
      {
    mutationKey: ['DeleteCliToken'],
    mutationFn: (variables?: DeleteCliTokenMutationVariables) => fetcher<DeleteCliTokenMutation, DeleteCliTokenMutationVariables>(client, DeleteCliTokenDocument, variables, headers)(),
    ...options
  }
    )};

export const AddSourceDocument = `
    mutation AddSource($organizationId: ID!, $name: String!, $driver: SourceDriver!, $host: String!, $port: Int!, $database: String!, $user: String!, $password: String!) {
  addSource(
    organization_id: $organizationId
    name: $name
    driver: $driver
    host: $host
    port: $port
    database: $database
    user: $user
    password: $password
  ) {
    id
  }
}
    `;

export const useAddSourceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddSourceMutation, TError, AddSourceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddSourceMutation, TError, AddSourceMutationVariables, TContext>(
      {
    mutationKey: ['AddSource'],
    mutationFn: (variables?: AddSourceMutationVariables) => fetcher<AddSourceMutation, AddSourceMutationVariables>(client, AddSourceDocument, variables, headers)(),
    ...options
  }
    )};

export const GetSourcesDocument = `
    query GetSources($organizationId: ID!) {
  sources(organization_id: $organizationId) {
    id
    name
    host
    user
    database
    failed_at
    is_syncing
  }
}
    `;

export const useGetSourcesQuery = <
      TData = GetSourcesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetSourcesQueryVariables,
      options?: Omit<UseQueryOptions<GetSourcesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetSourcesQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetSourcesQuery, TError, TData>(
      {
    queryKey: ['GetSources', variables],
    queryFn: fetcher<GetSourcesQuery, GetSourcesQueryVariables>(client, GetSourcesDocument, variables, headers),
    ...options
  }
    )};

export const RefreshSourceDocument = `
    mutation RefreshSource($sourceId: ID!) {
  refreshSource(source_id: $sourceId) {
    id
  }
}
    `;

export const useRefreshSourceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RefreshSourceMutation, TError, RefreshSourceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<RefreshSourceMutation, TError, RefreshSourceMutationVariables, TContext>(
      {
    mutationKey: ['RefreshSource'],
    mutationFn: (variables?: RefreshSourceMutationVariables) => fetcher<RefreshSourceMutation, RefreshSourceMutationVariables>(client, RefreshSourceDocument, variables, headers)(),
    ...options
  }
    )};

export const GetDashboardDocument = `
    query GetDashboard($dashboardId: ID!, $filters: [QueryFilterInput!]!) {
  dashboard(id: $dashboardId) {
    columns
    rows
    charts {
      id
      x
      y
      width
      height
      datasets {
        label
        metric {
          id
          diver_query
          source {
            id
          }
        }
        render_settings
        type
      }
    }
    referencedFilters {
      id
      identifier
      queryFilter {
        compiledQuery(filters: $filters) {
          result {
            rows
          }
        }
      }
    }
  }
}
    `;

export const useGetDashboardQuery = <
      TData = GetDashboardQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetDashboardQueryVariables,
      options?: Omit<UseQueryOptions<GetDashboardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetDashboardQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetDashboardQuery, TError, TData>(
      {
    queryKey: ['GetDashboard', variables],
    queryFn: fetcher<GetDashboardQuery, GetDashboardQueryVariables>(client, GetDashboardDocument, variables, headers),
    ...options
  }
    )};

export const GetDatabaseAlmanacDocument = `
    query GetDatabaseAlmanac($organizationId: ID!) {
  sources(organization_id: $organizationId) {
    availableSchemas {
      name
      tables {
        columns {
          data_type
          name
        }
        name
      }
    }
    id
  }
}
    `;

export const useGetDatabaseAlmanacQuery = <
      TData = GetDatabaseAlmanacQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetDatabaseAlmanacQueryVariables,
      options?: Omit<UseQueryOptions<GetDatabaseAlmanacQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetDatabaseAlmanacQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetDatabaseAlmanacQuery, TError, TData>(
      {
    queryKey: ['GetDatabaseAlmanac', variables],
    queryFn: fetcher<GetDatabaseAlmanacQuery, GetDatabaseAlmanacQueryVariables>(client, GetDatabaseAlmanacDocument, variables, headers),
    ...options
  }
    )};

export const RunQueryDocument = `
    mutation RunQuery($sourceId: ID!, $query: String!, $filters: [QueryFilterInput!]!) {
  compileQuery(source_id: $sourceId, query: $query, filters: $filters) {
    result {
      cache
      columns
      rows
      count
      runAt
      time
      meta {
        type
      }
    }
    query
    filters
  }
}
    `;

export const useRunQueryMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RunQueryMutation, TError, RunQueryMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<RunQueryMutation, TError, RunQueryMutationVariables, TContext>(
      {
    mutationKey: ['RunQuery'],
    mutationFn: (variables?: RunQueryMutationVariables) => fetcher<RunQueryMutation, RunQueryMutationVariables>(client, RunQueryDocument, variables, headers)(),
    ...options
  }
    )};

export const ValidateQueryDocument = `
    mutation ValidateQuery($sourceId: ID!, $query: String!) {
  compileQuery(source_id: $sourceId, query: $query, filters: []) {
    query
    filters
  }
}
    `;

export const useValidateQueryMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ValidateQueryMutation, TError, ValidateQueryMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<ValidateQueryMutation, TError, ValidateQueryMutationVariables, TContext>(
      {
    mutationKey: ['ValidateQuery'],
    mutationFn: (variables?: ValidateQueryMutationVariables) => fetcher<ValidateQueryMutation, ValidateQueryMutationVariables>(client, ValidateQueryDocument, variables, headers)(),
    ...options
  }
    )};

export const SaveQueryDocument = `
    mutation SaveQuery($queryId: ID, $sourceId: ID, $query: String!) {
  saveQuery(query_id: $queryId, source_id: $sourceId, diver_query: $query) {
    id
  }
}
    `;

export const useSaveQueryMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SaveQueryMutation, TError, SaveQueryMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SaveQueryMutation, TError, SaveQueryMutationVariables, TContext>(
      {
    mutationKey: ['SaveQuery'],
    mutationFn: (variables?: SaveQueryMutationVariables) => fetcher<SaveQueryMutation, SaveQueryMutationVariables>(client, SaveQueryDocument, variables, headers)(),
    ...options
  }
    )};

export const AddQueryToChartDocument = `
    mutation AddQueryToChart($chartId: ID!, $queryId: ID!, $label: String!, $type: String!, $renderSettings: String) {
  addQueryToChart(
    chart_id: $chartId
    query_id: $queryId
    label: $label
    type: $type
    render_settings: $renderSettings
  ) {
    id
  }
}
    `;

export const useAddQueryToChartMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddQueryToChartMutation, TError, AddQueryToChartMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddQueryToChartMutation, TError, AddQueryToChartMutationVariables, TContext>(
      {
    mutationKey: ['AddQueryToChart'],
    mutationFn: (variables?: AddQueryToChartMutationVariables) => fetcher<AddQueryToChartMutation, AddQueryToChartMutationVariables>(client, AddQueryToChartDocument, variables, headers)(),
    ...options
  }
    )};

export const CreateChartDocument = `
    mutation CreateChart($dashboardId: ID!, $x: Int!, $y: Int!, $width: Int!, $height: Int!) {
  createChart(
    dashboard_id: $dashboardId
    x: $x
    y: $y
    width: $width
    height: $height
  ) {
    id
  }
}
    `;

export const useCreateChartMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateChartMutation, TError, CreateChartMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateChartMutation, TError, CreateChartMutationVariables, TContext>(
      {
    mutationKey: ['CreateChart'],
    mutationFn: (variables?: CreateChartMutationVariables) => fetcher<CreateChartMutation, CreateChartMutationVariables>(client, CreateChartDocument, variables, headers)(),
    ...options
  }
    )};

export const DeleteChartDocument = `
    mutation DeleteChart($chartId: ID!) {
  deleteChart(chart_id: $chartId) {
    id
  }
}
    `;

export const useDeleteChartMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteChartMutation, TError, DeleteChartMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteChartMutation, TError, DeleteChartMutationVariables, TContext>(
      {
    mutationKey: ['DeleteChart'],
    mutationFn: (variables?: DeleteChartMutationVariables) => fetcher<DeleteChartMutation, DeleteChartMutationVariables>(client, DeleteChartDocument, variables, headers)(),
    ...options
  }
    )};

export const MoveChartDocument = `
    mutation MoveChart($chartId: ID!, $dashboardId: ID!, $x: Int, $y: Int) {
  moveChart(chart_id: $chartId, dashboard_id: $dashboardId, x: $x, y: $y) {
    id
  }
}
    `;

export const useMoveChartMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<MoveChartMutation, TError, MoveChartMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<MoveChartMutation, TError, MoveChartMutationVariables, TContext>(
      {
    mutationKey: ['MoveChart'],
    mutationFn: (variables?: MoveChartMutationVariables) => fetcher<MoveChartMutation, MoveChartMutationVariables>(client, MoveChartDocument, variables, headers)(),
    ...options
  }
    )};

export const GetDashboardsDocument = `
    query GetDashboards($organizationId: ID!) {
  dashboards(organization_id: $organizationId) {
    id
    name
    chartCount
    charts {
      id
      height
      width
      x
      y
    }
    columns
    rows
  }
}
    `;

export const useGetDashboardsQuery = <
      TData = GetDashboardsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetDashboardsQueryVariables,
      options?: Omit<UseQueryOptions<GetDashboardsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetDashboardsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetDashboardsQuery, TError, TData>(
      {
    queryKey: ['GetDashboards', variables],
    queryFn: fetcher<GetDashboardsQuery, GetDashboardsQueryVariables>(client, GetDashboardsDocument, variables, headers),
    ...options
  }
    )};

export const CreateDashboardDocument = `
    mutation CreateDashboard($cacheTtl: Int!, $organizationId: ID!, $name: String!, $columns: Int!, $rows: Int!) {
  createDashboard(
    cache_ttl: $cacheTtl
    organization_id: $organizationId
    name: $name
    columns: $columns
    rows: $rows
  ) {
    id
  }
}
    `;

export const useCreateDashboardMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateDashboardMutation, TError, CreateDashboardMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateDashboardMutation, TError, CreateDashboardMutationVariables, TContext>(
      {
    mutationKey: ['CreateDashboard'],
    mutationFn: (variables?: CreateDashboardMutationVariables) => fetcher<CreateDashboardMutation, CreateDashboardMutationVariables>(client, CreateDashboardDocument, variables, headers)(),
    ...options
  }
    )};

export const GetQueryFiltersDocument = `
    query GetQueryFilters($organizationId: ID!, $sourceId: ID) {
  queryFilters(organization_id: $organizationId, source_id: $sourceId) {
    id
    identifier
    filterReferences {
      metric {
        diver_query
      }
    }
    filterReferenceCount
    metric {
      id
      diver_query
    }
    source {
      id
    }
  }
}
    `;

export const useGetQueryFiltersQuery = <
      TData = GetQueryFiltersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetQueryFiltersQueryVariables,
      options?: Omit<UseQueryOptions<GetQueryFiltersQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetQueryFiltersQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetQueryFiltersQuery, TError, TData>(
      {
    queryKey: ['GetQueryFilters', variables],
    queryFn: fetcher<GetQueryFiltersQuery, GetQueryFiltersQueryVariables>(client, GetQueryFiltersDocument, variables, headers),
    ...options
  }
    )};

export const DeleteQueryFilterDocument = `
    mutation DeleteQueryFilter($queryFilterId: ID!) {
  deleteQueryFilter(query_filter_id: $queryFilterId) {
    id
  }
}
    `;

export const useDeleteQueryFilterMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteQueryFilterMutation, TError, DeleteQueryFilterMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteQueryFilterMutation, TError, DeleteQueryFilterMutationVariables, TContext>(
      {
    mutationKey: ['DeleteQueryFilter'],
    mutationFn: (variables?: DeleteQueryFilterMutationVariables) => fetcher<DeleteQueryFilterMutation, DeleteQueryFilterMutationVariables>(client, DeleteQueryFilterDocument, variables, headers)(),
    ...options
  }
    )};

export const SaveQueryFilterDocument = `
    mutation SaveQueryFilter($queryFilterId: ID, $queryId: ID, $sourceId: ID, $identifier: String, $name: String) {
  saveQueryFilter(
    query_filter_id: $queryFilterId
    query_id: $queryId
    source_id: $sourceId
    identifier: $identifier
    name: $name
  ) {
    id
  }
}
    `;

export const useSaveQueryFilterMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SaveQueryFilterMutation, TError, SaveQueryFilterMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SaveQueryFilterMutation, TError, SaveQueryFilterMutationVariables, TContext>(
      {
    mutationKey: ['SaveQueryFilter'],
    mutationFn: (variables?: SaveQueryFilterMutationVariables) => fetcher<SaveQueryFilterMutation, SaveQueryFilterMutationVariables>(client, SaveQueryFilterDocument, variables, headers)(),
    ...options
  }
    )};

export const LogInDocument = `
    mutation LogIn($email: String!, $password: String!) {
  authenticate(email: $email, password: $password) {
    token
    user {
      id
      email
      name
      gravatar
    }
  }
}
    `;

export const useLogInMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LogInMutation, TError, LogInMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<LogInMutation, TError, LogInMutationVariables, TContext>(
      {
    mutationKey: ['LogIn'],
    mutationFn: (variables?: LogInMutationVariables) => fetcher<LogInMutation, LogInMutationVariables>(client, LogInDocument, variables, headers)(),
    ...options
  }
    )};

export const CreateOrganizationDocument = `
    mutation CreateOrganization($organizationName: String!) {
  createOrganization(organization_name: $organizationName) {
    id
  }
}
    `;

export const useCreateOrganizationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>(
      {
    mutationKey: ['CreateOrganization'],
    mutationFn: (variables?: CreateOrganizationMutationVariables) => fetcher<CreateOrganizationMutation, CreateOrganizationMutationVariables>(client, CreateOrganizationDocument, variables, headers)(),
    ...options
  }
    )};

export const AddDomainDocument = `
    mutation AddDomain($organizationId: ID!, $domainName: String!) {
  addDomain(organization_id: $organizationId, domain_name: $domainName) {
    id
  }
}
    `;

export const useAddDomainMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddDomainMutation, TError, AddDomainMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddDomainMutation, TError, AddDomainMutationVariables, TContext>(
      {
    mutationKey: ['AddDomain'],
    mutationFn: (variables?: AddDomainMutationVariables) => fetcher<AddDomainMutation, AddDomainMutationVariables>(client, AddDomainDocument, variables, headers)(),
    ...options
  }
    )};

export const GetDomainsDocument = `
    query GetDomains($organizationId: ID!) {
  domains(organization_id: $organizationId) {
    id
    name
    record_type
    record_domain
    record_value
    verified_at
  }
}
    `;

export const useGetDomainsQuery = <
      TData = GetDomainsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetDomainsQueryVariables,
      options?: Omit<UseQueryOptions<GetDomainsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetDomainsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetDomainsQuery, TError, TData>(
      {
    queryKey: ['GetDomains', variables],
    queryFn: fetcher<GetDomainsQuery, GetDomainsQueryVariables>(client, GetDomainsDocument, variables, headers),
    ...options
  }
    )};

export const DeleteDomainDocument = `
    mutation DeleteDomain($domainId: ID!) {
  deleteDomain(domain_id: $domainId) {
    id
  }
}
    `;

export const useDeleteDomainMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteDomainMutation, TError, DeleteDomainMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteDomainMutation, TError, DeleteDomainMutationVariables, TContext>(
      {
    mutationKey: ['DeleteDomain'],
    mutationFn: (variables?: DeleteDomainMutationVariables) => fetcher<DeleteDomainMutation, DeleteDomainMutationVariables>(client, DeleteDomainDocument, variables, headers)(),
    ...options
  }
    )};

export const DeleteOrganizationDocument = `
    mutation DeleteOrganization($organizationId: ID!) {
  deleteOrganization(organization_id: $organizationId)
}
    `;

export const useDeleteOrganizationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteOrganizationMutation, TError, DeleteOrganizationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteOrganizationMutation, TError, DeleteOrganizationMutationVariables, TContext>(
      {
    mutationKey: ['DeleteOrganization'],
    mutationFn: (variables?: DeleteOrganizationMutationVariables) => fetcher<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(client, DeleteOrganizationDocument, variables, headers)(),
    ...options
  }
    )};

export const VerifyDomainDocument = `
    mutation VerifyDomain($domainId: ID!) {
  verifyDomain(domain_id: $domainId) {
    id
  }
}
    `;

export const useVerifyDomainMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VerifyDomainMutation, TError, VerifyDomainMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<VerifyDomainMutation, TError, VerifyDomainMutationVariables, TContext>(
      {
    mutationKey: ['VerifyDomain'],
    mutationFn: (variables?: VerifyDomainMutationVariables) => fetcher<VerifyDomainMutation, VerifyDomainMutationVariables>(client, VerifyDomainDocument, variables, headers)(),
    ...options
  }
    )};

export const InviteUserDocument = `
    mutation InviteUser($organizationId: ID!, $email: String!, $name: String!, $role: Role!) {
  inviteUser(
    organization_id: $organizationId
    email: $email
    name: $name
    role: $role
  ) {
    email
    name
  }
}
    `;

export const useInviteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<InviteUserMutation, TError, InviteUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<InviteUserMutation, TError, InviteUserMutationVariables, TContext>(
      {
    mutationKey: ['InviteUser'],
    mutationFn: (variables?: InviteUserMutationVariables) => fetcher<InviteUserMutation, InviteUserMutationVariables>(client, InviteUserDocument, variables, headers)(),
    ...options
  }
    )};

export const GetUsersDocument = `
    query GetUsers($organizationId: ID!) {
  me {
    organizations(id: $organizationId) {
      invitations {
        id
        email
        expires_at
        gravatar
        name
        role
      }
      members {
        user {
          id
          email
          gravatar
          name
        }
        role
      }
    }
  }
}
    `;

export const useGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUsersQueryVariables,
      options?: Omit<UseQueryOptions<GetUsersQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUsersQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUsersQuery, TError, TData>(
      {
    queryKey: ['GetUsers', variables],
    queryFn: fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, variables, headers),
    ...options
  }
    )};

export const ChangeOrganizationUserRoleDocument = `
    mutation ChangeOrganizationUserRole($organizationId: ID!, $userId: ID!, $role: Role!) {
  changeOrganizationUserRole(
    organization_id: $organizationId
    user_id: $userId
    role: $role
  )
}
    `;

export const useChangeOrganizationUserRoleMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ChangeOrganizationUserRoleMutation, TError, ChangeOrganizationUserRoleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<ChangeOrganizationUserRoleMutation, TError, ChangeOrganizationUserRoleMutationVariables, TContext>(
      {
    mutationKey: ['ChangeOrganizationUserRole'],
    mutationFn: (variables?: ChangeOrganizationUserRoleMutationVariables) => fetcher<ChangeOrganizationUserRoleMutation, ChangeOrganizationUserRoleMutationVariables>(client, ChangeOrganizationUserRoleDocument, variables, headers)(),
    ...options
  }
    )};

export const DeleteOrganizationUserDocument = `
    mutation DeleteOrganizationUser($organizationId: ID!, $userId: ID!) {
  deleteOrganizationUser(organization_id: $organizationId, user_id: $userId)
}
    `;

export const useDeleteOrganizationUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteOrganizationUserMutation, TError, DeleteOrganizationUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteOrganizationUserMutation, TError, DeleteOrganizationUserMutationVariables, TContext>(
      {
    mutationKey: ['DeleteOrganizationUser'],
    mutationFn: (variables?: DeleteOrganizationUserMutationVariables) => fetcher<DeleteOrganizationUserMutation, DeleteOrganizationUserMutationVariables>(client, DeleteOrganizationUserDocument, variables, headers)(),
    ...options
  }
    )};

export const UninviteUserDocument = `
    mutation UninviteUser($invitationId: ID!) {
  uninviteUser(invitation_id: $invitationId) {
    id
  }
}
    `;

export const useUninviteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UninviteUserMutation, TError, UninviteUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UninviteUserMutation, TError, UninviteUserMutationVariables, TContext>(
      {
    mutationKey: ['UninviteUser'],
    mutationFn: (variables?: UninviteUserMutationVariables) => fetcher<UninviteUserMutation, UninviteUserMutationVariables>(client, UninviteUserDocument, variables, headers)(),
    ...options
  }
    )};
