import { Dashboard, DashboardItem, Spinner } from '@withdiver/components/src/Dashboard'
import { Button } from '@withdiver/components/src/Button'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import {
	Loader,
	Plus,
} from 'react-feather'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { useGetDashboardsQuery } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import Sidebar from '../Sidebar'
import { DashboardCreationModal } from './DashboardCreationModal'

function DashboardListPage() {
	const { show } = useModal()
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const { data, isLoading } = useGetDashboardsQuery(graphQLClient, { organizationId })
	const theme = useTheme() as Theme

	const onNewDashboardClick = useCallback(() => {
		return show(modalProps => (
			<DashboardCreationModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				{...modalProps}
			/>
		))
	}, [ graphQLClient, organizationId, show ])

	return (
		<View>
			<Sidebar/>
			<View px={[0, 40]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Dashboards
						<Button
							variant="primary"
							size="icon"
							alignSelf="center"
							width="fit-content"
							onClick={onNewDashboardClick()}
							ml={10}
						>
							<View as={Plus} size={20}/>
						</Button>
					</Text>
				</View>
				{isLoading &&
				<Spinner as={Text} color="text" justifyContent="center" alignItems="center" height="100%">
					<View as={Loader} size={88}/>
				</Spinner>
				}
				<View
					display="grid"
					gap="20px"
					gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
				>
					{data?.dashboards.map(dashboard => (
						<View
							backgroundColor={theme.colors.cardInputBackground}
							borderRadius={20}
							color="text"
							flexDirection="column"
							height="fit-content"
							margin="auto"
							width="fit-content"
							key={dashboard.id}
							as={Link}
							to={dashboard.id}
							p={10}
							mb={10}
						>
							{dashboard.name} ({dashboard.chartCount} charts)
							<View mt={2}/>
							<Dashboard
								columns={dashboard.columns}
								rows={dashboard.rows}
								height={200}
								width={300}
								pointerEvents="none"
								backgroundColor={theme.dashboard.backgroundColor}
								gap={5}
								p={10}
							>
								{dashboard.charts.map(chart => {
									return (
										<DashboardItem
											key={chart.id}
											padding={0}
											height={chart.height}
											width={chart.width}
											x={chart.x}
											y={chart.y}
										/>
									)
								})}
							</Dashboard>
						</View>
					))}
				</View>
			</View>
		</View>
	)
}

export default DashboardListPage
