import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import MultiSelect from '@withdiver/components/src/inputs/MultiSelect'
import { ListView } from '@withdiver/components/src/ListView'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback, useState } from 'react'
import { GetDomainsQuery, useGetDomainsQuery } from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import { SamlSsoCreationModal } from './SamlSsoCreationModal'

function Sso() {
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const { data: domainData } = useGetDomainsQuery(graphQLClient, { organizationId })
	const [ domains, setDomains ] = useState<GetDomainsQuery['domains']>([])
	const { show } = useModal()

	const onAddSamlClick = useCallback(() => {
		return show(modalProps => (
			<SamlSsoCreationModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				{...modalProps}
			/>
		))()
	}, [ graphQLClient, organizationId, show ])

	return (
		<ListView width="100%" maxWidth={1100} gap={20}>
			<Card p={2} flexDirection="column">
				<Text color="text" fontSize={20}>Email Single Sign-On</Text>
				<Text color="text">Allow anyone within your email domain to sign up and automatically join your workplace.</Text>
				<View mt={2} gap={20}>
					<MultiSelect
						items={domainData?.domains ?? []}
						itemToLabel={domain => domain.name}
						selected={domains}
						placeholder="domains"
						onChange={setDomains}
					/>
					<Button size="medium" variant="primary">Save</Button>
				</View>
			</Card>
			<Card p={2}>
				<View flexDirection="column" flexGrow={1}>
					<Text color="text" fontSize={20}>SAML Single Sign-On</Text>
					<Text color="text">Allow anyone with access to your identity provider to automatically join your workplace.</Text>
				</View>
				<View>
					<Button
						onClick={onAddSamlClick}
						variant="primary"
					>
						Add SAML
					</Button>
				</View>
			</Card>
			<Card p={2} flexDirection="column">
				<Text color="text" fontSize={20}>SCIM 2.0</Text>
				<Text color="text">Enable programmatic access for managing your team.</Text>
				<Card p={2} mt={3} flexDirection="column" border="1px solid" borderColor="success">
					<Text
						color="text"
						alignSelf="center"
					>
						This feature is unlocked by the <TextLink color="success" to={`/workplace/${organizationId}/billing/subscription`}>Olympic</TextLink> plan
					</Text>
				</Card>
			</Card>
		</ListView>
	)
}

export default Sso
