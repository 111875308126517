import React, { PropsWithChildren } from 'react'
import { useTheme } from 'styled-components'
import { Text } from '../Text'
import Theme from '../theme/Theme'
import { View, ViewProps } from '../View'

interface HeaderButtonProps {
	variant: keyof Theme['colors']['window']
}

function HeaderButton({ variant }: HeaderButtonProps) {
	return (
		<View
			backgroundColor={`window.${variant}`}
			size="12px"
			borderRadius="50%"
		/>
	)
}

interface HeaderProps extends PropsWithChildren {
}

function Header({ children }: HeaderProps) {
	return (
		<View p={1}>
			<View
				width="60px"
				alignItems="center"
				gap={5}
				pr="12px"
			>
				<HeaderButton variant="close"/>
				<HeaderButton variant="minimize"/>
				<HeaderButton variant="fullscreen"/>
			</View>
			<Text
				color="text"
				flexGrow={1}
				justifyContent="center"
				mr="60px"
			>
				{children}
			</Text>
		</View>
	)
}

interface WindowProps extends ViewProps {
	title: any
}

export function Window({ children, title, ...props }: WindowProps) {
	const theme = useTheme() as Theme
	return (
		<View
			flexDirection="column"
			background={theme.colors.cardInputBackground}
			borderRadius={12}
			border={theme.input.border}
			minHeight={200}
			minWidth={300}
			{...props}
		>
			<Header>{title}</Header>
			<View
				borderTop={theme.input.border}
				p={1}
			>
				<Text color="text">
					{children}
				</Text>
			</View>
		</View>
	)
}
