import { useQueryClient } from '@tanstack/react-query'
import { Card } from '@withdiver/components/src/Card'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ListView } from '@withdiver/components/src/ListView'
import { Text, TextLink } from '@withdiver/components/src/Text'
import Theme from '@withdiver/components/src/theme/Theme'
import { View } from '@withdiver/components/src/View'
import { parseISO } from 'date-fns'
import React, { useCallback } from 'react'
import { useTheme } from 'styled-components'
import {
	Role,
	useChangeOrganizationUserRoleMutation,
	useDeleteOrganizationUserMutation,
	useGetUsersQuery,
	useUninviteUserMutation,
} from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'

function UserList() {
	const { id: organizationId } = useOrganization()
	const queryClient = useQueryClient()
	const graphQLClient = useGraphQLClient()
	const { data } = useGetUsersQuery(graphQLClient, { organizationId })
	const deleteOrganizationUserMutation = useDeleteOrganizationUserMutation(graphQLClient)
	const changeOrganizationUserRoleMutationMutation = useChangeOrganizationUserRoleMutation(graphQLClient)
	const uninviteUserMutation = useUninviteUserMutation(graphQLClient)
	const theme = useTheme() as Theme

	const onRoleChange = useCallback((userId: string) => async (role: Role) => {
		try {
			await changeOrganizationUserRoleMutationMutation.mutateAsync({ organizationId, userId, role })
		} finally {
			await queryClient.invalidateQueries({ refetchType: 'all' })
		}
	}, [ changeOrganizationUserRoleMutationMutation, organizationId, queryClient ])

	const onRemoveClick = useCallback((userId: string) => async () => {
		try {
			await deleteOrganizationUserMutation.mutateAsync({ organizationId, userId })
		} finally {
			await queryClient.invalidateQueries({ refetchType: 'all' })
		}
	}, [ deleteOrganizationUserMutation, organizationId, queryClient ])

	const onUninviteClick = useCallback((invitationId: string) => async () => {
		try {
			await uninviteUserMutation.mutateAsync({ invitationId })
		} finally {
			await queryClient.invalidateQueries({ refetchType: 'all' })
		}
	}, [ uninviteUserMutation, queryClient ])

	return (
		<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
			<Text
				color="text"
				display="flex"
				maxWidth={1100}
				px={3}
				fontWeight="bold"
			>
				<View minWidth="45px" mr={2}/>
				<View width="70%">Name</View>
				<View justifyContent="center" width="20%">Role</View>
				<View justifyContent="center" width="10%">Action</View>
			</Text>
			<ListView gap={20}>
				{data?.me.organizations?.[0].members.map(member => (
					<Card p={3} maxWidth={1100} alignItems="center">
						<View
							backgroundImage={`url('${member.user.gravatar}')`}
							backgroundPosition="center"
							alignSelf="center"
							justifyContent="center"
							backgroundSize="cover"
							size="45px"
							minWidth="45px"
							border={`2px solid ${theme.colors.rootBackgroundColor}`}
							padding="1px"
							borderRadius="50%"
							mr={2}
						/>
						<Text
							display="block"
							color="text"
							fontSize={3}
							width="70%"
							textOverflow="ellipsis"
							overflow="hidden"
							title={member.user.name}
							whiteSpace="nowrap"
						>
							{member.user.name}
							<Text
								color="text"
								fontSize={0}
							>
								{member.user.email}
							</Text>
						</Text>
						<View width="20%">
							<Select
								items={Object.values(Role)}
								onChange={onRoleChange(member.user.id)}
								selected={member.role}
							/>
						</View>
						<TextLink
							color="danger"
							onClick={onRemoveClick(member.user.id)}
							overflow="hidden"
							textAlign="center"
							textOverflow="ellipsis"
							to=""
							whiteSpace="nowrap"
							width="10%"
						>
							Remove
						</TextLink>
					</Card>
				))}
				{data?.me.organizations?.[0].members.length > 0 && data?.me.organizations?.[0].invitations.length > 0 &&
				<hr style={{ width: '100%' }}/>
				}
				{data?.me.organizations?.[0].invitations.map(invitation => {
					const isExpired = parseISO(invitation.expires_at) < new Date()

					return (
						<Card
							p={3}
							maxWidth={1100}
							alignItems="center"
							opacity={isExpired ? 0.5 : 1}
						>
							<View
								backgroundImage={`url('${invitation.gravatar}')`}
								backgroundPosition="center"
								alignSelf="center"
								justifyContent="center"
								backgroundSize="cover"
								size="45px"
								minWidth="45px"
								border={`2px solid ${theme.colors.rootBackgroundColor}`}
								padding="1px"
								borderRadius="50%"
								mr={2}
							/>
							<Text
								display="block"
								color="text"
								fontSize={3}
								width="70%"
								textOverflow="ellipsis"
								overflow="hidden"
								title={invitation.name}
								whiteSpace="nowrap"
							>
								<Text color="text">
									{invitation.name}
									&nbsp;
									{isExpired &&
									<Text color="text">(invitation expired)</Text>
									}
									{isExpired ||
									<Text color="success">(invited)</Text>
									}
								</Text>
								<Text
									color="text"
									fontSize={0}
								>
									{invitation.email}
								</Text>
							</Text>
							<Text color="text" justifyContent="center" width="20%">
								{invitation.role}
							</Text>
							<TextLink
								color="danger"
								onClick={onUninviteClick(invitation.id)}
								overflow="hidden"
								textAlign="center"
								textOverflow="ellipsis"
								to=""
								whiteSpace="nowrap"
								width="10%"
							>
								{isExpired ? 'Delete' : 'Uninvite'}
							</TextLink>
						</Card>
					)
				})}
			</ListView>
		</View>
	)
}

export default UserList
