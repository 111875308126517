import JsPDF from 'jspdf'

function downloadCanvasToPdf(canvas: HTMLCanvasElement) {
	// Create a new JsPDF object
	const pdf = new JsPDF('l', 'px', [ canvas.width / 2, canvas.height / 2 ])
	// Get the canvas data as a data URL
	const dataUrl = canvas.toDataURL('image/png')
	// Add the image data to the PDF
	pdf.addImage(dataUrl, 'png', 0, 0, canvas.width / 2, canvas.height / 2)
	// Trigger PDF download
	pdf.save('chart.pdf')
}

export {
	downloadCanvasToPdf,
}
