import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { ListView } from '@withdiver/components/src/ListView'
import { OverflowActionMenu } from '@withdiver/components/src/menus/OverflowAction'
import useModal from '@withdiver/components/src/modals/useModal'
import { PreText, Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import { Plus } from 'react-feather'
import {
	QueryFilter,
	useDeleteQueryFilterMutation,
	useGetQueryFiltersQuery,
} from '../../generated/graphql'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import Sidebar from '../Sidebar'
import { QueryFilterEditorModal } from './QueryFilterEditorModal'

type EditProps = Omit<Parameters<typeof QueryFilterEditorModal>[0], 'graphQLClient'>

const BuiltIn = () => <Text color="success" display="initial">(special)</Text>

function FiltersPage() {
	const graphQLClient = useGraphQLClient()
	const queryClient = useQueryClient()
	const { id: organizationId } = useOrganization()
	const { show } = useModal()

	const { data } = useGetQueryFiltersQuery(graphQLClient, { organizationId })
	const deleteQueryFilter = useDeleteQueryFilterMutation(graphQLClient)

	const onAddQueryFilterClick = useCallback(() => {
		show(modalProps => (
			<QueryFilterEditorModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				query=""
				{...modalProps}
			/>
		))()
	}, [ graphQLClient, organizationId, show ])

	const onDeleteClick = useCallback((queryFilterId: QueryFilter['id']) => async () => {
		await deleteQueryFilter.mutateAsync({ queryFilterId })
		await queryClient.invalidateQueries({ refetchType: 'all' })
	}, [ deleteQueryFilter, queryClient ])

	const onEditClick = useCallback((editProps: EditProps) => {
		return show(modalProps => (
			<QueryFilterEditorModal
				{...editProps}
				graphQLClient={graphQLClient}
				{...modalProps}
			/>
		))
	}, [ graphQLClient, show ])

	return (
		<View>
			<Sidebar/>
			<View px={[ 0, 40 ]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Filters
						<Button
							variant="primary"
							size="icon"
							alignSelf="center"
							width="fit-content"
							onClick={onAddQueryFilterClick}
							ml={10}
						>
							<View as={Plus} size={20}/>
						</Button>
					</Text>
				</View>
				<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
					<Text
						color="text"
						display="flex"
						maxWidth={1100}
						px={3}
						fontWeight="bold"
					>
						<View width="50%">Filter Name</View>
						<View justifyContent="center" width="20%">Identifier</View>
						<View justifyContent="center" width="20%">Chart usages</View>
						<View justifyContent="center" width="10%"/>
					</Text>
					<ListView gap={20}>
						{data?.queryFilters.map(filter => (
							<Card p={3} maxWidth={1100} alignItems="baseline">
								<Text
									display="block"
									color="text"
									fontSize={3}
									width="50%"
									textOverflow="ellipsis"
									overflow="hidden"
									title={alert.name}
									whiteSpace="nowrap"
								>
									{filter.identifier}
								</Text>
								<Text
									color="text"
									justifyContent="center"
									width="20%"
								>
									<PreText>{filter.identifier}</PreText>
								</Text>
								<Text
									color="text"
									justifyContent="center"
									width="20%"
								>
									{filter.filterReferenceCount > 0
										? <TextLink to="">{filter.filterReferenceCount}</TextLink>
										: 0
									}
								</Text>
								<Text
									color="text"
									fontSize={1}
									width="10%"
									textOverflow="ellipsis"
									justifyContent="flex-end"
									whiteSpace="nowrap"
								>
									<OverflowActionMenu>
										<TextLink
											to=""
											onClick={onEditClick({
												queryFilterId: filter.id,
												identifier: filter.identifier,
												name: filter.identifier,
												organizationId,
												query: filter.metric.diver_query ?? '',
												queryId: filter.metric.id,
												sourceId: filter.source.id,
											})}
											color="text"
										>
											Edit
										</TextLink>
										<TextLink
											to=""
											onClick={onDeleteClick(filter.id)}
											color="danger"
										>
											Delete
										</TextLink>
									</OverflowActionMenu>
								</Text>
							</Card>
						))}
						{Boolean(data?.queryFilters.length) ||
						<Card p={3} maxWidth={1100} alignItems="baseline" opacity={0.6}>
							<Text color="text">
								You currently don't have any custom filters. Click the <Plus size="1em"/> button in the top of the
								page to add a new one.
							</Text>
						</Card>
						}
						<hr style={{ width: '100%' }}/>
						<Card p={3} maxWidth={1100} alignItems="baseline">
							<Text
								display="block"
								color="text"
								fontSize={3}
								width="50%"
								textOverflow="ellipsis"
								overflow="hidden"
								title={alert.name}
								whiteSpace="nowrap"
							>
								Timerange <BuiltIn/>
							</Text>
							<Text
								color="text"
								justifyContent="center"
								width="20%"
							>
								<PreText>timerange</PreText>
							</Text>
							<View flexGrow={1}/>
							<TextLink
								target="_blank"
								to="/docs/filters/timerange"
							>
								Learn more
							</TextLink>
						</Card>
						<Card p={3} maxWidth={1100} alignItems="baseline">
							<Text
								display="block"
								color="text"
								fontSize={3}
								width="50%"
								textOverflow="ellipsis"
								overflow="hidden"
								title={alert.name}
								whiteSpace="nowrap"
							>
								Aggregation <BuiltIn/>
							</Text>
							<Text
								color="text"
								justifyContent="center"
								width="20%"
							>
								<PreText>aggregation</PreText>
							</Text>
							<View flexGrow={1}/>
							<TextLink
								target="_blank"
								to="/docs/filters/aggregation"
							>
								Learn more
							</TextLink>
						</Card>
					</ListView>
				</View>
			</View>
		</View>
	)
}

export default FiltersPage
