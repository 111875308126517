import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Scalars, useAddDomainMutation } from '../../generated/graphql'

interface DomainAdditionModalProps extends ModalProps {
	graphQLClient: GraphQLClient
	organizationId: Scalars['ID']
}

interface DomainAdditionFormValues {
	domain: string
}

const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/

const Form = styled.form``

export function DomainAdditionModal(props: DomainAdditionModalProps) {
	const organizationId = props.organizationId
	const queryClient = useQueryClient()
	const mutation = useAddDomainMutation(props.graphQLClient)
	const { register, handleSubmit, watch } = useForm<DomainAdditionFormValues>()

	const onHide = props.onHide
	const onAddDomain = useCallback(async ({ domain }: DomainAdditionFormValues) => {
		try {
			await mutation.mutateAsync({ organizationId, domainName: domain })
			await queryClient.invalidateQueries({ refetchType: 'all' })
			onHide?.()
		} catch (e) {
			console.error(e)
		}
	}, [ mutation, onHide, organizationId, queryClient ])

	return (
		<Modal {...props}>
			<View minWidth="300px" width="30vw" flexGrow={1} flexDirection="column">
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onAddDomain)}
					width="100%"
				>
					<FormControl label="Add a new domain to use SAML or email SSO">
						<Input
							placeholder="ex: withdiver.com"
							{...register('domain', {
								pattern: domainRegex,
								required: true,
							})}
						/>
					</FormControl>
					<View mt={2}>
						<Button
							disabled={!domainRegex.test(watch('domain'))}
							size="medium"
							variant="primary"
						>
							Save
						</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
