import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Text } from '../../Text'
import { View, ViewProps } from '../../View'

export interface FormControlProps extends ViewProps {
	children: ReactElement | ReactElement[]
	label?: string
}

const Label = styled.label``

export function FormControl({ children, label, ...props }: FormControlProps) {
	return (
		<View
			flexDirection="column"
			alignItems="baseline"
			{...props}
			as={Label}
		>
			<Text
				color="text"
				mb="5px"
			>
				{label || ' '}
			</Text>
			<View
				flexDirection="column"
				width="100%"
			>
				{children}
			</View>
		</View>
	)
}
