import { Button } from '@withdiver/components/src/Button'
import { Card } from '@withdiver/components/src/Card'
import { Text, TextLink } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import { GitHub } from 'react-feather'
import { useOrganization } from '../../useOrganization'

function GithubPage() {
	const { id } = useOrganization()

	const onButtonClick = useCallback(() => {
		window.location.href = `https://github.com/apps/diver-withdiver-com/installations/new?state=${id}`
	}, [ id ])

	return (
		<View flexDirection="column" width="100%" maxWidth={1100} gap={20}>
			<View flexDirection="column" gap={20}>
				<Card display="block" p={3} maxWidth={1100}>
					<Text display="block" color="text" mb={3}>
						Diver can connect with GitHub to automate
						the process of creating dashboards,
						updating documentation and if your team already builds metrics
						using
						{' '}
						<TextLink
							target="_blank"
							to="/external/dbt/docs/docs/introduction"
						>
							dbt
						</TextLink>
						,
						we can discover them automatically and make
						them readily available to you when building dashboards.
					</Text>
					<Button onClick={onButtonClick} size="medium" variant="primary">
						<View as={GitHub} mr={1} size={20}/>Get Started
					</Button>
				</Card>
			</View>
		</View>
	)
}

export default GithubPage
