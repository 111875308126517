import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { TableChart } from '@withdiver/components/src/charts/TableChart'
import { CodeArea } from '@withdiver/components/src/inputs/CodeArea'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import cronstrue from 'cronstrue'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback, useState } from 'react'
import { CheckCircle, HelpCircle } from 'react-feather'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
	useGetSourcesQuery,
	useRunQueryMutation,
	useSaveAlertMutation,
	useSaveQueryMutation,
	useValidateQueryMutation,
} from '../../generated/graphql'

interface AlertEditorModalProps extends ModalProps {
	alertId?: string
	graphQLClient: GraphQLClient
	cron?: AlertEditorFormValues['cron']
	name?: AlertEditorFormValues['name']
	organizationId: string
	query: AlertEditorFormValues['query']
	queryId?: string
	sourceId?: AlertEditorFormValues['sourceId']
}

interface AlertEditorFormValues {
	cron: string
	name: string
	query: string
	sourceId: string
}

const Form = styled.form``

export function AlertEditorModal(props: AlertEditorModalProps) {
	const { data: sources } = useGetSourcesQuery(props.graphQLClient, { organizationId: props.organizationId })
	const validateQuery = useValidateQueryMutation(props.graphQLClient)
	const runQuery = useRunQueryMutation(props.graphQLClient)
	const [ queryResult, setQueryResult ] = useState<{ columns: string[], rows: string[] }|null>()
	const [ fontSize, setFontSize ] = useState(13)
	const [ tabSize, setTabSize ] = useState(4)
	const queryClient = useQueryClient()
	const saveQuery = useSaveQueryMutation(props.graphQLClient)
	const saveAlert = useSaveAlertMutation(props.graphQLClient)
	const { register, handleSubmit, getValues, setValue, watch } = useForm<AlertEditorFormValues>({
		defaultValues: {
			cron: props.cron ?? '',
			name: props.name ?? '',
			query: props.query,
			sourceId: props.sourceId,
		},
	})
	const [ sawError, setSawError ] = useState(false)

	const onValidateQuery = useCallback(async ({ query, sourceId }: AlertEditorFormValues) => {
		if (!query || !sourceId) {
			setSawError(false)
			return
		}
		try {
			await validateQuery.mutateAsync({ sourceId, query })
			setSawError(false)
		} catch (e) {
			setSawError(true)
		}
	}, [ validateQuery, setSawError ])

	const onRunQuery = useCallback(async () => {
		try {
			const response = await runQuery.mutateAsync({
				filters: [],
				sourceId: getValues('sourceId'),
				query: getValues('query'),
			})
			setQueryResult(response?.compileQuery.result)
			setSawError(false)
		} catch (e) {
			setSawError(true)
		}
	}, [ getValues, runQuery, setSawError ])

	const onHide = props.onHide
	const onSaveAlert = useCallback(async () => {
		try {
			const savedQueryResponse = await saveQuery.mutateAsync({
				queryId: props.queryId,
				sourceId: getValues('sourceId'),
				query: getValues('query'),
			})
			if (!savedQueryResponse.saveQuery) {
				return
			}
			await saveAlert.mutateAsync({
				alertId: props.alertId,
				organizationId: props.alertId ? undefined : props.organizationId,
				queryId: savedQueryResponse.saveQuery.id,
				sourceId: getValues('sourceId'),
				cron: getValues('cron'),
				name: getValues('name'),
			})
		} finally {
			queryClient.invalidateQueries([], { refetchType: 'all' })
		}
		onHide?.()
	}, [
		getValues,
		onHide,
		props.alertId,
		props.organizationId,
		props.queryId,
		queryClient,
		saveAlert,
		saveQuery,
	])

	let humanReadableCron = ''
	let CronIcon = HelpCircle
	try {
		humanReadableCron = cronstrue.toString(watch('cron'))
		CronIcon = CheckCircle
	} catch (e) {
		console.debug(e)
	}

	const onFormChange = useCallback(() => {
		onValidateQuery(getValues())
	}, [onValidateQuery, getValues])

	register('sourceId')

	return (
		<Modal {...props}>
			<View height="80vh" width="80vw" flexGrow={1}>
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onValidateQuery)}
					width="inherit"
					gap="10px"
				>
					<View gap="40px">
						<FormControl label="Alert name">
							<Input
								{...register('name')}
							/>
						</FormControl>
						<FormControl label="Run interval">
							<View flexDirection="row" alignItems="baseline">
								<Input
									{...register('cron')}
									placeholder="* * * * *"
									color={humanReadableCron ? 'success' : 'text'}
									icon={
										<Text color={humanReadableCron ? 'success' : 'text'}>
											<CronIcon size={16}/>
										</Text>
									}
								/>
								<Text
									color="success"
									ml={2}
								>
									{humanReadableCron}
								</Text>
							</View>
						</FormControl>
					</View>
					<View
						width="100%"
						flexBasis={0}
						flexGrow={1}
						gap="40px"
					>
						<View
							flexDirection="column"
							height="100%"
							width="100%"
							gap="10px"
						>
							<CodeArea
								fontSize={fontSize}
								backgroundColor={sawError ? '#f00' : undefined}
								onChange={(value) => {
									setValue('query', value)
									onFormChange()
								}}
								tabSize={tabSize}
								showPrintMargin={false}
								value={watch('query')}
								mode="dql"
							/>
							<View flexDirection="row-reverse" gap="20px">
								<Button variant="primary" size="medium" disabled={sawError} onClick={onRunQuery}>
									Test alert
								</Button>
								<Select
									items={sources?.sources.map(source => source.id) ?? []}
									itemToLabel={item => sources?.sources.find(s => s.id === item)?.name ?? ''}
									selected={watch('sourceId')}
									onChange={sourceId => setValue('sourceId', sourceId) }
								/>
								<Select
									items={[1, 2, 4, 8]}
									itemToLabel={item => `${item} space${item > 1 ? 's' : ''}`}
									selected={tabSize}
									onChange={setTabSize}
								/>
								<View width="100px">
									<Select
										items={[11, 13, 14, 16, 18]}
										itemToLabel={item => `${item} px`}
										selected={fontSize}
										onChange={setFontSize}
									/>
								</View>
							</View>
						</View>
					</View>
					<View flexGrow={1} flexBasis={0} overflow="auto">
						<Text color="text" width="100%">
							<TableChart
								data={{
									columns: queryResult?.columns ?? [],
									rows: queryResult?.rows.map(row => JSON.parse(row)) ?? [],
								}}
							/>
						</Text>
					</View>
					<View>
						<Button variant="success" size="medium" type="button" onClick={onSaveAlert}>Save</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
