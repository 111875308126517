import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React from 'react'
import { Navigate, NavLink, NavLinkProps, Route, Routes } from 'react-router-dom'
import styled from 'styled-components'
import Sidebar from '../Sidebar'
import CreditCardPage from './CreditCardPage'
import InvoiceList from './InvoiceList'
import Subscription from './Subscription'

const TabLink = styled(View).attrs({ as: NavLink })<NavLinkProps>(
	{
		textDecoration: 'none',
	},
	({ theme }) => ({
		fontSize: '20px',
		color: theme.colors.text,
		borderBottom: '2px solid transparent',
		width: '100%',
		justifyContent: 'center',
		paddingBottom: theme.space[1],
		[View]: {
			color: 'darkgray',
			alignSelf: 'center',
		},
		'&:hover': {
			color: theme.colors.primary,
		},
		'&.active': {
			color: theme.colors.primary,
			borderBottom: `2px solid ${theme.colors.primary}`,
		},
	}),
)

function BillingPage() {
	return (
		<View>
			<Sidebar/>
			<View px={[0, 40]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Billing</Text>
				</View>
				<View maxWidth={1100} mb={3}>
					<TabLink to="subscription">Subscription</TabLink>
					<TabLink to="card">Credit Card</TabLink>
					<TabLink to="invoices">Invoices</TabLink>
				</View>
				<Routes>
					<Route path="subscription" element={<Subscription/>}/>
					<Route path="card" element={<CreditCardPage/>}/>
					<Route path="invoices" element={<InvoiceList/>}/>
					<Route index element={<Navigate to="subscription" replace/>}/>
				</Routes>
			</View>
		</View>
	)
}

export {
	TabLink,
}

export default BillingPage
