import React, { ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import { Button, ButtonProps } from '../Button'
import { View, ViewProps } from '../View'

const AggregationPickerWrapper = styled(View).attrs({ as: styled.div`` })<ViewProps>(
	{
		width: 'fit-content',
		'& > *': {
			borderRadius: 0,
			borderWidth: '2px 1px',
		},
		'& > :first-child': {
			borderRadius: '20px 0 0 20px',
			borderWidth: '2px 1px 2px 2px',
		},
		'& > :last-child': {
			borderRadius: '0 20px 20px 0',
			borderWidth: '2px 2px 2px 1px',
		},
	},
)

interface AggregationPickerProps<Value extends string> {
	options: Record<Value, ButtonProps['children']>
	value?: Value
	onChange?: (aggregation: Value) => void
}

export function AggregationPicker<Value extends string>({ onChange, ...props }: AggregationPickerProps<Value>) {
	const onClick = useCallback((aggregation: Value) => () => {
		onChange && onChange(aggregation)
	}, [ onChange ])

	return (
		<AggregationPickerWrapper>
			{Object.entries(props.options).map(([key, label]) => (
				<Button
					key={key}
					variant={key === props.value ? 'primary' : 'outline'}
					size="medium"
					onClick={onClick(key as Value)}
				>
					{label as ReactNode}
				</Button>
			))}
		</AggregationPickerWrapper>
	)
}
