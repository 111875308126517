import { useNavigate, useParams } from 'react-router-dom'

function switchOrganization(navigate: ReturnType<typeof useNavigate>, organizationId: string, subPath?: string) {
	navigate(`/workplace/${organizationId}/${subPath ?? ''}`)
}

function useOrganization() {
	const { organizationId } = useParams()
	return { id: organizationId! }
}

export {
	switchOrganization,
	useOrganization,
}
