
const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_API_URL ?? 'http://localhost/glorious'
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
const TEMPORARY_STRIPE_CLIENT_SECRET = process.env.REACT_APP_TEMPORARY_STRIPE_CLIENT_SECRET!

export {
	GRAPHQL_API_URL,
	STRIPE_PUBLISHABLE_KEY,
	TEMPORARY_STRIPE_CLIENT_SECRET,
}
