import Tippy from '@tippyjs/react'
import { Button } from '@withdiver/components/src/Button'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import { Plus } from 'react-feather'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import { TabLink } from '../Billing'
import Sidebar from '../Sidebar'
import { SourceAdditionModal } from './SourceAdditionModal'
import CommandLineTokenListPage from './CommandLineTokenListPage'
import GithubPage from './GithubPage'
import SourceListPage from './SourceListPage'

function ConnectionsPage() {
	const graphQLClient = useGraphQLClient()
	const { id: organizationId } = useOrganization()
	const { show } = useModal()

	const onAddSourceClick = useCallback(() => {
		show(modalProps => (
			<SourceAdditionModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				{...modalProps}
			/>
		))()
	}, [ graphQLClient, organizationId, show ])

	return (
		<View>
			<Sidebar/>
			<View px={[ 0, 40 ]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Connections
						<Tippy content="Add source">
							<Button
								variant="primary"
								size="icon"
								alignSelf="center"
								width="fit-content"
								onClick={onAddSourceClick}
								ml={10}
							>
								<View as={Plus} size={20}/>
							</Button>
						</Tippy>
					</Text>
				</View>
				<View maxWidth={1100} mb={3}>
					<TabLink to="sources">Sources</TabLink>
					<TabLink to="cli">Command Line</TabLink>
					<TabLink to="github">GitHub</TabLink>
				</View>
				<Routes>
					<Route path="sources" element={<SourceListPage/>}/>
					<Route path="cli" element={<CommandLineTokenListPage/>}/>
					<Route path="github" element={<GithubPage/>}/>
					<Route index element={<Navigate to="sources" replace/>}/>
				</Routes>
			</View>
		</View>
	)
}

export default ConnectionsPage
