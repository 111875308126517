import Tippy from '@tippyjs/react'
import { Button } from '@withdiver/components/src/Button'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text } from '@withdiver/components/src/Text'
import { View } from '@withdiver/components/src/View'
import React, { useCallback } from 'react'
import { Plus } from 'react-feather'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useGraphQLClient } from '../../useGraphQLClient'
import { useOrganization } from '../../useOrganization'
import { TabLink } from '../Billing'
import Sidebar from '../Sidebar'
import Sso from './Sso'
import { UserInvitationModal } from './UserInvitationModal'
import UserList from './UserList'

function TeamPage() {
	const graphQLClient = useGraphQLClient()
	const { id: organizationId } = useOrganization()
	const { show } = useModal()

	const onInviteUserClick = useCallback(() => {
		show(modalProps => (
			<UserInvitationModal
				graphQLClient={graphQLClient}
				organizationId={organizationId}
				{...modalProps}
			/>
		))()
	}, [ graphQLClient, organizationId, show ])

	return (
		<View>
			<Sidebar/>
			<View px={[0, 40]} py={40} display="block" width="100%">
				<View mb={20}>
					<Text color="text" fontSize={24}>Team
						<Tippy content="Invite user">
							<Button
								variant="primary"
								size="icon"
								alignSelf="center"
								width="fit-content"
								onClick={onInviteUserClick}
								ml={10}
							>
								<View as={Plus} size={20}/>
							</Button>
						</Tippy>
					</Text>
				</View>
				<View maxWidth={1100} mb={3}>
					<TabLink to="users">Users</TabLink>
					<TabLink to="groups">Groups</TabLink>
					<TabLink to="sso">SSO</TabLink>
					<TabLink to="roles">Roles</TabLink>
				</View>
				<Routes>
					<Route path="users" element={<UserList/>}/>
					<Route path="groups" element={<View/>}/>
					<Route path="sso" element={<Sso/>}/>
					<Route path="roles" element={<View/>}/>
					<Route index element={<Navigate to="users" replace/>}/>
				</Routes>
			</View>
		</View>
	)
}

export default TeamPage
