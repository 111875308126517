import { Select } from '@withdiver/components/src/inputs/Select'
import useModal from '@withdiver/components/src/modals/useModal'
import { Text } from '@withdiver/components/src/Text'
import { View, ViewProps } from '@withdiver/components/src/View'
import React, { useCallback, useEffect, useState } from 'react'
import {
	Activity,
	Bell,
	Book,
	Clock,
	CreditCard,
	ExternalLink,
	Filter,
	LifeBuoy,
	Link2,
	MessageCircle,
	Settings,
	Trello,
	Users,
} from 'react-feather'
import { NavLink, NavLinkProps, useMatch, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { GetMeQuery, useGetMeQuery } from '../generated/graphql'
import { useGraphQLClient } from '../useGraphQLClient'
import { switchOrganization, useOrganization } from '../useOrganization'
import { OrganizationCreationModal } from './OrganizationCreationModal'

const Img = styled.img({})

const SidebarLink = styled(View).attrs((props: { [key: string]: unknown }) => ({ as: props.as ?? NavLink }))<ViewProps | NavLinkProps>(
	{
		cursor: 'pointer',
		fontSize: 16,
		fontWeight: 600,
		textDecoration: 'none',
		margin: '5px 0',
	},
	({ theme }) => ({
		color: theme.colors.text,
		[View]: {
			color: 'darkgray',
			alignSelf: 'center',
		},
		'&:hover, &.active': {
			color: theme.colors.primary,
			[View]: {
				color: theme.colors.primary,
				opacity: 1,
			},
		},
	}),
)

function Sidebar() {
	const { id: organizationId } = useOrganization()
	const graphQLClient = useGraphQLClient()
	const { data, isSuccess } = useGetMeQuery(graphQLClient)
	const navigate = useNavigate()
	const { show } = useModal()
	const match = useMatch('/workplace/:id/*')
	const [ organizationSearch, setOrganizationSearch ] = useState('')

	const onOrganizationSwitch = useCallback<(value: GetMeQuery['me']['organizations'][0]) => void>((value) => {
		if (value.id === '+') {
			show(modalProps => (
				<OrganizationCreationModal
					graphQLClient={graphQLClient}
					{...modalProps}
				/>
			))()
			return // We want to start the organization creation flow here.
		}
		if (data?.me.organizations.includes(value)) {
			switchOrganization(navigate, value.id, match?.params['*'])
		}
	}, [ data, graphQLClient, match, navigate, show ])

	const onFeedbackClick = useCallback(() => {
		console.log('Open feedback modal')
	}, [])

	const onSupportClick = useCallback(() => {
		console.log('Open support modal')
	}, [])

	useEffect(() => {
		if (organizationId === null && isSuccess) {
			onOrganizationSwitch(data?.me.organizations?.[0] ?? { id: '+', name: '' })
		}
	}, [ data?.me.organizations, isSuccess, onOrganizationSwitch, organizationId ])

	return (
		<View flexDirection="column" width={200} ml={40} mt={20}>
			<View as={Img} src="/logo.svg" height={38} width={38} m={6} mb={26}/>
			<Select
				items={
					(data?.me.organizations ?? [])
						.filter(o => o.name.toLowerCase()
							.startsWith(organizationSearch.toLowerCase()),
						)
						.concat([ { id: '+', name: '+ Add workplace' } ])
				}
				itemToLabel={organization => organization.name}
				selected={data?.me.organizations?.find(o => o.id === organizationId)}
				onChange={onOrganizationSwitch}
				searchable={(data?.me.organizations.length ?? 0) >= 10}
				searchValue={organizationSearch}
				onSearchChange={e => {
					setOrganizationSearch(e.currentTarget.value)
				}}
			/>
			<View mt={20}/>
			<SidebarLink to={`/workplace/${organizationId}/dashboards`}><View as={Trello} size={20} mr={10}/>Dashboards</SidebarLink>
			<SidebarLink to={`/workplace/${organizationId}/filters`}><View as={Filter} size={20} mr={10}/>Filters</SidebarLink>
			<SidebarLink to={`/workplace/${organizationId}/alerts`}><View as={Bell} size={20} mr={10}/>Alerts</SidebarLink>
			<SidebarLink to={`/workplace/${organizationId}/activity`}><View as={Activity} size={20} mr={10}/>Activity</SidebarLink>
			<SidebarLink to={`/workplace/${organizationId}/team`}><View as={Users} size={20} mr={10}/>Team</SidebarLink>
			<SidebarLink to={`/workplace/${organizationId}/connections`}><View as={Link2} size={20} mr={10}/>Connections</SidebarLink>
			<SidebarLink to={`/workplace/${organizationId}/billing`}><View as={CreditCard} size={20} mr={10}/>Billing</SidebarLink>
			<SidebarLink to={`/workplace/${organizationId}/settings`}><View as={Settings} size={20} mr={10}/>Settings</SidebarLink>
			<Text color="text" fontSize={20}>----------</Text>
			<SidebarLink to="/docs" target="blank">
				<View as={Book} size={20} mr={10}/>Docs
				<View as={ExternalLink} opacity={0} size={16} ml={1} color="darkgray"/>
			</SidebarLink>
			<SidebarLink as={View} onClick={onFeedbackClick}>
				<View as={MessageCircle} size={20} mr={10}/>Feedback
			</SidebarLink>
			<SidebarLink as={View} onClick={onSupportClick}>
				<View as={LifeBuoy} size={20} mr={10}/>Support
			</SidebarLink>
			<SidebarLink to="/status" target="_blank">
				<View as={Clock} size={20} mr={10}/>Status
				<View as={ExternalLink} opacity={0} size={16} ml={1} color="darkgray"/>
			</SidebarLink>
		</View>
	)
}

export default Sidebar
