import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@withdiver/components/src/Button'
import { FormControl } from '@withdiver/components/src/inputs/FormControl'
import { Input } from '@withdiver/components/src/inputs/Input'
import { Select } from '@withdiver/components/src/inputs/Select'
import { ModalProps } from '@withdiver/components/src/modals'
import Modal from '@withdiver/components/src/modals/Modal'
import { View } from '@withdiver/components/src/View'
import { GraphQLClient } from 'graphql-request'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { SourceDriver, useAddSourceMutation } from '../../generated/graphql'

interface SourceAdditionModalProps extends ModalProps {
	graphQLClient: GraphQLClient
	organizationId: string
}

interface SourceAdditionFormValues {
	name: string
	driver: SourceDriver
	host: string
	port: string
	database: string
	user: string
	password: string
}

const Form = styled.form``

export function SourceAdditionModal(props: SourceAdditionModalProps) {
	const { organizationId } = props
	const queryClient = useQueryClient()
	const mutation = useAddSourceMutation(props.graphQLClient)
	const { register, handleSubmit, setValue, watch } = useForm<SourceAdditionFormValues>()

	const onHide = props.onHide
	const onAddSource = useCallback(async (formValues: SourceAdditionFormValues) => {
		try {
			await mutation.mutateAsync({
				organizationId,
				name: formValues.name,
				driver: formValues.driver,
				host: formValues.host,
				port: parseInt(formValues.port, 10),
				database: formValues.database,
				user: formValues.user,
				password: formValues.password,
			})
			await queryClient.invalidateQueries({ refetchType: 'all' })
			onHide?.()
		} catch (e) {
			console.error(e)
		}
	}, [ mutation, onHide, organizationId, queryClient ])

	return (
		<Modal {...props}>
			<View minWidth="300px" width="30vw" flexGrow={1} flexDirection="column">
				<View
					as={Form}
					flexDirection="column"
					onSubmit={handleSubmit(onAddSource)}
					width="100%"
					gap="20px"
				>
					<FormControl label="Source name">
						<Input
							{...register('name')}
						/>
					</FormControl>
					<FormControl label="Driver">
						<Select
							{...register('driver')}
							onChange={driver => setValue('driver', driver)}
							items={Object.values(SourceDriver)}
							selected={watch('driver')}
						/>
					</FormControl>
					<View width="100%" gap="40px">
						<FormControl label="Host" width="70%">
							<Input
								{...register('host')}
							/>
						</FormControl>
						<FormControl label="Port" width="30%">
							<Input
								type="number"
								min={1}
								max={65535}
								{...register('port')}
							/>
						</FormControl>
					</View>
					<FormControl label="Database">
						<Input
							{...register('database')}
						/>
					</FormControl>
					<FormControl label="Username">
						<Input
							{...register('user')}
						/>
					</FormControl>
					<FormControl label="Password">
						<Input
							type="password"
							{...register('password')}
						/>
					</FormControl>
					<View mt={2}>
						<Button variant="primary" size="medium">Test & add source</Button>
					</View>
				</View>
			</View>
		</Modal>
	)
}
