import * as Sentry from '@sentry/browser'
import {
	Chart as ChartJS,
	registerables,
} from 'chart.js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

Sentry.init({
	dsn: "https://6b0503575d3d45e18392c9bacc39273a@o4504046740766720.ingest.sentry.io/4504108274614272",
	integrations: [ Sentry.browserTracingIntegration() ],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
})

ChartJS.register(...registerables)

const container = document.getElementById('root')
if (!container) {
	throw new Error('container not found!')
}

const root = createRoot(container)

root.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>,
)
