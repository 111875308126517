import { Ace } from 'ace-builds'
import React, { FC, forwardRef } from 'react'
import ReactAce from 'react-ace'
import styled, { useTheme } from 'styled-components'
import { Text } from '../../Text'
import Theme from '../../theme/Theme'
import { View, ViewProps } from '../../View'

import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/ext-language_tools'
import './mode-dql.js'

interface CodeAreaProps {
	as?: string | React.ComponentType
	autocompletion?: {
		value: string
		score: number
		meta: string
	}[]
	backgroundColor?: ViewProps['backgroundColor']
	fontSize?: string | number

	// Ace props
	name?: string
	style?: React.CSSProperties
	mode?: string | object
	theme?: string
	height?: string
	width?: string
	className?: string
	showGutter?: boolean
	showPrintMargin?: boolean
	highlightActiveLine?: boolean
	focus?: boolean
	cursorStart?: number
	wrapEnabled?: boolean
	readOnly?: boolean
	minLines?: number
	maxLines?: number
	debounceChangePeriod?: number
	enableBasicAutocompletion?: boolean | string[]
	enableLiveAutocompletion?: boolean | string[]
	tabSize?: number
	value?: string
	placeholder?: string
	defaultValue?: string
	onChange?: (value: string, event?: any) => void
}

const StyledCodeArea = styled(ReactAce)(
	{
		background: 'inherit',
		border: 'none',
		borderRadius: 'inherit',
		color: 'inherit',
		outline: 'none',
		zIndex: 1,
	},
	({ theme }) => ({
		'& .ace_comment': {
			color: theme.codeArea.colors.comment,
		},
		'& .ace_constant': {
			color: `${theme.codeArea.colors.constant} !important`,
		},
		'& .ace_comment.ace_doc': {
			color: theme.codeArea.colors.comment,
		},
		'& .ace_dql': {
			color: theme.codeArea.colors.dql,
		},
		'& .ace_identifier': {
			color: theme.codeArea.colors.comment,
		},
		'& .ace_keyword': {
			color: theme.codeArea.colors.keyword,
		},
		'& .ace_string': {
			color: theme.codeArea.colors.string,
		},
		'& .ace_support.ace_function': {
			color: theme.codeArea.colors.supportFunction,
		},
	}),
)

const CodeArea = forwardRef<ReactAce>(({
	as,
	autocompletion,
	backgroundColor,
	...props
}: CodeAreaProps, ref) => {
	const theme = useTheme() as Theme
	return (
		<View
			backgroundColor={backgroundColor ?? theme.colors.cardInputBackground}
			border={theme.input.border}
			borderRadius={6}
			height="inherit"
			width="inherit"
			overflow="hidden"
		>
			<Text
				color="text"
				display="block"
				height="inherit"
				width="inherit"
				overflow="auto"
			>
				<StyledCodeArea
					key={JSON.stringify(autocompletion)}
					editorProps={{
						completers: [
							{
								getCompletions: function (editor, session, pos, prefix, callback) {
									callback(null, autocompletion ?? []);
								},
								identifierRegexps: [
									/[a-zA-Z]+[a-zA-Z0-9_.]*/,
								],
							},
						] as Ace.Completer[],
					}}
					enableLiveAutocompletion={true}
					showGutter={true}
					highlightActiveLine={true}
					height="inherit"
					width="inherit"
					wrapEnabled={true}
					ref={ref}
					{...props}
				/>
			</Text>
		</View>
	)
}) as FC<CodeAreaProps>

export {
	CodeArea,
}
